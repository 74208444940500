<div
  cdkDropList
  class="tf-nc tf-node-{{ slideData.id }} slide{{ slideData.id }}"
  [id]="parentData?.id ? parentData.id : ''"
  [cdkDropListData]="slideData"
  (cdkDropListDropped)="onDragDrop($event)"
>
  <div
    cdkDrag
    class="tf-custom-wrapper"
    [id]="slideData.id"
    [cdkDragData]="slideData"
    [cdkDragDisabled]="!parentData || preview"
  >
    <div *ngIf="slideData" class="tf-custom-content-wrapper slide">
      <div class="slide-wrapper">
        <st-slide-editor
          *ngIf="!preview"
          [story]="story"
          [slideData]="slideData"
          [parentData]="parentData"
          [selectedSlideId]="selectedSlideId"
        ></st-slide-editor>
        <st-slide-preview
          *ngIf="preview"
          [selectedSlideId]="selectedSlideId"
          [slideData]="slideData"
        ></st-slide-preview>
      </div>
    </div>
  </div>

  <div class="tf-custom-wrapper tf-custom-placeholder">
    <div class="node-content-wrapper slide">
      <div class="slide-wrapper">
        <div class="card-body slide-img"></div>
      </div>
    </div>
  </div>
</div>

<ul class="node__children" *ngIf="nextSlides.length > 0" [id]="slideData?.id.toString()">
  <li
    slide
    class="node"
    *ngFor="let subItem of nextSlides"
    [story]="story"
    [slideData]="subItem"
    [selectedSlideId]="selectedSlideId"
    [parentData]="slideData"
    [preview]="preview"
    (itemDrop)="onDragDrop($event)"
  ></li>
</ul>
