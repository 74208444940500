<div class="story-view">
  <div [class.active]="!assetsLoaded" class="hover-box top">
    <div class="settings to-top">
      <a [routerLink]="backButtonURL" class="back-story-link mdc-icon-button material-icons">arrow_back</a>
    </div>
  </div>
  <div class="story-container">
    <player *ngIf="story" (recieveSessionCode)="getSessionCode($event)" (playerEvents)="listenToPlayerEvents($event)" [storyInput]="story"
    [currentSlideId]="initialSlideId"></player>
  </div>

  <div [class.active]="!assetsLoaded" class="hover-box bottom">
    <div class="settings to-bottom">
      <div class="sessioncode" cdkDrag cdkDragLockAxis="x" *ngIf="sessionCode" (click)="openDialog()">
        SessieCode: {{ sessionCode }}
      </div>

      <div class="sessionSize">
        <span class="material-icons">
          people
        </span>
      </div>
    </div>
  </div>
</div>