import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injector, Injectable } from '@angular/core';
import { RollbarService } from '../app.module';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const rollbar = this.injector.get(RollbarService);

        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {
          // client-side error

          errorMessage = `Client Error: ${error.error.message}`;
        } else {
          // server-side error

          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        // this.toaster.error(errorMessage);

        rollbar.error(new Error(error.message).stack);

        return throwError(errorMessage);
      }),
    );
  }
}
