import { Touchpoint } from '@shared/models/entities/touchpoint';

export class WTGridItem {
  public gridIndex: number;
  public touchpoints: Touchpoint[];
  public tagId: number;

  constructor(gridIndex: number, touchpoints: Touchpoint[] = []) {
    this.gridIndex = gridIndex;
    this.touchpoints = touchpoints;
  }
}
