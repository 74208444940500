import { Component, EventEmitter, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EditorService } from '@pages/editor/services/editor.service';
@Component({
  selector: 'st-wordcloud-element',
  templateUrl: './wordcloud-element.component.html',
  styleUrls: ['./wordcloud-element.component.scss'],
})
export class WordcloudElementComponent implements OnInit, OnChanges {
  @Output() public save = new EventEmitter<boolean>();

  public wordcloudSlideForm: FormGroup;

  public chips;
  public isOpenQuestion: boolean;
  public showAllWords: boolean;
  public chipsArr = [];

  public buttonDisabled: boolean;

  public question: HTMLElement;
  public svgDOM: SVGSVGElement;

  constructor(fb: FormBuilder, private editorService: EditorService) {
    this.wordcloudSlideForm = fb.group({
      checkboxOpenAnswer: [],
      checkboxShowAllWords: [],
      addWord: [],
      voteCount: [],
    });
  }

  public _keyUp(event: any) {
    const pattern = /^[A-Za-z]*$/;
    this.buttonDisabled = !pattern.test(event);
  }

  public ngOnInit() {
    this.question = this.editorService.slideDom.querySelector('#question1');
    this.svgDOM = this.editorService.slideDom.querySelector('svg');
    this.isOpenQuestion = this.svgDOM.getAttribute('isopenquestion') === 'true';
    this.showAllWords = this.svgDOM.getAttribute('showallwords') === 'true';
    this.chips = this.svgDOM.dataset.chips;
    this.chipsArr = this.svgDOM.dataset.chips.split(',');
    this.wordcloudSlideForm.get('checkboxOpenAnswer').setValue(this.isOpenQuestion);
    this.wordcloudSlideForm.get('checkboxShowAllWords').setValue(this.showAllWords);

    // this.wordcloudSlideForm.get('textarea').setValue(this.chips);
  }

  public ngOnChanges(): void {
    this.wordcloudSlideForm.get('checkboxOpenAnswer').valueChanges.subscribe(val => {
      this.svgDOM.setAttribute('isopenquestion', val);
      this.save.emit(true);
    });

    this.wordcloudSlideForm.get('checkboxShowAllWords').valueChanges.subscribe(val => {
      this.svgDOM.setAttribute('showallwords', val);
      this.save.emit(true);
    });

    this.wordcloudSlideForm.get('voteCount').valueChanges.subscribe(val => {
      this.svgDOM.setAttribute('votecount', val);
      this.save.emit(true);
    });
  }

  public addChipWords(word: string) {
    if (word !== '' && this.chipsArr.length <= 20 && !this.buttonDisabled) {
      this.chipsArr.unshift(word);
      this.wordcloudSlideForm.get('addWord').setValue('');
      this.svgDOM.setAttribute('data-chips', this.chipsArr.toString());
      this.save.emit(true);
    } else {
      return;
    }
  }

  public deleteChip(index) {
    if (index > -1) {
      this.chipsArr.splice(index, 1);
    }

    this.svgDOM.setAttribute('data-chips', this.chipsArr.toString());
    this.save.emit(true);
  }
}
