import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'genericFilter',
})

export class GenericFilterPipe<T> implements PipeTransform {
    public transform(value: T[], filter: Partial<T>): T[] {
        if (value) {
            return value.filter(v => Object.keys(filter).every(key => v[key] === filter[key]));
        }
        return [];
    }
}