<h2 mat-dialog-title>
  Publish
</h2>
<mat-dialog-content class="publish mat-typography">
  <mat-radio-group (change)="inputChanges()" [formControl]="selectedDeviceId" color="primary">
    <p *ngFor="let device of devices">
      <mat-radio-button [disabled]="device.status == 'offline' || !device.status" value="{{ device.id }}">
        <b>Naam: </b>{{ device.name }}<br />
        <b>Type: </b>
        <span *ngIf="device.type == 'wt2'">WhiteTable 2.0</span>
        <span *ngIf="device.type == 'wt3'">WhiteTable 3.0</span><br />
        <b>Status: </b>
        <span *ngIf="device.status == 'offline'" class="offline"
          >Offline sinds {{ device.lastOnline | date: 'd LLLL H:mm' }}</span
        >
        <span *ngIf="device.status == 'available'" class="online">Online</span>
        <span *ngIf="!device.status">No data</span>
      </mat-radio-button>
    </p>
  </mat-radio-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button [disabled]="!deviceSelected" mat-raised-button (click)="publishOnDevice()">
    Publish
  </button>
</mat-dialog-actions>
