import { Slide } from './slide';
import { Asset } from './asset/asset';
import { Visibility } from './visibility';
import { StoryType } from './storyType';

export class Story {
  public id: number;
  public name: string;
  public created: Date;
  public updated: Date;
  public templateId: number;
  public slides: Slide[];
  public assets: Asset[];
  public visibility: Visibility;
  public modelId: number;
  public storyType: StoryType;
}
