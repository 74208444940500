import { Injectable } from '@angular/core';
import { LinkMode } from '@shared/models/domain-models/tree-structure/link-mode';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LinkModeService {
  public slideData: Observable<object>;
  public linkMode: Observable<LinkMode>;
  private slideDataSubject: Subject<object>;
  private linkModeSubject: Subject<LinkMode>;

  constructor() {
    this.slideDataSubject = new Subject<object>();
    this.slideData = this.slideDataSubject.asObservable();
    this.linkModeSubject = new Subject<LinkMode>();
    this.linkMode = this.linkModeSubject.asObservable();
  }

  public getSlideData() {
    return this.slideData;
  }

  public setSlideData(slideData) {
    this.slideDataSubject.next(slideData);
  }

  public setLinkMode(linkMode:  LinkMode) {
    this.linkModeSubject.next(linkMode);
  }
}
