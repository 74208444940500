<st-header>
  <ul *ngIf="story$ | async as story" class="nav-main">
    <!-- <li *ngFor="let editorModeItem of ['web', 'wt2', 'wt3']" class="nav-main__item"
      [class]="(editorMode$ | async) === editorModeItem ? 'selected' : ''">
      <button mat-icon-button (click)="changeEditorMode(editorModeItem)">
        <mat-icon matTooltip="{{ editorModeItem }}" matTooltipPosition="below">
          <span *ngIf="editorModeItem === 'web'">computer</span>
          <span *ngIf="editorModeItem === 'wt2'">tv</span>
          <span *ngIf="editorModeItem === 'wt3'">cast</span>
        </mat-icon>
      </button>
    </li> -->
    <h3>{{ story.name }}</h3>
  </ul>
</st-header>

<div class="st-grid editor">
  <st-story-panel [story]="story$ | async"></st-story-panel>
  <st-edit-panel [editorMode]="editorMode$ | async"></st-edit-panel>
  <st-slide-panel
    *ngIf="selectedSlide$ | async"
    [touchpoints]="touchpoints$ | async"
    [slides]="slides$ | async"
    [slide]="selectedSlide$ | async"
  ></st-slide-panel>
</div>
