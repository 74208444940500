import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { StoriesSortPipe } from './pipes/stories-sort-pipe/stories-sort.pipe';
import { StoriesComponent } from './components/stories/stories.component';
import { DashboardComponent } from './dashboard.component';
import { StoryCardComponent } from './components/story-card/story-card.component';
import { SearchFilterPipe } from './pipes/search-filter/search-filter.pipe';
import { CreateStoryDialogComponent } from './components/create-story-dialog/create-story-dialog.component';

@NgModule({
  declarations: [
    DashboardComponent,
    StoriesComponent,
    StoriesSortPipe, 
    StoryCardComponent,
    SearchFilterPipe,
    CreateStoryDialogComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class DashboardModule {}
