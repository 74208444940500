export enum GraphType {
  BAR_CHART = 'barChart',
  PIE_CHART = 'pieChart',
  DOUGHNUT_CHART = 'doughnutChart',
  LINE_CHART = 'lineChart',
  BARCHARTLINE = 'barChartLine',
  RADAR_CHART = 'radarChart',
  SCATTER_CHART = 'scatterChart',
  BUBBLE_CHART = 'bubbleChart',
  FUNNEL_CHART = 'funnel',
}
