import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { TreeModule } from '@circlon/angular-tree-component';
import { TreeComponent } from './tree.component';
import { TreeStoryPanelComponent } from './tree-story-panel/tree-story-panel.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SlideLinkerComponent } from './tree-story-panel/components/slide-linker/slide-linker.component';
import { RootSlideChangerComponent } from './tree-story-panel/components/root-slide-changer/root-slide-changer.component';

@NgModule({
  declarations: [TreeComponent, TreeStoryPanelComponent, SlideLinkerComponent, RootSlideChangerComponent],
  imports: [CommonModule, TreeModule, SharedModule, ScrollingModule, DragDropModule],
})
export class TreeEditorModule {}
