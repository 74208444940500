<div>
  <mat-card-title>Background Editor</mat-card-title>
  <mat-card-content>
    <mat-form-field>
      <label>Background type</label>
      <mat-select [value]="backgroundType" (selectionChange)="changeSelect($event)">
        <mat-option value="image">Image</mat-option>
        <mat-option value="video">Video</mat-option>
        <mat-option value="default">Default</mat-option>
      </mat-select>
    </mat-form-field>
    <br />
    <st-video-element
      (save)="saveBackground($event)"
      [selectedElement]="selectedElement"
      [assets]="assets | genericFilter: { type: assetType.VIDEO }"
      *ngIf="backgroundType === 'video'"
    ></st-video-element>
    <st-image-element
      *ngIf="backgroundType === 'image'"
      (save)="saveBackground($event)"
      [selectedElement]="selectedElement"
      [assets]="assets | genericFilter: { type: assetType.ORIGINAL_IMAGE }"
      [storyId]="storyId"
    >
    </st-image-element>
    <div *ngIf="backgroundType === 'default'">
      <label style="display: block;">Background color</label>

      <div
        id="primary_color"
        style="margin-top: 5px;"
        [style.background-color]="backgroundColor"
        [(colorPicker)]="backgroundColor"
        [cpOKButton]="true"
        [cpCancelButton]="true"
        (colorPickerSelect)="changeBgColor($event)"
      ></div>
      <!-- <color-chrome
        *ngIf="showColorPicker"
        [color]="backgroundColor"
        (onChangeComplete)="changeBgColor($event)"
      ></color-chrome> -->
    </div>
  </mat-card-content>
</div>
