import { EventEmitter, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LinkBlot } from './blots/link-blot';
import Quill from 'quill';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { TextLinkPopupComponent } from './popups/text-link-popup/text-link-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'st-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public content: string;
  @Input() public disableLists = true;
  @Output() public contentEvent = new EventEmitter<any>();
  public control: FormControl;
  public quillFormats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
    'line-height',
  ];
  private editor: Quill;
  private subscriptions: Subscription[] = [];

  constructor(private dialog: MatDialog){}

  public ngOnInit() {
    const colorClass = Quill.import('attributors/class/color');
    const colorStyle = Quill.import('attributors/style/color');
    const directionStyle = Quill.import('attributors/style/direction');
    const backgroundStyle = Quill.import('attributors/style/background');
    const alignStyle = Quill.import('attributors/style/align');
    const fontSizeStyle = Quill.import('attributors/style/size');
    fontSizeStyle.whitelist = ['0.4em', '0.6em', '0.8em', '1em', '1.2em', '1.4em', '1.6em'];

    // Creates Attributor for line height
    const Parchment = Quill.import('parchment')
    const lineHeightConfig = {
      scope: Parchment.Scope.INLINE,
      whitelist: ['1', '1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.8', '2', '3', '4', '5']
    } 
    const lineHeightClass= new Parchment.Attributor.Class('line-height', 'ql-line-height', lineHeightConfig);
    const lineHeightStyle = new Parchment.Attributor.Style('line-height', 'line-height', lineHeightConfig);
    Parchment.register(lineHeightClass);
    Parchment.register(lineHeightStyle);

    Quill.register(colorClass, true);
    Quill.register(colorStyle, true);
    Quill.register(directionStyle, true);
    Quill.register(backgroundStyle, true);
    Quill.register(alignStyle, true);
    Quill.register(fontSizeStyle, true);

    LinkBlot.blotName = 'link';
    LinkBlot.tagName = 'a';
    LinkBlot.className = 'link-blot';
    Quill.register(LinkBlot);

    this.control = this.control ?? new FormControl();
    this.control.setValue(this.content);

    
    // set initial value and ignore emitEvents for valueChanges subscription
    this.control.setValue(this.content, { emitEvent: false });
    // subscribe to value changes to control emit events and skip initial undefined value
    this.subscriptions.push(
      this.control.valueChanges.pipe(skip(1)).subscribe(text => {
        this.contentEvent.emit(text);
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['content']) {
      if (this.content && this.control) {
        this.control.setValue(this.content, { emitEvent: false });
      }
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public editorCreated(editor: Quill){
    this.editor = editor;
    editor.on('selection-change',(range : {index: number, length: number}) => {
      if(range){
         // Useful for seeing what properties exist on selection
        // const format = editor.getFormat(range);
        // console.log(format);
      }
      
    })
  }


  public setLink(){
    const selection: {index: number, length: number} = this.editor.getSelection();
    if(selection){
      const format = this.editor.getFormat(selection);
      if(format['link']){
        this.editor.format('link', undefined);
        this.control.setValue(this.editor.root.innerHTML, { emitEvent: true });
      }
      else{
        const dialogRef = this.dialog.open(TextLinkPopupComponent, {
          width: '600px',
          height: '400px',
        });
    
        dialogRef.afterClosed().subscribe((link: string) => {
         if(link){
          this.editor.format('link', link);
          this.control.setValue(this.editor.root.innerHTML, { emitEvent: true });
         }
        });
      }
    }
  }
  
}
