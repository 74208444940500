import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Touchpoint } from '@shared/models/entities/touchpoint';
import { UpdateDto } from '@shared/dto/update.dto';
import { Observable } from 'rxjs';
import { AddTouchpointDto } from '@shared/dto/addTouchpoint.dto';

@Injectable({
  providedIn: 'root',
})
export class TouchpointService {
  private readonly URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public removeLink(id: number) {
    return this.http.delete<any>(`${this.URL}/touchpoint/deleteTouchlpointById/${id}`);
  }

  public addTouchpoint(dto: AddTouchpointDto): Observable<Touchpoint[]> {
    return this.http.post<Touchpoint[]>(`${this.URL}/touchpoint/addTouchpoint`, dto);
  }

  public updateTouchpointStyle(touchpointId: number, templateTouchpointId: number) {
    return this.http.put<Touchpoint>(`${this.URL}/touchpoint/updateTouchpointStyle/${touchpointId}`, {
      id: templateTouchpointId,
    });
  }

  public update(key: Partial<Touchpoint>, filter: Partial<Touchpoint>): Observable<Touchpoint> {
    const updateDto = new UpdateDto(key, filter);
    return this.http.put<Touchpoint>(`${this.URL}/touchpoint/update`, updateDto);
  }

  public updateTouchpointTree(data: any) {
    return this.http.put<boolean>(`${this.URL}/touchpoint/updateTouchpointTree`, data);
  }
}
