import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Store } from '@ngrx/store';
import { TextEditorPopupComponent } from '@shared/components/text-editor/text-editor-popup/text-editor-popup.component';
import { UpdateDto } from '@shared/dto/update.dto';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { ParagraphElement } from '@shared/models/domain-models/editor/parser/elements/paragraphElement';
import { TitleElement } from '@shared/models/domain-models/editor/parser/elements/titleElement';
import { StElementType } from '@shared/models/entities/stElements/stElementType';
import { StTextElement } from '@shared/models/entities/stElements/stTextElement.entity';
import { GoogleService } from '@shared/services/http/google-service/google.service';
import { updateStElement } from '@shared/store/actions/story.actions';
import { DefaultFont, Font } from '@storytemple/models';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'st-text-element',
  templateUrl: './text-element.component.html',
  styleUrls: ['./text-element.component.scss'],
})
export class TextElementComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public selectedElement: TitleElement | ParagraphElement;
  public fontControl = new FormControl();
  public filteredFonts: Observable<Font[]>;
  public editorText: string;
  public textData = undefined;
  private timeout;
  private fonts: Font[];

  constructor(private store: Store<AppState>, private googleService: GoogleService, private dialog: MatDialog) {}

  public async ngOnInit() {
    this.fonts = await this.googleService.getFonts().toPromise();
    this.filteredFonts = this.fontControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => this.fonts.filter(font => font.name.toLowerCase().includes(value.toLowerCase()))),
    );
    this.editorText = this.selectedElement.stTextElement.content;
  }

  public ngOnDestroy(): void {
    if (this.textData) {
      const dto = new UpdateDto<StTextElement>(
        { slideId: this.selectedElement.stTextElement.slideId, slotId: this.selectedElement.stTextElement.slotId },
        { stElementType: StElementType.STTEXTELEMENT, content: this.textData.text, fontSize: this.textData.fontSize },
      );
      this.store.dispatch(updateStElement({ dto, combine: false }));
    }
  }

  public ngOnChanges(): void {
    this.editorText = this.selectedElement.stTextElement.content;
  }

  public changeFont(event) {
    const fontName: string = event.option.value;
    const font: Font = this.fonts.find(f => f.name === fontName);
    this.selectedElement.changeFont(font.name);
    if (font instanceof DefaultFont) {
      const dto = new UpdateDto<StTextElement>(
        { slideId: this.selectedElement.stTextElement.slideId, slotId: this.selectedElement.stTextElement.slotId },
        { stElementType: StElementType.STTEXTELEMENT, font: font.name, fallbackFont: font.fallback },
      );
      this.store.dispatch(updateStElement({ dto, combine: false }));
    } else {
      // TODO Google Fonts --> Only Roboto for now
      const dto = new UpdateDto<StTextElement>(
        { slideId: this.selectedElement.stTextElement.slideId, slotId: this.selectedElement.stTextElement.slotId },
        { stElementType: StElementType.STTEXTELEMENT, font: font.name },
      );
      this.store.dispatch(updateStElement({ dto, combine: false }));
    }
  }

  public onContentChanged(text: string) {
    if (!text) {
      text = '';
    }

    this.editorText = text;
    const autoSize = this.selectedElement.stTextElement.autoSize;
    this.textData = this.selectedElement.editElement(text, autoSize);

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const dto = new UpdateDto<StTextElement>(
        { slideId: this.selectedElement.stTextElement.slideId, slotId: this.selectedElement.stTextElement.slotId },
        {
          stElementType: StElementType.STTEXTELEMENT,
          content: this.textData.text,
          fontSize: this.textData.fontSize,
          autoSize,
        },
      );
      this.store.dispatch(updateStElement({ dto, combine: false }));
    }, 500);
  }

  public expandTextEditor() {
    const dialogRef = this.dialog.open(TextEditorPopupComponent, {
      width: '1000px',
      height: '800px',
      data: { content: this.editorText },
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      this.selectedElement.stTextElement.content = result;
      this.onContentChanged(result);
    });
  }

  public toggleAutoSize(event: MatSlideToggleChange) {
    this.selectedElement.stTextElement.autoSize = event.checked;
    this.onContentChanged(this.editorText);
  }
}
