<div>
  <mat-card-title>Video Editor</mat-card-title>
  <mat-card-content>
    <h3 class="mb-1rem">Videos</h3>
    <mat-form-field>
      <label>Select video</label>
      <mat-select [value]="activeAsset" (selectionChange)="setVideo($event)">
        <mat-option *ngIf="activeAsset === undefined" value="none">None</mat-option>
        <mat-option *ngFor="let asset of assets" [value]="asset">{{ asset.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-button class="marginAuto" (click)="uploader.click()">
      <mat-icon aria-hidden="false" aria-label="home icon">cloud_upload</mat-icon>
      Upload video
    </button>
    <input hidden type="file" multiple accept="video/*" #uploader (input)="uploadVideos($event)" />
    <ng-container *ngIf="videoOptions">
      <mat-checkbox class="videoOptions-checkbox" color="primary" [checked]="videoOptions.loop" (change)="loopVideo()"
        >Loop video</mat-checkbox
      >
      <mat-checkbox
        *ngIf="selectedElement.elementType !== ElementType.BACKGROUND_ELEMENT"
        class="videoOptions-checkbox"
        color="primary"
        [checked]="videoOptions.mute"
        (change)="muteVideo()"
        >Mute video</mat-checkbox
      >
      <mat-checkbox
        *ngIf="selectedElement.elementType !== ElementType.BACKGROUND_ELEMENT"
        class="videoOptions-checkbox"
        color="primary"
        [checked]="videoOptions.autoplay"
        (change)="autoplayVideo()"
        >Autoplay video</mat-checkbox
      >
      <mat-checkbox
        *ngIf="selectedElement.elementType === ElementType.BACKGROUND_ELEMENT"
        class="videoOptions-checkbox"
        color="primary"
        [checked]="videoOptions.controls"
        (change)="setVideoControls()"
        >Video controls</mat-checkbox
      >
    </ng-container>
  </mat-card-content>
</div>
