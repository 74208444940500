import { AfterViewChecked, Component, ElementRef, HostListener, Input, OnChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { EditorMode } from '@shared/models/entities/editor-mode';
import { Iframe } from '@shared/models/entities/iframe';
import { Touchpoint } from '@shared/models/entities/touchpoint';
import { TouchpointDeviceType } from '@shared/models/entities/touchpointDeviceType';
import { TouchpointType } from '@shared/models/entities/touchpointType';
import { SlideService } from '@shared/services/http/slide-service/slide.service';
import { setSelectedSlide, setSelectedTouchpoint } from '@shared/store/actions/editor.actions';
import { updateTouchpoint } from '@shared/store/actions/story.actions';

@Component({
  selector: 'st-web-touchpoints',
  templateUrl: './web-touchpoints.component.html',
  styleUrls: ['./web-touchpoints.component.scss'],
})
export class WebTouchpointsComponent implements AfterViewChecked, OnChanges {
  @Input() public touchpoints: Touchpoint[];
  @Input() public show: boolean;
  @ViewChild('storySlide') public storySlide: ElementRef;
  @ViewChild('touchpointContainer', { static: true }) public touchpointContainer: ElementRef;
  public touchPointsForMode: Touchpoint[];
  public editorMode: EditorMode;
  public isDragging = false;
  private isSingleClick = true;

  constructor(public service: SlideService, private store: Store<AppState>) {}

  @HostListener('window:resize')
  public setTouchPointContainerStyles(): void {
    const storySlideBox = document.querySelector('#storySlide').getBoundingClientRect();
    const width = Math.min((storySlideBox.height / 9) * 16, storySlideBox.width);
    const height = Math.min((storySlideBox.width / 16) * 9, storySlideBox.height);
    this.touchpointContainer.nativeElement.style.width = `${width}px`;
    this.touchpointContainer.nativeElement.style.height = `${height}px`;
  }

  public ngOnChanges(): void {
    this.touchPointsForMode = this.touchpoints.filter(tp => tp.deviceType === TouchpointDeviceType.web);
  }

  public ngAfterViewChecked(): void {
    this.setTouchPointContainerStyles();
  }

  public touchPointStyles(touchPoint: Touchpoint): object {
    const styles: { [k: string]: any } = {
      'width.%': touchPoint.width,
      'height.%': touchPoint.height,
    };

    const box = this.touchpointContainer.nativeElement.getBoundingClientRect();
    styles.transform = `translate(${box.width * (touchPoint.x / 100)}px, ${box.height * (touchPoint.y / 100)}px)`;

    return styles;
  }

  public mouseOverTouchpoint(touchpoint: Touchpoint) {
    if (touchpoint.type === null || touchpoint.type === TouchpointType.LINK) {
      const preview = document.querySelector(`#slide-tree-${touchpoint.nextSlideId}`);
      preview.classList.add('next-slide');
    }
  }

  public mouseOutTouchpoint(touchpoint: Touchpoint) {
    if (touchpoint.type === null || touchpoint.type === TouchpointType.LINK) {
      const preview = document.querySelector(`#slide-tree-${touchpoint.nextSlideId}`);
      preview.classList.remove('next-slide');
    }
  }

  /**
   * Emits when the user starts dragging the item.
   */
  public start() {
    this.isDragging = true;
  }

  /**
   * Called when touchpoint is dropped and calculates x and y based on slide
   *
   * @param event - drop event generated by drag and drop CDK
   * @param touchpoint - dropped touchpoint
   * @param iframe - dropped iframe
   */
  public drop(event, draggableElement: Touchpoint & Iframe) {
    this.isDragging = false;
    // Reset size for touchpoint resize
    event.source._dragRef._previewRect = null;
    const tpElement: Element = event.source.getRootElement();
    const tpRect = tpElement.getBoundingClientRect();
    const slideRect = (tpElement.parentNode as Element).getBoundingClientRect();

    const x = Number((((tpRect.x - slideRect.left) / slideRect.width) * 100).toFixed(2));
    const y = Number((((tpRect.y - slideRect.top) / slideRect.height) * 100).toFixed(2));
    this.store.dispatch(updateTouchpoint({ key: { id: draggableElement.id }, filter: { x, y } }));
  }

  /**
   * Called when touchpoint is clicked and creates an touchpoint element to be edited.
   *
   * @param touchpoint - clicked touchpoint
   */
  public editTouchpoint(touchpoint: Touchpoint) {
    this.isSingleClick = true;
    if (this.isSingleClick) {
      this.store.dispatch(setSelectedTouchpoint({ touchpointId: touchpoint.id }));
    }
  }

  public doubleClick(touchpoint: Touchpoint) {
    if (touchpoint.type === TouchpointType.URL) {
      window.open(touchpoint.link, '_blank');
    } else {
      this.isSingleClick = false;
      this.store.dispatch(setSelectedSlide({ slideId: touchpoint.nextSlideId }));
    }
  }
}
