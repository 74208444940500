import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import * as copy from 'copy-to-clipboard';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'st-sessioncode',
  templateUrl: './sessioncode.component.html',
  styleUrls: ['./sessioncode.component.scss'],
})
export class SessioncodeComponent implements OnInit {
  public sessionCode: string;
  public sessionCodeUrl: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public toast: ToastrService) {}
  
  public ngOnInit() {
    this.sessionCode = this.data;
    this.sessionCodeUrl = environment.IAPP_URL + 'home/' + this.sessionCode;
  }

  public copyToClipboard() {
    this.toast.success('Iapp url Coppied to Clipboard!');
    copy(this.sessionCodeUrl);
  }
}
