<form class="int-sheet-form" [formGroup]="sliderSlideForm">
  <p>
    <label>How many steps in the app:</label>
    <mat-radio-group formControlName="radioButtons" aria-label="Select an option" color="primary">
      <mat-radio-button [checked]="range == '10'" value="10">0 to 10 </mat-radio-button>
      <mat-radio-button [checked]="range == '5'" value="5">0 to 5</mat-radio-button>
    </mat-radio-group>
    <mat-form-field color="primary">
      <input matInput type="number" placeholder="Time (seconds)" formControlName="seconds" value="5" min="5" />
    </mat-form-field>
  </p>
</form>
