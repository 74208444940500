import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ToastrModule } from 'ngx-toastr';
import { RoutingModule } from 'src/app/routing.module';
import { EditPanelComponent } from './components/edit-panel/edit-panel.component';
import { ImageElementComponent } from './components/edit-panel/elements/image-element/image-element.component';
import { ImageSelectorComponent } from './components/edit-panel/elements/image-element/image-selector/image-selector.component';
import { TextElementComponent } from './components/edit-panel/elements/text-element/text-element.component';
import { TouchpointElementComponent } from './components/edit-panel/elements/touchpoint-element/touchpoint-element.component';
import { EditorComponent } from './editor.component';
import { SlidePanelComponent } from './components/slide-panel/slide-panel.component';
import { StoryPanelComponent } from './components/story-panel/story-panel.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedModule } from '@shared/shared.module';
import { InteractiveElementComponent } from './components/edit-panel/elements/interactive-element/interactive-element.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageComponent } from './components/edit-panel/elements/image-element/image/image.component';
import { MatTabsModule } from '@angular/material/tabs';
import { VideoElementComponent } from './components/edit-panel/elements/video-element/video-element.component';
import { VideoEditButtonComponent } from './components/slide-panel/video-edit-button/video-edit-button.component';
import { SlideNotesComponent } from './components/slide-panel/slide-notes/slide-notes.component';
import { PlaceholderService } from './components/slide-panel/services/placeholder-service/placeholder-service.service';
// eslint-disable-next-line max-len
import { MultiplechoiceElementComponent } from './components/edit-panel/elements/interactive-element/multiplechoice-element/multiplechoice-element.component';
import { WordcloudElementComponent } from './components/edit-panel/elements/interactive-element/wordcloud-element/wordcloud-element.component';
import { SliderElementComponent } from './components/edit-panel/elements/interactive-element/slider-element/slider-element.component';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { BackgroundElementComponent } from './components/edit-panel/elements/background-element/background-element.component';
import { GraphElementComponent } from './components/edit-panel/elements/graph-element/graph-element.component';
import { GraphDataEditorComponent } from './components/edit-panel/elements/graph-element/graph-data-editor/graph-data-editor.component';
import { ModelElementComponent } from './components/edit-panel/elements/model-element/model-element.component';
import { WhitetableGridComponent } from './components/slide-panel/touchpoint-containers/whitetable-grid/whitetable-grid.component';
import { WebTouchpointsComponent } from './components/slide-panel/touchpoint-containers/web-touchpoints/web-touchpoints.component';
import { AddButtonComponent } from '@shared/components/add-button/add-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TableElementComponent } from './components/edit-panel/elements/table-element/table-element.component';
import { TableDataEditorComponent } from './components/edit-panel/elements/table-element/table-data-editor/table-data-editor.component';
import { UrlTpAdderComponent } from './components/slide-panel/components/url-tp-adder/url-tp-adder.component';
import { QuillModule } from 'ngx-quill';
import { RichTextEditorModule } from '@shared/components/rich-text-editor/rich-text-editor.module';
import { TextLinkPopupComponent } from '@shared/components/rich-text-editor/popups/text-link-popup/text-link-popup.component';

@NgModule({
    declarations: [
        EditorComponent,
        SlidePanelComponent,
        EditPanelComponent,
        StoryPanelComponent,
        TextElementComponent,
        ImageElementComponent,
        ImageSelectorComponent,
        TouchpointElementComponent,
        InteractiveElementComponent,
        ImageComponent,
        VideoElementComponent,
        VideoEditButtonComponent,
        SlideNotesComponent,
        MultiplechoiceElementComponent,
        WordcloudElementComponent,
        SliderElementComponent,
        BackgroundElementComponent,
        GraphElementComponent,
        GraphDataEditorComponent,
        ModelElementComponent,
        WhitetableGridComponent,
        WebTouchpointsComponent,
        AddButtonComponent,
        TableElementComponent,
        TableDataEditorComponent,
        UrlTpAdderComponent,
        TextLinkPopupComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        RoutingModule,
        ToastrModule.forRoot({ preventDuplicates: true }),
        ImageCropperModule,
        SharedModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
        DragDropModule,
        MatCheckboxModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        FormsModule,
        MatListModule,
        MatRadioModule,
        MatTableModule,
        AngularSvgIconModule.forRoot(),
        QuillModule.forRoot(),
        RichTextEditorModule,
    ],
    providers: [PlaceholderService]
})
export class EditorModule {}
