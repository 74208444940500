import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ShareURlWithDate } from '@shared/models/entities/ShareURLWithDate';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  public _linkReceivedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public linkReceivedObs: Observable<boolean> = this._linkReceivedSubject.asObservable();

  private readonly URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public existingLink(id: number): Observable<ShareURlWithDate> {
    this._linkReceivedSubject.next(false);
    return this.http.get<ShareURlWithDate>(`${this.URL}/share/linkExists/${id}`);
  }

  public requestPublicShareLink(id: number, date?: Date): Promise<ShareURlWithDate> {
    this._linkReceivedSubject.next(false);
    return new Promise<ShareURlWithDate>(resolve => {
      let dateUTC = '';
      if (date === undefined) {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + 14);
        dateUTC = date.toUTCString();
      } else {
        dateUTC = date.toUTCString();
      }
      this.http
        .post<ShareURlWithDate>(`${this.URL}/share/requestShareLink/${id}`, { expiryDate: dateUTC })
        .subscribe(data => {
          this._linkReceivedSubject.next(true);
          resolve(data);
        });
    });
  }

  public storyToPdf(storyId: number): Observable<Buffer> {
    return this.http.get<Buffer>(`${this.URL}/story/toPDF/${storyId}`);
  }
}
