import { trigger, transition, query, style, stagger, animate, keyframes } from '@angular/animations';

export const CardAnimations = {
  // Trigger animation cards array
  cardAnimation: trigger('cardAnimation', [
    // Transition from any state to any state
    transition('* => *', [
      // Initially the all cards are not visible
      query(':enter', style({ opacity: 0 }), { optional: true }),
      // Each card will appear sequentially with the delay of 300ms
      query(
        ':enter',
        stagger('50ms', [
          animate(
            '.2s ease-in',
            keyframes([
              style({ opacity: 0, transform: 'translateY(-10%)', offset: 0 }),
              style({ opacity: 0.5, transform: 'translateY(-10px)', offset: 0.3 }),
              style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
            ]),
          ),
        ]),
        { optional: true },
      ),
    ]),
  ]),

  cardExitAnimation: trigger('cardExitAnimation', [
    // Cards will disappear sequentially with the delay of 300ms
    transition(
      'closed => open',
      animate(
        '2000ms ease-out',
        keyframes([
          style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
          style({ opacity: 0.5, transform: 'translateY(-15px)', offset: 0.3 }),
          style({ opacity: 0, transform: 'translateY(-30%)', offset: 1 }),
        ]),
      ),
    ),
  ]),
};
