import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UploadStatusInterface } from './upload-status-interface';

@Component({
  selector: 'st-upload-status',
  templateUrl: './upload-status.component.html',
  styleUrls: ['./upload-status.component.scss'],
})
export class UploadStatusComponent implements OnInit, OnDestroy {
  // Current number of files being processed in first progressbar.
  public indeterminateCounter = 1;
  // Max number of files being processed in first progressbar.
  public indeterminateTotalCount: number;
  // Current number of files being processed in second progressbar.
  public bufferCounter = 0;
  // Max number of files being processed in first progressbar.
  public bufferTotalCount: number;

  // Booleans which determine which view to show.
  public indeterminateBarIsDone = false;
  public bufferBarIsDone = false;
  public bufferBarIsStarted = false;

  // EventEmitters which are passing on the count
  private indeterminateCountEmitter: EventEmitter<number>;
  private bufferCountEmitter: EventEmitter<number>;

  // EventEmitter which let's the parent know when the progressbar are done.
  private runWhenDone: EventEmitter<void>;
  private subscriptions: Subscription[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: UploadStatusInterface) {}

  public ngOnInit() {
    // Get total files for progress bars.
    this.indeterminateTotalCount = this.data.indeterminateTotalCount;
    this.bufferTotalCount = this.data.bufferTotalCount;

    // Get emitters
    this.indeterminateCountEmitter = this.data.indeterminateCountEmitter;
    this.bufferCountEmitter = this.data.bufferCountEmitter;
    this.bufferCountEmitter = this.data.bufferCountEmitter;
    this.runWhenDone = this.data.runWhenDone;

    // Subscribe on indeterminateCountEmitter.
    this.subscriptions.push(this.indeterminateCountEmitter.subscribe((count) => {
      // Start buffer bar after first file of indeterminate bar is done.
      if (this.bufferCounter === 0) {
        this.bufferCounter++;
        this.bufferBarIsStarted = true;
      }

      // Set indeterminate bar on done when counter is equal to total count
      // Else counter + 1
      if (this.indeterminateCounter === this.indeterminateTotalCount) {
        this.indeterminateBarIsDone = true;
      } else {
        this.indeterminateCounter += count;
      }
    }));
    // Subscribe on bufferCountEmitter.
    this.subscriptions.push(this.bufferCountEmitter.subscribe((count) => {
      // Set buffer bar on done when counter is equal to total count.
      // Else counter + 1
      if (this.bufferCounter === this.bufferTotalCount) {
        this.bufferBarIsDone = true;
        this.runWhenDone.emit();
      } else {
        this.bufferCounter += count;
      }
    }));
  }

  public ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
