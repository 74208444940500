import { Component, OnDestroy, OnInit } from '@angular/core';
import { PreviousRouteService } from '@shared/services/local/previous-route/previous-route.service';
import { CurrentStoryService } from '@shared/services/local/current-story/current-story.service';
import { StorySocket } from '@shared/services/local/story-socket/story.event';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@shared/services/local/analytics/analytics.service';
import { StoryService } from '@shared/services/http/story-service/story.service';
import { Story } from '@shared/models/entities/story';
import { MatDialog } from '@angular/material/dialog';
import { SessioncodeComponent } from '@shared/components/sessioncode/sessioncode.component';
import { TouchpointDeviceType } from '@shared/models/entities/touchpointDeviceType';
import { Subscription } from 'rxjs';
import gtag from 'ga-gtag';
import { SlideType } from '@shared/models/entities/slideType';
import { filter } from 'rxjs/operators';
import { PlayerEvent } from '@shared/models/domain-models/player/playerEvent';

@Component({
  selector: 'st-story-view',
  templateUrl: './story-view.component.html',
  styleUrls: ['./story-view.component.scss'],
})
export class StoryViewComponent implements OnInit, OnDestroy {
  public showControls = true;
  public assetsLoaded = false;
  public storyId;
  public shareToken: string;
  public sessionCode: string;
  public story: Story;
  public initialSlideId: number;
  public dialogIsOpen = false;
  public routeSubscription: Subscription
  constructor(
    private previousRouteService: PreviousRouteService,
    private route: ActivatedRoute,
    private router: Router,
    private socket: StorySocket,
    private currentStoryService: CurrentStoryService,
    private analyticsService: AnalyticsService,
    private storyService: StoryService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    this.routeSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        gtag('event', `storyview_id: ${this.route.snapshot.params.id}`);
        const slideId = this.route.snapshot.paramMap.get('slideId')
        if(slideId){
          gtag('event', `storyview_id: ${this.route.snapshot.params.id}, slide_id: ${slideId}`);
        }
      });
  }

  public async ngOnInit() {
    this.storyId = this.route.snapshot.params.id;
    this.shareToken = this.route.snapshot.queryParams.shareToken;
    this.story = await this.storyService.getStoryById(this.storyId, this.shareToken).toPromise();

    const slideId = this.route.snapshot.paramMap.get('slideId');
    this.initialSlideId = slideId !== null ? Number(slideId) : this.story.slides.find(sl => sl.slideType === SlideType.ROOT).id;
   
    this.story.slides.forEach(
      s => (s.touchpoints = s.touchpoints.filter(tp => tp.deviceType === TouchpointDeviceType.web)),
    );
    this.currentStoryService.assetsLoaded.subscribe(loaded => {
      this.assetsLoaded = loaded;
      // this.analyticsService.publish(false, this.route.snapshot.params.id);
    });
    if (this.currentStoryService.storyInPresenterView) {
      this.currentStoryService.presenterViewId = this.route.snapshot.queryParamMap.get('presenterView');
      this.socket.emit('joinRoom', this.currentStoryService.presenterViewId);
    }

    this.socket.on('disconnected', () => {
      console.log('disconnected');
      this.currentStoryService.inPresenterView = false;
      this.currentStoryService.presenterViewId = null;

      // In case the presenter view refreshes, close this window.
      if (this.currentStoryService.storyInPresenterView) {
        window.close();
      }
    });

    this.socket.on('goToSlide', (slideId: number) => {
      this.router.navigate(
        [`/story${this.currentStoryService.presenterViewPath}`, this.currentStoryService.currentStoryId, slideId],
        {
          queryParamsHandling: 'merge',
        },
      );
    });
  }

  public ngOnDestroy(){
    this.routeSubscription.unsubscribe();
  }

  public openDialog() {
    if (this.dialogIsOpen === false) {
      this.dialogIsOpen = true;
      const dialogRef = this.dialog.open(SessioncodeComponent, {
        data: this.sessionCode,
      });
      dialogRef.afterClosed().subscribe(() => {
        this.dialogIsOpen = false;
      });
    }
  }

  public getSessionCode(code) {
    this.sessionCode = code;
  }

  public listenToPlayerEvents(event: PlayerEvent){
    switch(event.eventName){
      case 'slideChange':
        gtag('event', `storyview_id: ${event.params.storyId}, slide_id: ${event.params.slideId}`);
        break;
        
      default:
        break;
    }

  }

  get backButtonURL(): string {
    const ref = this.previousRouteService.getPreviousUrl();
    return ref.includes('editor') ? ref.replace(location.origin, '') : '/';
  }
}
