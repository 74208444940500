import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { StoryCardComponent } from '@pages/dashboard/components/story-card/story-card.component';
import { HeaderComponent } from '../header/header.component';
import { ShareService } from '@shared/services/http/shareLink-service/share.service';
import { Subscription } from 'rxjs';
// import { FormControl } from '@angular/forms';
// import { Observable, Subscription } from 'rxjs';
// import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'st-share-menu',
  templateUrl: './share-menu.component.html',
  styleUrls: ['./share-menu.component.scss'],
})
export class ShareMenuComponent implements OnInit, OnDestroy {
  public selectedDate: Date;
  public linkReceived: boolean;

  public storyLink: any;
  public visibility: any;
  public customDate: boolean;
  public minDate: Date;

  public lastFilter = '';

  private storyId: number;
  private storyName: string;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<StoryCardComponent, HeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public shareService: ShareService,
    private clipboard: Clipboard,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.shareService.linkReceivedObs.subscribe(received => {
        this.linkReceived = received;
      }),
    );

    this.storyId = this.data.storyId;

    // set minDate to tomorrow
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.storyName = this.data.storyName;
    this.getShareLinkOnInit();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  public displayFn(): undefined {
    // function to empty the inputfield on select
    return;
  }

  public getShareLinkOnInit(): void {
    this.shareService
      .existingLink(this.storyId)
      .toPromise()
      .then(data => {
        this.shareService._linkReceivedSubject.next(true);
        this.storyLink = data;
      })
      .catch(
        async () =>
          // get a new link in case no active link exists
          await this.shareService.requestPublicShareLink(this.storyId),
      )
      .finally(() => {
        this.storyLink.expiryDate = new Date(this.storyLink.expiryDate).toLocaleDateString('en-GB');
        this.clipboard.copy(this.storyLink.shareURL);
      });
  }

  public async getShareLinkWithCustomDate() {
    this.storyLink = await this.shareService.requestPublicShareLink(this.storyId, this.selectedDate);
    this.storyLink.expiryDate = new Date(this.storyLink.expiryDate).toLocaleDateString('en-GB');
    this.clipboard.copy(this.storyLink.shareURL);
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public async storyToPdf() {
    const buffer: any = await this.shareService.storyToPdf(this.storyId).toPromise();
    this.downloadFile(buffer.data);
  }

  private downloadFile(data) {
    const blob = new Blob([new Uint8Array(data)], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${this.storyName}.pdf`;
    link.click();
  }
}
