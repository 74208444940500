import { Component } from '@angular/core';

@Component({
  selector: 'st-add-button',    
  templateUrl: './add-button.component.html',       
  styleUrls: ['./add-button.component.scss']        
})
export class AddButtonComponent {
  constructor() {}
}
