<div class="slide-selector">
  <h1 mat-dialog-title>Add slide</h1>
  <div mat-dialog-actions>
    <div class="mdc-layout-grid slide-wrapper">
      <mat-tab-group (selectedTabChange)="tabChanged($event)" value="primary">
        <mat-tab label="Standaard">
          <div
            id="template-slides"
            class="mdc-layout-grid__inner"
            *ngIf="previewSlidesBasic?.length !== 0; else noTemplates"
          >
            <div
              *ngFor="let previewSlide of previewSlidesBasic"
              (click)="selectpreviewSlideBasic(previewSlide)"
              class="mdc-layout-grid__cell--span-4 slide-selector-slide basic"
            >
              <h2>{{ previewSlide.name }}</h2>
              <div
                [ngClass]="selectedpreviewSlideBasic === previewSlide ? 'selected' : 'notSelected'"
                [innerHTML]="previewSlide.content | safeHTML"
              ></div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Interactief">
          <div
            id="template-slides"
            class="mdc-layout-grid__inner"
            *ngIf="previewSlidesInteractive?.length !== 0; else noTemplates"
          >
            <div
              *ngFor="let previewSlide of previewSlidesInteractive"
              (click)="selectpreviewSlideInteractive(previewSlide)"
              class="mdc-layout-grid__cell--span-4 slide-selector-slide interactive"
            >
              <h2>{{ previewSlide.name }}</h2>
              <div
                [ngClass]="selectedpreviewSlideInteractive === previewSlide ? 'selected' : 'notSelected'"
                [innerHTML]="previewSlide.content | safeHTML"
              ></div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
      <ng-template #noTemplates class="mdc-layout-grid__inner">
        <h4>No templates available</h4>
      </ng-template>
    </div>
    <button id="insert-button" mat-button (click)="SlideToSubmit()" cdkFocusInitial>
      Insert slide
    </button>
  </div>
</div>
