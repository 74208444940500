<div class="int-section">
  <form class="int-sheet-form" [formGroup]="intSlideForm">
    <mat-form-field color="primary">
      <mat-select [value]="selected" placeholder="Correct answer" formControlName="answer">
        <mat-option *ngFor="let answer of answers; index as i" [value]="i" [innerHtml]="answer.innerHTML"></mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field color="primary">
      <input matInput type="number" placeholder="Time (seconds)" formControlName="seconds" min="5" />
    </mat-form-field>
  </form>
</div>
