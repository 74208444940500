<div class="story-selector">
  <h1 mat-dialog-title>Add story</h1>
  <button class="close" mat-button (click)="onNoClick()">X</button>
  <div>
    <ng-container *ngIf="previewStories$ | async as previewStories">
      <div class="preview-story-wrapper" *ngIf="previewStories?.length !== 0; else noStories">
        <div
          class="preview-story"
          *ngFor="let previewstory of previewStories"
          (click)="selectpreviewStory(previewstory)"
        >
          <h3>{{ previewstory.name }}</h3>
          <div
            *ngIf="previewstory.slides.length > 0"
            [ngClass]="selectedPreviewStory === previewstory ? 'selected' : 'notSelected'"
            class="thumbnail"
            [innerHTML]="previewstory.slides[0].content | safeHTML"
          ></div>
        </div>
      </div>
      <ng-template #noStories>
        <h4>No stories available</h4>
      </ng-template>
      <button
        class="insert-button"
        mat-button
        (click)="storyToSubmit()"
        [disabled]="!previewStories || !selectedPreviewStory ? true : null"
        cdkFocusInitial
      >
        Insert story
      </button>
    </ng-container>
  </div>
</div>
