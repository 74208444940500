<mat-card class="edit-panel">
  <ng-container *ngIf="selectedElement$ | async as selectedElement">
    <div [ngSwitch]="selectedElement.elementType">
      <st-text-element *ngSwitchCase="elementType.TEXT_ELEMENT" [selectedElement]="selectedElement"></st-text-element>
      <st-image-element
        *ngSwitchCase="elementType.IMAGE_ELEMENT"
        [selectedElement]="selectedElement"
        [assets]="assets$ | async | genericFilter: { type: assetType.ORIGINAL_IMAGE }"
        [storyId]="(story$ | async).id"
      ></st-image-element>
      <st-background-element
        *ngSwitchCase="elementType.BACKGROUND_ELEMENT"
        [selectedElement]="selectedElement"
        [assets]="assets$ | async"
        [storyId]="(story$ | async).id"
      ></st-background-element>
      <st-video-element
        *ngSwitchCase="elementType.VIDEO_ELEMENT"
        [selectedElement]="selectedElement"
        [assets]="assets$ | async | genericFilter: { type: assetType.VIDEO }"
      ></st-video-element>
      <st-graph-element
        *ngSwitchCase="elementType.GRAPH_ELEMENT"
        [selectedElement]="selectedElement"
      ></st-graph-element>
      <st-table-element
        *ngSwitchCase="elementType.TABLE_ELEMENT"
        [selectedElement]="selectedElement"
      ></st-table-element>

      <div *ngSwitchDefault>
        <mat-card-title>Editor</mat-card-title>
        <p>Click on element to edit</p>
      </div>
      <button *ngIf="selectedElement$ | async" mat-raised-button (click)="closeEditor()">Done</button>
    </div>
  </ng-container>

  <st-model-element *ngIf="editorMode === 'wt3'" [story]="story$ | async"> </st-model-element>
  <st-touchpoint-element
    *ngIf="selectedTouchpointId"
    [selectedTouchpoint]="selectedTouchpoint$ | async"
    [template]="template$ | async"
    [story]="story$ | async"
    [editorMode]="editorMode"
  ></st-touchpoint-element>
  <button *ngIf="selectedTouchpointId" mat-raised-button (click)="closeEditor()">Done</button>
  <st-interactive-element
    *ngIf="editorService?.slide?.slideType === slideTypes.INTERACTIVE"
    [changeAnswers]="changeAnswers"
  ></st-interactive-element>
</mat-card>
