<div class="table-element">
  <mat-card-title>Table Editor</mat-card-title>
  <!-- Changing Colors -->
  <mat-form-field>
    <mat-label>Border Color</mat-label>
    <input
      matInput
      minlength="4"
      maxlength="7"
      id="borderColor"
      (input)="changeColor($event.target.id, $event.target.value)"
      value="{{ borderColor }}"
    />
    <mat-icon class="color-dot-inside-input" matSuffix [ngStyle]="{ color: borderColor }">brightness_1</mat-icon>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Header Color</mat-label>
    <input
      matInput
      minlength="4"
      maxlength="7"
      id="headerColor"
      (input)="changeColor($event.target.id, $event.target.value)"
      value="{{ headerColor }}"
    />
    <mat-icon class="color-dot-inside-input" matSuffix [ngStyle]="{ color: headerColor }">brightness_1</mat-icon>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Background Color</mat-label>
    <input
      matInput
      minlength="4"
      maxlength="7"
      id="backgroundColor"
      (input)="changeColor($event.target.id, $event.target.value)"
      value="{{ backgroundColor }}"
    />
    <mat-icon class="color-dot-inside-input" matSuffix [ngStyle]="{ color: backgroundColor }">brightness_1</mat-icon>
  </mat-form-field>
  <!-- Update data -->
  <button class="edit-table-data-btn" mat-flat-button color="primary" (click)="editDataDialog()">
    Edit Table Data
  </button>
</div>
