<div class="int-section">
  <form class="int-sheet-form" [formGroup]="wordcloudSlideForm">
    <div>
      <mat-form-field>
        <mat-label>Vote Count</mat-label>
        
        <mat-select class="votecountSelect" formControlName="voteCount" matNativeControl>
          <mat-option value="1">1</mat-option>
          <mat-option value="2">2</mat-option>
          <mat-option value="3">3</mat-option>
          <mat-option value="4">4</mat-option>
          <mat-option value="5">5</mat-option>
          <mat-option value="6">6</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox color="primary" formControlName="checkboxShowAllWords">Show all words on start up</mat-checkbox>
      <mat-checkbox color="primary" formControlName="checkboxOpenAnswer">Enable open answer</mat-checkbox>

      <mat-label>Words for chips</mat-label>
      <mat-form-field>
        <input
          (input)="_keyUp($event.target.value)"
          [attr.disabled]="chipsArr.length >= 20 ? '' : null"
          matInput
          maxlength="14"
          #addWordInput
          formControlName="addWord"
          placeholder="Type your word here"
        />
      </mat-form-field>

      <button
        [attr.disabled]="chipsArr.length >= 20 ? '' : null"
        mat-raised-button
        (click)="addChipWords(addWordInput.value)"
        color="primary"
      >
        add chip
      </button>
      <p>{{ chipsArr.length }} / 20</p>
    </div>
  </form>
  <ul>
    <li *ngFor="let chip of chipsArr; index as i">
      <div>
        <p>{{ chip }}</p>
        <button mat-icon-button (click)="deleteChip(i)"><mat-icon>highlight_off</mat-icon></button>
      </div>
    </li>
  </ul>
</div>
