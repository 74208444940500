import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { Slide } from '@shared/models/entities/slide';
import { Story } from '@shared/models/entities/story';
import { Template } from '@shared/models/entities/template';
import { Touchpoint } from '@shared/models/entities/touchpoint';
import { TouchpointType } from '@shared/models/entities/touchpointType';
import { addLinkTouchpoint, removeLinkTouchpoint } from '@shared/store/actions/story.actions';
import { LinkModeService } from '@shared/services/local/link-mode/link-mode.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { LinkMode } from '@shared/models/domain-models/tree-structure/link-mode';
import { AddLinkTouchpointDto } from '@shared/dto/addLinkTouchpoint.dto';

@Component({
  selector: 'st-tree-story-panel', 
  templateUrl: './tree-story-panel.component.html',
  styleUrls: ['./tree-story-panel.component.scss'],
})
export class TreeStoryPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public story: Story;
  @Input() public touchpoints: Touchpoint[];
  @Input() public selectedSlide: Slide;
  @Input() public template: Template;
  public LinkMode = LinkMode;
  public linkMode: LinkMode;
  public linkSlides: Slide[];
  public slideLinkTouchpointsIds: number[];
  private subscriptions: Subscription[] = [];


  constructor(private router: Router, private store: Store<AppState>, private linkModeService: LinkModeService) {}

  public ngOnChanges(): void {
      this.linkSlides = this.story.slides.filter(s => s.id !== this.selectedSlide.id);
      this.slideLinkTouchpointsIds = this.touchpoints
        .filter(tp => tp.parentSlideId === this.selectedSlide.id && tp.type === TouchpointType.LINK)
        .map(tp => tp.nextSlideId);
  }

  public ngOnInit() {
    this.subscriptions.push(this.linkModeService.linkMode.subscribe((newLinkMode: LinkMode) => {
      this.linkMode = newLinkMode;
    }));
  }

  public ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public editor() {
    this.router.navigate([`editor/${this.story.id}`], { replaceUrl: true }).then(r => r);
  }

  public linkSlide(slide: Slide) {
    const dto = new AddLinkTouchpointDto(
      this.selectedSlide.id,
      TouchpointType.LINK,
      this.template.touchpointStyles.find(tps => tps.default).id,
      slide.id,
    );
    this.store.dispatch(addLinkTouchpoint({ addTouchpointDto: dto }));
  }

  public removeLink(slide: Slide) {
    this.touchpoints.forEach((tp: Touchpoint) => {
      if (
        tp.nextSlideId === slide.id &&
        tp.parentSlideId === this.selectedSlide.id &&
        tp.type === TouchpointType.LINK
      ) {
        this.store.dispatch(removeLinkTouchpoint({ touchpointId: tp.id }));
      }
    });
  }

  public mouseOverSlide(slide: Slide) {
    const preview = document.querySelector(`#slide-tree-${slide.id}`);
    preview.classList.add('linkBorder-hover');
  }

  public mouseOutSlide(slide: Slide) {
    const preview = document.querySelector(`#slide-tree-${slide.id}`);
    preview.classList.remove('linkBorder-hover');
  }
}
