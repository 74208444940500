<mat-card class="story-panel">
  <mat-card-title class="story-title">
    <mat-card-title>Storyline editor</mat-card-title>
    <div class="back-button">
      <button (click)="editor()" mat-raised-button>Back to editor</button>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div>
      <div class="image-preview card-body" [innerHtml]="selectedSlide?.content | safeHTML"></div>
    </div>
  </mat-card-content>

  <div [ngSwitch]="linkMode">
    <st-slide-linker *ngSwitchCase="LinkMode.TP" [story]="story" [touchpoints]="touchpoints" [template]="template" [selectedSlide]="selectedSlide"></st-slide-linker>
    <st-root-slide-changer *ngSwitchCase="LinkMode.PREVIEW" [story]="story" [selectedSlide]="selectedSlide"></st-root-slide-changer>

  </div>
</mat-card>
