<mat-card id="slidePanel">
  <section id="device-choice" class="whitetable">
    <div class="story-slide" id="storySlide" [attr.data-slideId]="editorService.slide.id">
      <div class="story-id">{{ editorService?.slide.id }}</div>
      <st-web-touchpoints
        *ngIf="(editorMode$ | async) === editorModes.web"
        (selectTouchpoint)="editTouchpoint($event)"
        [touchpoints]="touchpoints"
        [show]="showTouchpoints"
      ></st-web-touchpoints>
      <st-whitetable-grid
        *ngIf="(editorMode$ | async) === editorModes.wt3 || (editorMode$ | async) === editorModes.wt2"
        (selectTouchpoint)="editTouchpoint($event)"
        [touchpoints]="touchpoints"
        [editorMode]="editorMode$ | async"
        [slide]="slide"
        [allTouchpoints]="allTouchpoints$ | async"
        [slides]="slides"
      ></st-whitetable-grid>

      <div
        class="svg-editor card-body slide{{ slide.id }}"
        (click)="svgClick($event)"
        appAppMutationObserver
        (innerHtmlRendered)="innerHtmlChanged()"
        [innerHtml]="editorService.slideDom.documentElement.outerHTML | safeHTML"
      ></div>
      <button
        (click)="toggleShowNotesField('notesField')"
        mat-icon-button
        class="add-notes"
        [disabled]="!editorService || !editorService.slide.notes"
      >
        <mat-icon class="icon" matTooltip="Add notes" matTooltipPosition="after">notes</mat-icon>
      </button>
      <button (click)="addUrlTouchpoint()" mat-icon-button class="add-notes">
        <mat-icon class="icon" matTooltip="Add link" matTooltipPosition="after">link</mat-icon>
      </button>
      <button *ngIf="showTouchpoints" (click)="showTouchpoints = false" mat-icon-button class="add-notes">
        <mat-icon class="icon" matTooltip="Hide Touchpoints" matTooltipPosition="after">visibility</mat-icon>
      </button>
      <button *ngIf="!showTouchpoints" (click)="showTouchpoints = true" mat-icon-button class="add-notes">
        <mat-icon class="icon" matTooltip="Show Touchpoints" matTooltipPosition="after">visibility_off</mat-icon>
      </button>

      <st-slide-notes
        *ngIf="editorService && editorService.slide.notes"
        [@slideInOut]="animationState"
        class="notesField"
        [notes]="notes$ | async"
      ></st-slide-notes>
    </div>
  </section>
</mat-card>
