<div class="touchpoint-container" #touchpointContainer>
  <div class="gridwrapper" cdkDropListGroup>
    <div
      cdkDropList
      cdkDropListSortingDisabled
      *ngFor="let gridItem of grid"
      [id]="'gridIndex' + gridItem.gridIndex"
      [cdkDropListData]="gridItem.touchpoints"
      [attr.data-grid-index]="gridItem.gridIndex"
      (cdkDropListDropped)="drop($event, gridItem)"
      (cdkDropListEntered)="dropBoxEntered($event, gridItem)"
      (cdkDropListExited)="dropBoxLeft($event, gridItem)"
      class="grid"
      [ngStyle]="gridStyles()"
      [cdkDropListConnectedTo]="getDropLists()"
    >
      <div
        *ngIf="gridItem.touchpoints.length > 0; else placeholder"
        cdkDrag
        id="{{ gridItem.touchpoints[0].id }}"
        class="touch-point touchpoint-id-{{ gridItem.touchpoints[0].id }}"
        (mouseenter)="mouseOverTouchpoint(gridItem.touchpoints[0])"
        (mouseleave)="mouseOutTouchpoint(gridItem.touchpoints[0])"
        [ngStyle]="touchPointStyles(gridItem.touchpoints[0])"
        (dblclick)="doubleClick(gridItem.touchpoints[0])"
        (click)="editTouchpoint(gridItem.touchpoints[0])"
        [ngClass]="{ 'tp-invisible': gridItem.touchpoints[0].isInvisible === true }"
        cdkDragBoundary=".story-slide"
        [innerHTML]="gridItem.touchpoints[0].style.content | safeHTML"
        [attr.data-width]="gridItem.touchpoints[0].width"
        [attr.data-height]="gridItem.touchpoints[0].height"
      ></div>
      <ng-template #placeholder><div class="no-touchpoint" style="width: 100%; height: 100%;"></div></ng-template>
    </div>
  </div>
</div>
