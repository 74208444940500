<div id="image-component">
  <div *ngIf="imageBase64 !== undefined">
    <image-cropper #cropper *ngIf="showCropper"
      [imageBase64]="imageBase64"
      [maintainAspectRatio]="false"
      [aspectRatio]="aspectRatio"
      [containWithinAspectRatio]="true"
      [autoCrop]="true"
      format="png"
      (imageCropped)="imageCropped($event)"
      (cropperReady)="cropperReady()"
    ></image-cropper>
  </div>
</div>
