import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { skip, take } from 'rxjs/operators';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { Slide } from '@shared/models/entities/slide';
import { Story } from '@shared/models/entities/story';
import { Template } from '@shared/models/entities/template';
import { Touchpoint } from '@shared/models/entities/touchpoint';
import { CurrentStoryService } from '@shared/services/local/current-story/current-story.service';
import { loadStory } from '@shared/store/actions/story.actions';
import { getSlide, getStory } from '@shared/store/selectors/story.selector';
import { getSelectedSlide } from '@shared/store/selectors/editor.selector';
import { setSelectedSlide } from '@shared/store/actions/editor.actions';
import { SlideType } from '@shared/models/entities/slideType';

@Component({
  selector: 'st-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnInit, OnDestroy {
  public story$: Observable<Story>;
  public touchpoints$: Observable<Touchpoint[]>;
  public selectedSlide$: Observable<Slide>;
  public template$: Observable<Template>;
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private currentStoryService: CurrentStoryService,
    private store: Store<AppState>,
  ) {}

  public async ngOnInit() {
    this.currentStoryService.currentStoryId = Number(this.route.snapshot.paramMap.get('id'));
    this.store.dispatch(loadStory({ storyId: this.currentStoryService.currentStoryId }));
    
    this.story$ = this.store.pipe(select(getStory), skip(1));
    const st = await this.story$.pipe(take(1)).toPromise();

    this.subscriptions.push(this.store.select(getSelectedSlide).subscribe(async (slideId) => {
      if(slideId !== undefined){
        const slideObservable = this.store.select(getSlide, slideId);
        const res = await slideObservable.pipe(take(1)).toPromise()
        if( res !== undefined){
          this.selectedSlide$ = slideObservable
        }
        else{
          const rootSlideId = st.slides.find(sl => sl.slideType === SlideType.ROOT).id;
          this.store.dispatch(setSelectedSlide({slideId: rootSlideId}))
        }
      }
      else{
        const rootSlideId = st.slides.find(sl => sl.slideType === SlideType.ROOT).id;
        this.store.dispatch(setSelectedSlide({slideId: rootSlideId}))
      }
      this.touchpoints$ = this.store.select(store => store.story.touchpoints);
    }));

    this.template$ = this.store.select(store => store.story.template);
  }

  public ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
