import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Slide } from '@shared/models/entities/slide';
import { Story } from '@shared/models/entities/story';
import { TouchpointType } from '@shared/models/entities/touchpointType';
import { TreeSlide } from '@shared/models/domain-models/tree-structure/treeSlide';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[slide][story][slideData]',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss'],
})
export class SlideComponent implements OnChanges {
  @Input() public slideData: Slide;
  @Input() public story: Story;
  @Input() public parentData?: Slide;
  @Output() public itemDrop: EventEmitter<any> = new EventEmitter();
  @Input() public preview: boolean;
  @Input() public selectedSlideId: number;
  public nextSlides: Slide[] = [];

  // TODO --> detect when touchpoints are added/removed
  // private iterableDiffer: IterableDiffer<Touchpoint>;

  // constructor(private iterableDiffers: IterableDiffers) {
  //   this.iterableDiffer = this.iterableDiffers.find([]).create((index, item) => item.id);
  // }

  public ngOnChanges() {
    // TODO --> remove --> gets executed way too many times
    this.nextSlides = this.slideData.touchpoints
      .filter(tp => tp.type !== TouchpointType.LINK && tp.type !== TouchpointType.URL)
      .map(tp => this.story.slides.find(sl => sl.id === tp.nextSlideId));
  }

  // TODO --> NextSlides can only be updated when a change in the list of touchpoints from this slide occurs
  // public ngDoCheck() {
  //   if (this.iterableDiffer) {
  //     let changes: IterableChanges<Touchpoint> = this.iterableDiffer.diff(this.slideData.touchpoints);
  //     if (changes) {
  //       changes.forEachAddedItem(tp => {
  //         console.log(tp.item.id);
  //         if (tp.item.type !== TouchpointType.LINK && tp.item.type !== TouchpointType.URL) {
  //           this.nextSlides.push(this.story.slides.find(sl => sl.id === tp.item.nextSlideId));
  //         }
  //       });

  //       changes.forEachRemovedItem(tp => {
  //         if (tp.item.type !== TouchpointType.LINK && tp.item.type !== TouchpointType.URL) {
  //           this.nextSlides = this.nextSlides.filter(sl => sl.id !== tp.item.nextSlideId);
  //         }
  //       });
  //     }
  //   }
  // }

  public onDragDrop(event: CdkDragDrop<TreeSlide, TreeSlide>): void {
    this.itemDrop.emit(event);
  }
}
