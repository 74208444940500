import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Template } from '@shared/models/entities/template';
import { TouchpointStyle } from '@shared/models/entities/touchpointStyle';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private readonly URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public findTemplateById(id: number): Observable<Template> {
    return this.http.get<Template>(`${this.URL}/template/findTemplateById/${id}`);
  }

  public findTouchpointStyleByTemplateId(id: number): Observable<TouchpointStyle[]> {
    return this.http.get<TouchpointStyle[]>(`${this.URL}/template/findTouchpointStyleByTemplateId/${id}`);
  }

  public findDefaultTouchpointStyleByStoryId(id: number): Observable<TouchpointStyle> {
    return this.http.get<TouchpointStyle>(`${this.URL}/template/findTouchpointStyleByTemplateId/${id}`);
  }
}
