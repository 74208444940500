import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateService } from '@shared/services/http/template-service/template.service';
import { SlideType } from '@shared/models/entities/slideType';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PreviewStory } from '@shared/models/domain-models/previewStory/previewStory';
import { PreviewSlide } from '@shared/models/domain-models/previewStory/previewSlide';

@Component({
  selector: 'st-slide-selector',
  templateUrl: './slide-selector.component.html',
  styleUrls: ['./slide-selector.component.scss'],
})
export class SlideSelectorComponent implements OnInit {
  public previewSlidesBasic: PreviewSlide[];
  public previewSlidesInteractive: PreviewSlide[];
  public selectedpreviewSlideBasic: PreviewSlide;
  public selectedpreviewSlideInteractive: PreviewSlide;
  public tabIndex = 0;

  constructor(
    public dialogRef: MatDialogRef<SlideSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public previewStory: PreviewStory,
    public templateService: TemplateService,
  ) {}

  public ngOnInit() {
    this.previewSlidesBasic = this.previewStory.previewSlides.filter(
      slide => slide.slideType !== SlideType.INTERACTIVE,
    );
    this.previewSlidesInteractive = this.previewStory.previewSlides.filter(
      slide => slide.slideType === SlideType.INTERACTIVE,
    );
    this.selectedpreviewSlideBasic = this.previewSlidesBasic[0];
    this.selectedpreviewSlideInteractive = this.previewSlidesInteractive[0];
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.tabIndex = tabChangeEvent.index;
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public SlideToSubmit() {
    if (this.tabIndex === 0) {
      this.dialogRef.close(this.selectedpreviewSlideBasic);
    } else if (this.tabIndex === 1) {
      this.dialogRef.close(this.selectedpreviewSlideInteractive);
    } else {
      this.dialogRef.close();
    }
  }

  public selectpreviewSlideBasic(previewSlide) {
    this.selectedpreviewSlideBasic = previewSlide;
  }

  public selectpreviewSlideInteractive(previewSlide) {
    this.selectedpreviewSlideInteractive = previewSlide;
  }
}
