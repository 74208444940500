<div class="stories">
  <div class="stories__heading">
    <h1>{{ title }}</h1>

    <mat-form-field class="search" appearance="fill">
      <mat-icon matSuffix>search</mat-icon>
      <input type="tel" [(ngModel)]="searchVal" matInput placeholder="Search" />
    </mat-form-field>

    <mat-form-field class="sortFilter" appearance="fill">
      <mat-label>Sort by</mat-label>
      <mat-select (selectionChange)="changeFilter($event)">
        <mat-option value="created">Date Created</mat-option>
        <mat-option value="name">Name</mat-option>
        <mat-option value="updated">Last time updated</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-content></ng-content>
</div>
