import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { BackgroundElement } from '@shared/models/domain-models/editor/parser/elements/backgroundElement';
import { BackgroundType } from '@shared/models/entities/backgroundType';
import { StImageElement } from '@shared/models/entities/stElements/stImageElement.entity';
import { StVideoElement } from '@shared/models/entities/stElements/stVideoElement.entity';
import { updateSlide } from '@shared/store/actions/story.actions';
import { EditorService } from '@pages/editor/services/editor.service';
import { Asset } from '@shared/models/entities/asset/asset';
import { AssetType } from '@shared/models/entities/asset/assetType';

@Component({
  selector: 'st-background-element',
  templateUrl: './background-element.component.html',
  styleUrls: ['./background-element.component.scss'],
})
export class BackgroundElementComponent implements OnInit, OnDestroy {
  @Input() public selectedElement: BackgroundElement;
  @Input() public assets: Asset[];
  @Input() public storyId: number;
  public backgroundType: BackgroundType;
  public backgroundColor: string;
  public assetType = AssetType;
  public showColorPicker = false;
  private subscriptions: Subscription[] = [];

  constructor(public editorService: EditorService, private store: Store<AppState>) {}

  public ngOnInit() {
    // Subscribe to store to receive added stElement and add it to the selectedSlot
    this.subscriptions.push(
      this.store
        .select(store => store.story.slides)
        .subscribe(slides => {
          const slide = slides.find(s => s.id === this.editorService.slide.id);
          let stElement;
          switch (slide.backgroundType) {
            case BackgroundType.IMAGE:
              stElement = slide.stElements.find(ste => ste.slotId === 'backgroundImage1') as StImageElement;
              this.selectedElement.stImageElement = stElement;
              break;
            case BackgroundType.VIDEO:
              stElement = slide.stElements.find(ste => ste.slotId === 'backgroundVideo1') as StVideoElement;
              this.selectedElement.stVideoElement = stElement;
              break;
          }
          this.selectedElement.stElement = stElement;
          this.backgroundColor = slide.backgroundColor;
          this.backgroundType = slide.backgroundType;
        }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  /**
   * This function changes the selected background type.
   *
   * @param select value will be image or video
   */
  public changeSelect(select: MatSelectChange) {
    switch (select.value) {
      case 'image':
        this.updateBackgroundType(BackgroundType.IMAGE);
        this.selectedElement.stElement = this.selectedElement.stImageElement;
        break;
      case 'video':
        this.updateBackgroundType(BackgroundType.VIDEO);
        this.selectedElement.stElement = this.selectedElement.stVideoElement;
        break;
      case 'default':
        this.updateBackgroundType(BackgroundType.DEFAULT);
        break;
      default:
        break;
    }
  }

  public changeBgColor(bgColor: string) {
    this.selectedElement.changeBgColor(bgColor);
    this.store.dispatch(
      updateSlide({ key: { id: this.editorService.slide.id }, filter: { backgroundColor: bgColor }, combine: false }),
    );
  }

  /**
   * This function dispatches the event to update the backgroundType of the slide and re-combines it to show correct background
   *
   * @param backgroundType
   */
  private updateBackgroundType(backgroundType: BackgroundType) {
    this.selectedElement.changeBackgroundType(backgroundType);
    this.store.dispatch(updateSlide({ key: { id: this.editorService.slide.id }, filter: { backgroundType } }));
  }
}
