import { createAction, props } from '@ngrx/store';
import { AddSlideDto } from '@shared/dto/addSlide.dto';
import { AddTouchpointDto } from '@shared/dto/addTouchpoint.dto';
import { SaveBackgroundStElementDto } from '@shared/dto/saveBackgroundStElement.dto';
import { SaveStoryAssetDto } from '@shared/dto/saveStoryAsset.dto';
import { UpdateDto } from '@shared/dto/update.dto';
import { PreviewStory } from '@shared/models/domain-models/previewStory/previewStory';
import { Asset } from '@shared/models/entities/asset/asset';
import { EditorMode } from '@shared/models/entities/editor-mode';
import { Notes } from '@shared/models/entities/notes';
import { Slide } from '@shared/models/entities/slide';
import { StElement } from '@shared/models/entities/stElements/stElement';
import { Story } from '@shared/models/entities/story';
import { Template } from '@shared/models/entities/template';
import { Touchpoint } from '@shared/models/entities/touchpoint';
import { TouchPointObject } from '@shared/models/entities/touchPointObject';
import { TouchPointTag } from '@shared/models/entities/touchpointTag';

// Load story
export const loadStory = createAction('[Story] loadStory', props<{ storyId: number }>());
export const loadStorySuccess = createAction(
  '[Story] loadStorySuccess',
  props<{
    story: Story;
    slides: Slide[];
    touchpoints: Touchpoint[];
    template: Template;
    assets: Asset[];
    notes: Notes[];
  }>(),
);
export const loadStoryFailure = createAction('[Story] loadStoryFailure', props<{ error: Error }>());

// Update touchpoint parent
export const updateTouchpointParent = createAction(
  '[Story] updateTouchpointParent',
  props<{ slideId: number; oldDarentSlideId: number; newParentId: number }>(),
);
export const updateTouchpointParentSucces = createAction(
  '[Story] updateTouchpointParentSucces',
  props<{ slideId: number; oldDarentSlideId: number; newParentId: number }>(),
);
export const updateTouchpointParentFailure = createAction(
  '[Story] updateTouchpointParentFailure',
  props<{ error: Error }>(),
);

// Update touchpoint
export const updateTouchpoint = createAction(
  '[Story] updateTouchpoint',
  props<{ key: Partial<Touchpoint>; filter: Partial<Touchpoint> }>(),
);
export const updateTouchpointSucces = createAction(
  '[Story] updateTouchpointSucces',
  props<{ touchpoint: Touchpoint }>(),
);
export const updateTouchpointFailure = createAction('[Story] updateTouchpointFailure', props<{ error: Error }>());

// Add slide
export const addSlide = createAction(
  '[Story] addSlide',
  props<{
    addSlideDto: AddSlideDto;
  }>(),
);
export const addSlideSucces = createAction(
  '[Story] addSlideSucces',
  props<{ slide: Slide; touchpoints: Touchpoint[] }>(),
);
export const addSlideFailure = createAction('[Story] addSlideFailure', props<{ error: Error }>());

// Remove slide
export const removeSlide = createAction(
  '[Story] removeSlide',
  props<{
    slideId: number;
    parentSlideId: number;
  }>(),
);
export const removeSlideSucces = createAction(
  '[Story] removeSlideSucces',
  props<{ removedSlideId: number; removedTouchpoints: number[]; changedTouchpoints: any[] }>(),
);
export const removeSlideFailure = createAction('[Story] removeSlideFailure', props<{ error: Error }>());

// Remove slide
export const updateSlide = createAction(
  '[Story] updateSlide',
  props<{
    key: Partial<Slide>;
    filter: Partial<Slide>;
    combine?: boolean;
  }>(),
);
export const updateSlideSucces = createAction(
  '[Story] updateSlideSucces',
  props<{ key: Partial<Slide>; filter: Partial<Slide> }>(),
);
export const updateSlideFailure = createAction('[Story] updateSlideFailure', props<{ error: Error }>());

// Add link
export const addLinkTouchpoint = createAction(
  '[Story] addLinkTouchpoint',
  props<{ addTouchpointDto: AddTouchpointDto }>(),
);

export const addLinkTouchpointSucces = createAction(
  '[Story] addLinkTouchpointSucces',
  props<{
    touchpoints: Touchpoint[];
  }>(),
);
export const addLinkTouchpointFailure = createAction('[Story] addLinkTouchpointFailure', props<{ error: Error }>());

// Remove link
export const removeLinkTouchpoint = createAction(
  '[Story] removeLinkTouchpoint',
  props<{
    touchpointId: number;
  }>(),
);

export const removeLinkTouchpointSucces = createAction(
  '[Story] removeLinkTouchpointSucces',
  props<{
    touchpointId: number;
  }>(),
);
export const removeLinkTouchpointFailure = createAction(
  '[Story] removeLinkTouchpointFailure',
  props<{ error: Error }>(),
);

// UpdateStElement
export const updateStElement = createAction(
  '[Story] updateStElement',
  props<{
    dto: UpdateDto<StElement>;
    combine?: boolean;
  }>(),
);

export const updateStElementSucces = createAction(
  '[Story] updateStElementSucces',
  props<{
    stElement: StElement;
  }>(),
);

export const updateStElementFailure = createAction(
  '[Story] updateStElementFailure',
  props<{
    error: Error;
  }>(),
);

// CreateStElement
export const saveBackgroundStElement = createAction(
  '[Story] saveBackgroundStElement',
  props<{
    dto: SaveBackgroundStElementDto;
  }>(),
);

export const saveBackgroundStElementSucces = createAction(
  '[Story] saveBackgroundStElementSucces',
  props<{
    slide: Slide;
  }>(),
);

// combineSlide
export const combineSlide = createAction(
  '[Story] combineSlide',
  props<{
    slideId: number;
  }>(),
);

export const combineSlideSucces = createAction(
  '[Story] combineSlideSucces',
  props<{
    slide: Slide;
  }>(),
);

export const combineSlideFailure = createAction(
  '[Story] combineSlideFailure',
  props<{
    error: Error;
  }>(),
);

// combineSlide
export const loadPreviewStory = createAction(
  '[Story] loadPreviewStory',
  props<{
    previewStoryid: number;
  }>(),
);

export const loadPreviewStorySucces = createAction(
  '[Story] loadPreviewStorySucces',
  props<{
    previewStory: PreviewStory;
  }>(),
);

export const loadPreviewStoryFailure = createAction(
  '[Story] loadPreviewStoryFailure',
  props<{
    error: Error;
  }>(),
);

export const updateStory = createAction(
  '[Story] updateStory',
  props<{ key: Partial<Story>; filter: Partial<Story> }>(),
);
export const updateStorySuccess = createAction('[Story] updateStorySuccess', props<{ filter: Partial<Story> }>());
export const updateStoryFailure = createAction('[Story] updateStoryFailure', props<{ error: Error }>());

export const updateStoryModel = createAction('[Story] updateStoryModel', props<{ filter: Partial<Story> }>());
export const updateStoryModelSuccess = createAction('[Story] updateStoryModelSuccess');
export const updateStoryModelFailure = createAction('[Story] updateStoryModelFailure', props<{ error: Error }>());

export const updateTouchpointObject = createAction(
  '[Story] updateTouchpointObject',
  props<{
    key: Partial<TouchPointObject>;
    filter: Partial<TouchPointObject>;
  }>(),
);

export const updateTouchpointObjectSuccess = createAction(
  '[Story] updateTouchpointObjectSuccess',
  props<{ key: Partial<TouchPointObject>; filter: Partial<TouchPointObject> }>(),
);
export const updateTouchpointObjectFailure = createAction(
  '[Story] updateTouchpointObjectFailure',
  props<{ error: Error }>(),
);

export const updateTouchpointTag = createAction(
  '[Story] updateTouchpointTag',
  props<{
    key: Partial<TouchPointTag>;
    filter: Partial<TouchPointTag>;
  }>(),
);

export const updateTouchpointTagSuccess = createAction(
  '[Story] updateTouchpointTagSuccess',
  props<{
    key: Partial<TouchPointTag>;
    filter: Partial<TouchPointTag>;
  }>(),
);
export const updateTouchpointTagFailure = createAction('[Story] updateTouchpointTagFailure', props<{ error: Error }>());

export const createTouchPointsForMode = createAction(
  '[Story] createTouchPointsForMode',
  props<{
    storyId: number;
    editorMode: EditorMode;
  }>(),
);
export const createTouchPointsForModeSuccess = createAction(
  '[Story] createTouchPointsForModeSuccess',
  props<{ touchpoints: Touchpoint[] }>(),
);

export const createTouchPointsForModeFailure = createAction(
  '[Story] createTouchPointsForModeFailure',
  props<{ error: Error }>(),
);

export const changeSlideTemplate = createAction(
  '[Story] changeSlideTemplate',
  props<{ storyId: number; slideId: number; previewSlideId: number }>(),
);
export const changeSlideTemplateFailure = createAction('[Story] changeSlideTemplateFailure', props<{ error: Error }>());

export const saveStoryAsset = createAction(
  '[Story] saveStoryAsset',
  props<{
    saveAssetDto: SaveStoryAssetDto;
  }>(),
);

export const saveStoryAssetSucces = createAction(
  '[Story] saveStoryAssetSucces',
  props<{
    asset: Asset;
  }>(),
);

export const saveStoryAssetFailure = createAction(
  '[Story] saveStoryAssetFailure',
  props<{
    error: Error;
  }>(),
);

export const removeStoryAsset = createAction(
  '[Story] removeStoryAsset',
  props<{
    storyId: number;
    assetId: number;
  }>(),
);

export const removeStoryAssetSucces = createAction(
  '[Story] removeStoryAssetSucces',
  props<{
    assetId: number;
  }>(),
);

export const removeStoryAssetFailure = createAction(
  '[Story] removeStoryAssetFailure',
  props<{
    error: Error;
  }>(),
);

// Update touchpoint
export const updateNotes = createAction(
  '[Story] updateNotes',
  props<{ key: Partial<Notes>; filter: Partial<Notes> }>(),
);
export const updateNotesSucces = createAction(
  '[Story] updateNotesSucces',
  props<{ key: Partial<Notes>; filter: Partial<Notes> }>(),
);
export const updateNotesFailure = createAction('[Story] updateNotesFailure', props<{ error: Error }>());
