import { ImageElement } from './elements/imageElement';
import { TitleElement } from './elements/titleElement';
import { ParagraphElement } from './elements/paragraphElement';
import { VideoElement } from './elements/videoElement';
import { Element } from './element';
import { BackgroundElement } from './elements/backgroundElement';
import { GraphElement } from './elements/graphElement';
import { TableElement } from './elements/tableElement';
import { StElement } from '@shared/models/entities/stElements/stElement';
import { StTextElement } from '@shared/models/entities/stElements/stTextElement.entity';
import { StImageElement } from '@shared/models/entities/stElements/stImageElement.entity';
import { StVideoElement } from '@shared/models/entities/stElements/stVideoElement.entity';
import { StGraphElement } from '@shared/models/entities/stElements/stGraphElement.entity';
import { StTableElement } from '@shared/models/entities/stElements/stTableElement.entity';
import { BackgroundType } from '@shared/models/entities/backgroundType';
export class ElementFactory {
  constructor(private slideDom: Document) {}

  public createEditableElement(event, stElements: StElement[], bgType: BackgroundType): Element {
    const elementId = this.getNearestId(event.target);
    if (elementId === undefined) {
      return undefined;
    } else {
      // Search for clicked element in svgDom variable
      const root = this.slideDom.getElementById(elementId);
      if (root === null || undefined) {
        return undefined;
      }
      if (root.id.includes('background')) {
        const imageBg = stElements.find(ste => ste.slotId === 'backgroundImage1');
        const videoBg = stElements.find(ste => ste.slotId === 'backgroundVideo1');
        if (bgType === BackgroundType.VIDEO) {
          return new BackgroundElement(root, videoBg, videoBg as StVideoElement, imageBg as StImageElement);
        } else if (bgType === BackgroundType.IMAGE) {
          return new BackgroundElement(root, imageBg, videoBg as StVideoElement, imageBg as StImageElement);
        } else {
          return new BackgroundElement(root, undefined, videoBg as StVideoElement, imageBg as StImageElement);
        }
      } else {
        const stElement = stElements.find(ste => ste.slotId === elementId);
        return this.getElement(root, stElement);
      }
    }
  }

  private getElement(root: HTMLElement, stElement: StElement) {
    let element: Element;
    if (root.id.includes('paragraph') || root.id.includes('answer')) {
      element = new ParagraphElement(root, stElement as StTextElement);
    } else if (root.id.includes('title') || root.id.includes('question')) {
      element = new TitleElement(root, stElement as any);
    } else if (root.id.includes('image')) {
      element = new ImageElement(root, stElement as StImageElement);
    } else if (root.id.includes('video')) {
      element = new VideoElement(root, stElement as StVideoElement);
    } else if (root.id.includes('chart')) {
      element = new GraphElement(root, stElement as StGraphElement);
    } else if (root.id.includes('table')) {
      element = new TableElement(root, stElement as StTableElement);
    } else {
      return null;
    }
    return element;
  }

  private getNearestId(element: HTMLElement): string {
    if (element.id && element.tagName === 'g') {
      if (
        ['paragraph', 'title', 'image', 'video', 'chart', 'table', 'background'].some(elId => element.id.includes(elId))
      ) {
        return element.id;
      } else {
        return this.getNearestId(element.parentElement);
      }
    } else {
      return this.getNearestId(element.parentElement);
    }
  }
}
