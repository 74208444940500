import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { PublishComponent } from '@shared/components/publish/publish.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { Story } from '@shared/models/entities/story';
import {
  duplicateStory,
  duplicateStorySuccess,
  removeStory,
  updateStories,
} from '@shared/store/actions/stories.actions';
import { CardAnimations } from '@pages/dashboard/animations/card.animation';
import { ShareMenuComponent } from '@shared/components/share-menu/share-menu.component';
import { ToastrService } from 'ngx-toastr';
import { Actions, ofType } from '@ngrx/effects';
import { AuthenticationService } from '@shared/services/local/authentication-service/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'st-story-card',
  templateUrl: './story-card.component.html',
  styleUrls: ['./story-card.component.scss'],
  animations: [CardAnimations.cardExitAnimation],
})
export class StoryCardComponent implements OnInit, OnDestroy {
  @Input() public story: Story;
  @Input() public navTab: string;
  public storyName: string;
  public editTitle = false;
  public isDisabled = true;
  public leave = false;
  private dialogRef: MatDialogRef<ShareMenuComponent>;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private action$: Actions,
    private authService: AuthenticationService,
  ) {}

  public ngOnInit() {
    this.storyName = this.story.name;
    this.subscriptions.push(
      this.action$.pipe(ofType(duplicateStorySuccess)).subscribe(() => {
        if (this.navTab === 'Shared with me') {
          this.toastr.success('You can find the story in the "My stories" tab', 'Success!');
        }
      }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  public play(storyId: number) {
    this.router.navigate([`story/${storyId}`]);
  }

  public edit(storyId: number) {
    this.router.navigate([`editor/${storyId}`], { replaceUrl: true });
  }

  /**
   * Creates a temporary Story and adds it to the stories list
   * when done duplicating the temp story gets replaced by the duplication.
   *
   * @param storyId id of story to duplicate
   * @param story story used to make the temp duplicate
   * @see {@link https://lucid.app/invitations/accept/5cc936bf-29ec-4f75-a383-fa89bf5001fb}
   */
  public duplicate(storyId: number, story: Story) {
    this.store.dispatch(duplicateStory({ storyId, story }));
  }

  public remove(storyId: number) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '340px',
      data: 'Do you want to delete this story?',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(removeStory({ storyId }));
        this.leave = true;
      }
    });
  }

  public publish(storyId: number): void {
    const rootSlide = this.story.slides[0].id;
    this.dialog.open(PublishComponent, {
      width: '600px',
      data: {
        storyId,
        rootSlide,
      },
    });
  }

  public share(storyId: number): void {
    const storyName = this.story.name;
    this.dialogRef = this.dialog.open(ShareMenuComponent, {
      width: '500px',
      height: '500px',
      data: { storyId, storyName },
    });
  }

  public changeTitle(storyId: number) {
    this.store.dispatch(updateStories({ key: { id: storyId }, filter: { name: this.storyName } }));
  }
}
