import { StTableElement } from '@shared/models/entities/stElements/stTableElement.entity';
import { Element } from '../element';
import { ElementType } from './elementType';

export class TableElement implements Element {
  public elementType: ElementType;

  constructor(private rootNode: HTMLElement, public stTableElement: StTableElement) {
    this.elementType = ElementType.TABLE_ELEMENT;
  }

  public getElement(): HTMLElement {
    return this.rootNode;
  }

  public editElement() {}
}
