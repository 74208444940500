import { StVideoOptions } from '@shared/models/domain-models/video/stVideoOptions';
import { Component, Input, EventEmitter, OnChanges } from '@angular/core';
import { VideoElement } from '@shared/models/domain-models/editor/parser/elements/videoElement';
import { UploadStatusComponent } from '@shared/components/upload-status/upload-status.component';
import { MatDialog } from '@angular/material/dialog';
import { VimeoService } from '@shared/services/http/vimeo.service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { Store } from '@ngrx/store';
import { UpdateDto } from '@shared/dto/update.dto';
import { StVideoElement } from '@shared/models/entities/stElements/stVideoElement.entity';
import { StElementType } from '@shared/models/entities/stElements/stElementType';
import { saveStoryAsset, updateStElement } from '@shared/store/actions/story.actions';
import { EditorService } from '@pages/editor/services/editor.service';
import { AssetType } from '@shared/models/entities/asset/assetType';
import { VideoAsset } from '@shared/models/entities/asset/videoAsset';
import { SaveVideoAssetDto } from '@shared/dto/saveVideoAsset.dto';
import { Mime, supportedVideoMimes } from '@shared/models/entities/asset/mimeType';
import { ToastrService } from 'ngx-toastr';
import { BackgroundElement } from '@shared/models/domain-models/editor/parser/elements/backgroundElement';
import { ElementType } from '@shared/models/domain-models/editor/parser/elements/elementType';

@Component({
  selector: 'st-video-element',
  templateUrl: './video-element.component.html',
  styleUrls: ['./video-element.component.scss'],
})
export class VideoElementComponent implements OnChanges {
  @Input() public selectedElement: VideoElement | BackgroundElement;
  @Input() public assets: VideoAsset[];
  public activeAsset: VideoAsset;
  public videoOptions: StVideoOptions;
  public ElementType = ElementType;

  // Video properties
  public ST_VIDEOELEMENT_TYPE = 2;
  public ST_BACKROUND_VIDEOELEMENT_TYPE = 3;

  constructor(
    private editorService: EditorService,
    private vimeoService: VimeoService,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private toastrService: ToastrService,
  ) {}

  public ngOnChanges() {
    if (this.selectedElement.stElement.videoOptions) {
      this.videoOptions = { ...JSON.parse(this.selectedElement.stElement.videoOptions) };
    } else {
      this.videoOptions = { autoplay: false, controls: false, loop: false, mute: false };
    }

    if (this.selectedElement) {
      this.activeAsset = this.assets.find(asset => asset.id === this.selectedElement.stElement.assetId);
    }
  }

  /**
   * Show upload status dialog and upload all given video files to the backend.
   *
   * @param $event - The video files.
   */
  public async uploadVideos($event) {
    // Check if input is not empty.
    if ($event.target.files.length !== 0) {
      // Get videos from event.
      const fileList: FileList = $event.target.files;
      // Get current storyId.
      const storyId = this.editorService.slide.storyId;

      const videosUploadedEmitter = new EventEmitter();
      const videosTranscodedEmitter = new EventEmitter();
      const runWhenDone = new EventEmitter();

      // After all uploads are done, trigger a dialogue close
      runWhenDone.subscribe(() => {
        dialogRef.close();
      });

      // Show loader dialog.
      const dialogRef = this.dialog.open(UploadStatusComponent, {
        disableClose: true,
        width: '25%',
        data: {
          indeterminateBarName: 'Uploading video',
          indeterminateCountEmitter: videosUploadedEmitter,
          indeterminateTotalCount: fileList.length,
          bufferBarName: 'Transcoding video',
          bufferCountEmitter: videosTranscodedEmitter,
          bufferTotalCount: fileList.length,
          runWhenDone,
        },
      });

      // loop through all files
      for (const video of Array.from(fileList)) {
        const mimeType: Mime = video.type as Mime;
        if (!supportedVideoMimes.includes(mimeType)) {
          this.toastrService.error(`The type of video ${video.name} is not supported! Skipping upload..`);
          continue;
        }

        try {
          // Wait until file is uploaded.
          const vimeoVideo: any = await this.vimeoService
            .uploadVideoAndReturnVimeoIdAndTusLink(storyId, video)
            .toPromise();
          // Let dialog know that a file is uploaded
          videosUploadedEmitter.emit(1);
          // Wait until video is transcoded.
          await this.vimeoService.awaitVideoTranscodedByVimeoId(vimeoVideo.vimeoId);
          // Let dialog know that a file is done transcoding on Vimeo.
          videosTranscodedEmitter.emit(1);
          const dto = new SaveVideoAssetDto(vimeoVideo.vimeoId, mimeType, AssetType.VIDEO, video.name, storyId);
          this.store.dispatch(saveStoryAsset({ saveAssetDto: dto }));
        } catch (err) {
          this.toastrService.error(`Failed to upload video ${video.name}`);
          continue;
        }
      }
    }
  }

  public setVideo(event) {
    const asset: VideoAsset = event.value;
    this.selectedElement.changeVideo(asset.videoUrl);
    const dto = new UpdateDto<StVideoElement>(
      { slideId: this.selectedElement.stElement.slideId, slotId: this.selectedElement.stElement.slotId },
      { stElementType: StElementType.STVIDEOELEMENT, assetId: asset.id },
    );
    this.store.dispatch(updateStElement({ dto, combine: false }));
  }

  public loopVideo() {
    this.videoOptions.loop = !this.videoOptions.loop;
    this.saveVideoOptions();
  }
  public autoplayVideo() {
    this.videoOptions.autoplay = !this.videoOptions.autoplay;
    this.saveVideoOptions();
  }
  public muteVideo() {
    this.videoOptions.mute = !this.videoOptions.mute;
    this.saveVideoOptions();
  }

  public setVideoControls() {
    this.videoOptions.controls = !this.videoOptions.controls;
    this.saveVideoOptions();
  }

  private saveVideoOptions() {
    const dto = new UpdateDto<StVideoElement>(
      { slideId: this.selectedElement.stElement.slideId, slotId: this.selectedElement.stElement.slotId },
      {
        stElementType: StElementType.STVIDEOELEMENT,
        videoOptions: JSON.stringify(this.videoOptions),
      },
    );
    this.store.dispatch(updateStElement({ dto, combine: false }));
  }
}
