<div class="slide-container">
  <!-- zoom buttons -->
  <div class="slide-buttons" *ngIf="zoomButtons">
    <button mat-icon-button (click)="checkZoomChange(false, 3)">
      <i class="material-icons zoom-btn">zoom_out</i>
    </button>
    <mat-slider
      color="primary"
      min="{{ minZoom }}"
      max="{{ maxZoom }}"
      step="{{ incrementZoom }}"
      value="{{ zoomValue }}"
      (input)="checkZoomChange($event.value > this.zoomValue ? true : false)"
    ></mat-slider>
    <button mat-icon-button (click)="checkZoomChange(true, 3)">
      <i class="material-icons zoom-btn">zoom_in</i>
    </button>
  </div>

  <div
    stMouseWheel
    id="stScrollZoom"
    class="slide-panel"
    [ngStyle]="{
      transition: cdkDragTransition
    }"
  >
    <div class="drag" 
    [ngStyle]="{
      left: -(cdkDragWindowULLeft / 2) + 'px',
      top: -(cdkDragWindowULTop / 2) + 'px',
      width: (cdkDragWindowULWidth * 2) + 'px',
      height: (cdkDragWindowULHeight * 2) + 'px'
    }"
    >
      <div
        id="cdkDragWindow"
        cdkDragBoundary=".drag"
        cdkDrag
        (cdkDragEnded)="onDragEnded()"
        [cdkDragFreeDragPosition]="{ x: cdkDragLeftPosition, y: cdkDragTopPosition }"
        class="tf-tree tf-custom"
        [ngStyle]="{
          'font-size': zoomValue + 'px',
          transition: cdkDragTransition
        }"
      >
        <ul cdkDropListGroup>
          <li
            *ngIf="rootSlide"
            slide
            class="node"
            id="node-root"
            [story]="story"
            [slideData]="rootSlide"
            (itemDrop)="onDragDrop($event)"
            [preview]="preview"
            [selectedSlideId]="selectedSlide$ | async"
          >
        </li>
        </ul>
      </div>
    </div>
  </div>
</div>
