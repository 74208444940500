import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Story } from '@shared/models/entities/story';

@Pipe({
  name: 'storiesSort',
})
export class StoriesSortPipe implements PipeTransform {
  public transform(stories$: Observable<Story[]>, sortType): Observable<Story[]> {
    return stories$.pipe(
      map(stories => {
        try {
          if (sortType === 'updated') {
            return stories.sort((b, a) => (a[sortType] > b[sortType] ? 1 : a[sortType] === b[sortType] ? 0 : -1));
          } else if (sortType === 'name') {
            return stories.sort((a, b) => a.name.localeCompare(b.name));
          } else {
            return stories.sort((b, a) => (a[sortType] > b[sortType] ? 1 : a[sortType] === b[sortType] ? 0 : -1));
          }
        } catch (e) {}
      }),
    );
  }
}
