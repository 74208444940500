import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private readonly API_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  // public publish(device: boolean, storyID: number) {
  //   this.http.post<any>(this.API_URL + '/analytics/publish', { toDevice: device, storyID }).subscribe(
  //     () => {},
  //     () => {
  //       console.log('caught');
  //     },
  //   );
  // }
  // public openWindow() {
  //   this.http.get<any>(environment.API_URL + '/analytics/open_window').subscribe(
  //     () => {},
  //     () => {
  //       console.log('caught');
  //     },
  //   );
  // }
  // public closeWindow() {
  //   this.http.get<any>(environment.API_URL + '/analytics/close_window').subscribe(
  //     () => {},
  //     () => {
  //       console.log('caught');
  //     },
  //   );
  // }
  // public userPage(url: string) {
  //   this.http.post<any>(environment.API_URL + '/analytics/user_page', { url }).subscribe(
  //     () => {},
  //     () => {
  //       console.log('caught');
  //     },
  //   );
  // }
  // public activityUser(visibility: string) {
  //   this.http.post<any>(environment.API_URL + '/analytics/user_activity', { status: visibility }).subscribe(
  //     () => {},
  //     () => {
  //       console.log('caught');
  //     },
  //   );
  // }
}
