import { createAction, props } from '@ngrx/store';
import { Element } from '@shared/models/domain-models/editor/parser/element';
import { EditorMode } from '@shared/models/entities/editor-mode';

// Select slide
export const setSelectedSlide = createAction(
  '[Editor] setSelectedSlide',
  props<{
    slideId: number;
  }>(),
);

// Select slide
export const setSelectedElement = createAction(
  '[Editor] setSelectedElement',
  props<{
    element: Element;
  }>(),
);

// Select Touchpoint
export const setSelectedTouchpoint = createAction(
  '[Editor] setSelectedTouchpoint',
  props<{
    touchpointId: number;
  }>(),
);
// Select slide
export const setEditorMode = createAction(
  '[Editor] setEditorMode',
  props<{
    editorMode: EditorMode;
  }>(),
);
