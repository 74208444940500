import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { RichTextEditorComponent } from '../../rich-text-editor.component';

@Component({
  selector: 'st-text-link-popup',
  templateUrl: './text-link-popup.component.html',
  styleUrls: ['./text-link-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextLinkPopupComponent implements OnInit {
  public urlType: 'web' | 'mail' = 'web';
  public urlControl: FormControl;
  constructor(public dialogRef: MatDialogRef<RichTextEditorComponent>, public dialog: MatDialog) {}

  public ngOnInit(): void {
    this.urlControl = new FormControl('', [Validators.required, UrlValidator(this.urlType)]);
  }

  public changeLinkType(event: MatRadioChange) {
    this.urlType = event.value;
    this.urlControl.clearValidators();
    this.urlControl.setValidators([Validators.required, UrlValidator(this.urlType)]);
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public save() {
    if (this.urlType === 'web') {
      this.dialogRef.close(`https://${this.urlControl.value}`);
    } else {
      this.dialogRef.close(`mailto:${this.urlControl.value}`);
    }
  }

  public getErrorMessage() {
    if (this.urlControl.hasError('required')) {
      return 'You must enter a value';
    }
    return this.urlControl.hasError('pattern') ? this.urlControl.errors['pattern'] : '';
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function UrlValidator(urlType): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const urlRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)$/;
    // eslint-disable-next-line max-len
    const emailRegex = /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    const errorMap = {};
    if (control.value) {
      const validEmail = emailRegex.test(control.value);
      if (urlType === 'web') {
        if (!urlRegex.test(`https://${control.value}`)) {
          errorMap['pattern'] = 'Not a valid url';
        }
      } else if (urlType === 'mail' && !validEmail) {
        errorMap['pattern'] = 'Not a valid mail';
      }
    }

    return Object.keys(errorMap).length !== 0 ? errorMap : null;
  };
}
