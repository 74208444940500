import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AlertComponent } from '@shared/components/alert/alert.component';

export interface TableElementData {
  tableData: string[][];
}

@Component({
  selector: 'st-table-data-editor',
  templateUrl: './table-data-editor.component.html',
  styleUrls: ['./table-data-editor.component.scss'],
})
export class TableDataEditorComponent implements OnInit {
  public tableData: object[];
  public headerRow: string[]; // For updating and database
  public columns: string[]; // For displaying
  private timeout: ReturnType<typeof setTimeout>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TableElementData,
    public dialogRef: MatDialogRef<TableDataEditorComponent>,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) {}

  public ngOnInit() {
    this.headerRow = this.data.tableData.shift();
    this.columns = [...this.headerRow].concat('Actions');
    this.tableData = this.convertDataToTableStructure(this.data.tableData, this.headerRow);
  }

  public saveChanges() {
    this.tableData = this.convertTableToOriginalStructure(this.tableData, this.headerRow);
    this.dialogRef.close({ data: this.tableData });
  }

  public changeTableValue(objectIdx: number, keyLabel: string, value: string) {
    this.tableData[objectIdx][keyLabel] = value;
  }

  public changeColumnValue(columnIdx: number, currentName: string, value: string) {
    if (this.headerRow.indexOf(value) > -1) {
      this.toastr.warning(`Column "${value}" already exists.`);
    } else {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const oldColumnName = currentName;
        const newColumnName = value;
        this.headerRow[columnIdx] = value;

        // Check every object and create new kay value pair with the renamed column and delete old columns
        this.tableData.forEach(item => {
          item[newColumnName] = item[oldColumnName];
          delete item[oldColumnName];
        });

        // Update columns to show new legend names
        this.columns = [...this.headerRow].concat('Actions');
      }, 500);
    }
  }

  public addColumn() {
    // Add new column with a default name
    const newLegend = [...this.headerRow, `New Column ${this.headerRow.length + 1}`];
    this.headerRow = [...newLegend];

    // Create a new key value pair for each row
    this.tableData.forEach(item => {
      item[this.headerRow[this.headerRow.length - 1]] = 0;
    });

    // Replace current columns with new columns
    this.columns = [...this.headerRow].concat('Actions');
  }

  public removeColumn(columnIndex: number, column: string) {
    if (this.headerRow.length !== 1) {
      const dialogDeleteColumn = this.dialog.open(AlertComponent, {
        width: '370px',
        data: `Do you want to delete this column "${column}" ?`,
      });

      dialogDeleteColumn.afterClosed().subscribe(confirmedToDelete => {
        if (confirmedToDelete) {
          // Remove legend from the array and reset edit field
          this.headerRow = this.headerRow.filter((l, idx) => idx !== columnIndex);
          this.columns = [...this.headerRow].concat('Actions');

          const toBeDeletedColumn = column;
          // Remove each value of the deleted column in every object
          this.tableData.forEach(item => {
            delete item[toBeDeletedColumn];
          });
        }
      });
    } else {
      this.toastr.warning('Table needs at least one column.');
    }
  }

  public rowActions(type: string, deletedRow?: object) {
    if (type === 'add') {
      this.tableData = [...this.tableData, {}];
    } else {
      const dialogDelete = this.dialog.open(AlertComponent, {
        width: '340px',
        data: 'Do you want to delete this row?',
      });

      dialogDelete.afterClosed().subscribe(confirmedToDelete => {
        if (confirmedToDelete) {
          this.tableData = this.tableData.filter(i => i !== deletedRow);
        }
      });
    }
  }

  /**
   * Convert data from the database to the correct structure for Angular Material Table
   *
   * @param data 2D-array with only strings
   * @param headerRow The first row of the data is used as the header
   * @returns Array with objects of the data
   */
  public convertDataToTableStructure(data: string[][], headerRow: string[]): object[] {
    // If table has a single column rename the value key to the column name
    if (headerRow.length === 1) {
      data.forEach((obj, idx) => {
        obj[headerRow[0]] = data[idx][0];
      });

      return data;
    }

    // If table has multiple data series convert array values to key values
    const dataCollection: object[] = [];

    for (let i = 0; i < Object.keys(data).length; i++) {
      const dataObject = {};

      for (let j = 0; j < headerRow.length; j++) {
        dataObject[headerRow[j]] = data[i][j];
      }
      dataCollection.push(dataObject);
    }

    return dataCollection;
  }

  /**
   * Convert table structure back to original structure like in the database
   *
   * @param data Objects of the data
   * @param headerRow The first row of the data is used as the header
   * @returns 2D-array, the original structure
   */
  public convertTableToOriginalStructure(data: any, headerRow: string[]): string[][] {
    if (headerRow.length === 1) {
      const dataCollection = [];

      data.forEach(item => {
        dataCollection.push([item[headerRow[0]]]);
      });

      // Add the header row back to the data on the first position
      dataCollection.unshift(this.headerRow);

      return dataCollection;
    } else {
      const dataCollection = [];

      // If table has multiple columns convert key values back to array with values
      for (let i = 0; i < Object.keys(data).length; i++) {
        const arrayWithSerieValues = [];

        // Get all values from the data series and put it in one array
        for (let j = 0; j < headerRow.length; j++) {
          arrayWithSerieValues[j] = data[i][headerRow[j]];
        }

        dataCollection.push(arrayWithSerieValues);
      }

      // Add the header row back to the data on the first position
      dataCollection.unshift(this.headerRow);

      return dataCollection;
    }
  }
}
