import { Component, Input, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Slide } from '@shared/models/entities/slide';
import { Story } from '@shared/models/entities/story';

@Component({
  selector: 'st-story-panel',
  templateUrl: './story-panel.component.html',
  styleUrls: ['./story-panel.component.scss'],
})
export class StoryPanelComponent {
  @Output() public selectedSlide = new EventEmitter<Slide>();
  @Input() public story: Story;

  constructor(private router: Router) {}

  public treeEditor(id) {
    this.router.navigate([`editor/${id}/tree`], { replaceUrl: true });
  }
}
