import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/models/domain-models/appState';
import { AuthenticationService } from '@shared/services/local/authentication-service/authentication.service';
import { setLoggedInUser } from '@shared/store/actions/loggedInUser.actions';

import { User } from '@storytemple/models';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoggedInGuard implements CanActivate {
  private readonly URL = environment.loginServiceUrl;

  constructor(private authenticationService: AuthenticationService, private store: Store<AppState>) {}

  public canActivate(): Promise<boolean> {
    return new Promise(resolve => {
      this.authenticationService
        .loggedIn()
        .then((user: User) => {
          this.store.dispatch(setLoggedInUser({ user }));
          resolve(true);
        })
        .catch(() => {
          this.authenticationService.logout();
          window.location.href = `${this.URL}/login`;
          resolve(false);
        });
    });
  }
}
