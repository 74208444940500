import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/models/domain-models/appState';
import { PreviewSlide } from '@shared/models/domain-models/previewStory/previewSlide';
import { Slide } from '@shared/models/entities/slide';
import { Story } from '@shared/models/entities/story';
import { changeSlideTemplate } from '@shared/store/actions/story.actions';
import { take } from 'rxjs/operators';

@Component({
  selector: 'st-root-slide-changer',
  templateUrl: './root-slide-changer.component.html',
  styleUrls: ['./root-slide-changer.component.scss']
})
export class RootSlideChangerComponent implements OnInit {
  @Input() public story: Story;
  @Input() public selectedSlide: Slide;
  public previewSlides: PreviewSlide[];
  
  constructor(private store: Store<AppState>) { }

  public async ngOnInit() {
    this.previewSlides = (await this.store.select(store => store.story.previewStory).pipe(take(1)).toPromise()).previewSlides;
  }

  public changeRootSlide(previewSlideId: number){
    const currentSlideId = this.selectedSlide.id;
    this.store.dispatch(changeSlideTemplate({storyId: this.story.id, slideId: currentSlideId, previewSlideId}));
  }



}
