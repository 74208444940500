import { trigger, state, style, transition, animate } from '@angular/animations';

export const SlideInOutAnimation = [
  trigger('slideInOut', [
    state('small', style({ 'max-height': '0px', display: 'none' })),
    state('large', style({ 'max-height': '100px', display: 'visible' })),

    transition('small => large', animate('400ms ease-in')),
  ]),
];
