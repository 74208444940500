import { TouchpointType } from '../models/entities/touchpointType';
import { AddTouchpointDto } from './addTouchpoint.dto';

export class AddUrlTouchpointDto extends AddTouchpointDto {
  constructor(
    public parentSlideId: number,
    public url: string,
    public touchpointStyleId: number,
  ) {
    super(parentSlideId, TouchpointType.URL, touchpointStyleId)
  }
}
