<st-header class="st-grid-col-fullwidth" title="DashboardPage">
  <ul class="nav-main">
    <li class="nav-main__item" [class]="navTab === 'My stories' ? 'selected' : ''">
      <a href="javascript:;" (click)="changeDashboard('My stories')">
        My stories
      </a>
    </li>
    <li class="nav-main__item" [class]="navTab === 'Shared with me' ? 'selected' : ''">
      <a href="javascript:;" (click)="changeDashboard('Shared with me')">
        Shared with me
      </a>
    </li>
  </ul>
</st-header>

<!-- MY STORIES -->
<st-stories [title]="navTab" *ngIf="navTab === 'My stories'">
  <div class="stories-container">
    <st-loading [text]="'Loading stories'" *ngIf="loadingStories$ | async; else storiesList"></st-loading>
  </div>

  <ng-template #storiesList>
    <div class="stories-container" [@cardAnimation]="loadingStories$ | async">
      <div class="mdc-custom-add-tile add-tile" (click)="openAddStoryDialog()" appDragDrop>
        <span>+</span>
      </div>

      <ng-container *ngFor="let story of stories$ | storiesSort: filterVal | searchFilter: searchVal | async">
        <st-story-card
          *ngIf="story.tempId === undefined; else storyDuplicating"
          [navTab]="navTab"
          [story]="story"
        ></st-story-card>
        <ng-template #storyDuplicating>
          <mat-card class="loading-card">
            <mat-card-content>
              <st-loading [text]="'Duplicating story'"></st-loading>
            </mat-card-content>
          </mat-card>
        </ng-template>
      </ng-container>
    </div>
  </ng-template>
</st-stories>

<!-- Shared with me -->
<st-stories [title]="navTab" *ngIf="navTab === 'Shared with me'">
  <div class="stories-container">
    <st-loading [text]="'Loading stories'" *ngIf="loadingStories$ | async; else storiesList"></st-loading>
  </div>

  <ng-container *ngIf="sharedStoriesLists$ | async as sharedStoriesLists">
    <!-- ORGANISATION STORIES -->
    <div
      class="stories-container"
      [@cardAnimation]="loadingStories$ | async"
      *ngIf="sharedStoriesLists['ORGANISATION'].length > 0"
    >
      <h2>Shared with my organisation</h2>
      <ng-container *ngFor="let story of sharedStoriesLists['ORGANISATION']">
        <st-story-card
          *ngIf="story.tempId === undefined; else storyDuplicating"
          [navTab]="navTab"
          [story]="story"
        ></st-story-card>
        <ng-template #storyDuplicating>
          <mat-card class="loading-card">
            <mat-card-content>
              <st-loading [text]="'Duplicating story'"></st-loading>
            </mat-card-content>
          </mat-card>
        </ng-template>
      </ng-container>
    </div>

    <!-- GROUP STORIES -->
    <div
      class="stories-container"
      [@cardAnimation]="loadingStories$ | async"
      *ngIf="sharedStoriesLists['GROUP'].length > 0"
    >
      <h2>Shared with my goups</h2>
      <ng-container *ngFor="let story of sharedStoriesLists['GROUP']">
        <ng-container *ngIf="story.storyType !== 'preview'">
          <st-story-card
            *ngIf="story.tempId === undefined; else storyDuplicating"
            [navTab]="navTab"
            [story]="story"
          ></st-story-card>
          <ng-template #storyDuplicating>
            <mat-card class="loading-card">
              <mat-card-content>
                <st-loading [text]="'Duplicating story'"></st-loading>
              </mat-card-content>
            </mat-card>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>

    <!-- Shared with user -->
    <div
      class="stories-container"
      [@cardAnimation]="loadingStories$ | async"
      *ngIf="sharedStoriesLists['DIRECT'].length > 0"
    >
      <h2>Shared with me</h2>
      <ng-container *ngFor="let story of sharedStoriesLists['DIRECT']">
        <ng-container *ngIf="story.storyType !== 'preview'">
          <st-story-card
            *ngIf="story.tempId === undefined; else storyDuplicating"
            [navTab]="navTab"
            [story]="story"
          ></st-story-card>
          <ng-template #storyDuplicating>
            <mat-card class="loading-card">
              <mat-card-content>
                <st-loading [text]="'Duplicating story'"></st-loading>
              </mat-card-content>
            </mat-card>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</st-stories>
