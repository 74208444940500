import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import 'leader-line';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '../../models/domain-models/appState';
import { TreeSlide } from '../../models/domain-models/tree-structure/treeSlide';
import { Slide } from '../../models/entities/slide';
import { SlideType } from '../../models/entities/slideType';
import { Story } from '../../models/entities/story';
import { loadPreviewStory, updateTouchpointParent } from '../../store/actions/story.actions';
import { Template } from '../../models/entities/template';
import { getSelectedSlide } from '../../store/selectors/editor.selector';

@Component({
  selector: 'st-tree-overview',
  templateUrl: './tree-overview.component.html',
  styleUrls: ['./tree-overview.component.scss'],
})
export class TreeOverviewComponent implements OnDestroy, OnInit, AfterViewInit, OnChanges{
  @Input() public story: Story;
  @Input() public template: Template;
  @Input() public preview = false;
  @Input() public zoomValue = 16;
  @Input() public slideMenu = true;
  @Input() public zoomButtons = true;
  @Input() public zoomAction = true;
  @Input() public disableDrag = false;
  @Input() public dblClickActive = true;

  public cdkDragWindowULWidth = 0;
  public cdkDragWindowULHeight = 0;
  public cdkDragWindowULLeft = 0;
  public cdkDragWindowULTop = 0;
  public cdkDragLeftPosition = 0;
  public cdkDragTopPosition = 0;
  public cdkDragTransition = '';
  public maxZoom = 24;
  public minZoom = 4;
  public incrementZoom = 1;
  public checkZoomWidth;
  
  public currentSlide: Slide;
  public selectedSlide$: Observable<number>;
  public rootSlide: Slide;
  private subscriptions: Subscription[] = [];

  constructor(private toaster: ToastrService, private store: Store<AppState>, private cdRef: ChangeDetectorRef) {
    setTimeout(() => {
      this.cdkDragTransition = `0ms`;
    }, 100);
  }

  public ngOnChanges(){
    this.rootSlide = this.story.slides.find(s => s.slideType === SlideType.ROOT);
  }

  public ngOnInit() {
    this.selectedSlide$ = this.store.select(getSelectedSlide);
    this.subscriptions.push(this.selectedSlide$.subscribe(slideId => {
      if(this.currentSlide){
        this.cdkDragWindowStyle(this.currentSlide.id);
        if(this.currentSlide.id !== slideId){
          this.cdkDragChange(document.getElementsByClassName(`tf-node-${slideId}`)[0]);
        }
      }
      this.currentSlide = this.story.slides.find(slide => slide.id === slideId);
    }));

    if (!this.preview) {
      this.store.dispatch(loadPreviewStory({ previewStoryid: this.template.previewStoryId }));
    }
  }

  public ngAfterViewInit(){
    this.cdkDragWindowStyle(this.currentSlide.id);
    this.cdRef.detectChanges();
  }

  public ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public cdkDragChange(slide, dur = undefined) {
    if (slide) {
      this.cdkDragChangePosition(this.recursiveDraggableCoordinates(slide), slide.clientHeight, slide.clientWidth, dur);
    }
  }

  // calculate the distance from the slide to the root li
  public recursiveDraggableCoordinates(slide, coordinates = { offsetLeft: 0, offsetTop: 0 }) {
    if (slide.nodeName !== 'UL') {
      coordinates.offsetLeft += slide.offsetLeft;
      coordinates.offsetTop += slide.offsetTop;
    }

    if (slide.parentNode.id !== 'node-root') {
      this.recursiveDraggableCoordinates(slide.parentNode, coordinates);
    }
    return coordinates;
  }

  public cdkDragChangePosition(coordinates, height, width, dur = undefined) {
    const window = document.getElementById(`stScrollZoom`);
    const startTree = document.querySelector(`.tf-custom > ul`);
    const startTreePadding = parseInt(getComputedStyle(startTree).padding, 10);
    const startTreeMargin = parseInt(getComputedStyle(startTree).margin, 10);
    const duration = dur ? dur : 100;
    this.cdkDragLeftPosition =
      window.clientWidth / 2 -
      coordinates.offsetLeft -
      width / 2 -
      startTreePadding -
      startTreeMargin +
      this.cdkDragWindowULLeft / 2;
    this.cdkDragTopPosition =
      window.clientHeight / 2 -
      coordinates.offsetTop -
      height / 2 -
      startTreePadding -
      startTreeMargin +
      this.cdkDragWindowULTop / 2;

    this.cdkDragTransition = `${duration}ms`;
    setTimeout(() => {
      this.cdkDragTransition = `0ms`;
    }, duration);
  }

  public onDragDrop(event: CdkDragDrop<TreeSlide>) {
    event.item.element.nativeElement.classList.remove('active');
    if (this.canBeDropped(event)) {
      const movingItem = event.item.data;
      this.store.dispatch(
        updateTouchpointParent({
          slideId: movingItem.id,
          oldDarentSlideId: parseInt(event.previousContainer.id, 10),
          newParentId: event.container.data.id,
        }),
      );
    } else {
      this.toaster.warning('This slide cannot move to this location');
    }
  }

  public sidewaysZoom(scrollUp, ctrl = false, alt = false) {
    if (scrollUp) {
      if (ctrl && !alt && this.cdkDragLeftPosition <= this.cdkDragWindowULWidth) this.cdkDragLeftPosition += 30;
      if (alt && !ctrl) this.checkZoomChange(scrollUp);
      if (!alt && !ctrl && this.cdkDragTopPosition <= this.cdkDragWindowULHeight) this.cdkDragTopPosition += 30;
    } else {
      if (ctrl && !alt && this.cdkDragLeftPosition > 0) this.cdkDragLeftPosition -= 30;
      if (alt && !ctrl) this.checkZoomChange(scrollUp);
      if (!alt && !ctrl && this.cdkDragTopPosition > 0) this.cdkDragTopPosition -= 30;
    }
  }

  public checkZoomChange(scrollUp, multiplier = 1) {
    // get current width and height of the tree
    const currentTreeWidth = document.querySelectorAll('#cdkDragWindow > ul > li')[0].clientWidth;
    const currentTreeHeight = document.querySelectorAll('#cdkDragWindow > ul > li')[0].clientHeight;
    const currentCdkDragWindowULLeft = this.cdkDragWindowULLeft;
    const currentcdkDragWindowULTop = this.cdkDragWindowULTop;

    // change zoom size
    if (scrollUp && this.zoomValue < this.maxZoom) {
      this.zoomValue += this.incrementZoom * multiplier;
    } else if (!scrollUp && this.zoomValue > this.minZoom) {
      this.zoomValue -= this.incrementZoom * multiplier;
    }

    // wait for the zoom changes to take effect
    requestAnimationFrame(() => {
      this.cdkDragWindowStyle(this.currentSlide.id);

      // get new width and height of the tree
      const newTreeWidth = document.querySelectorAll('#cdkDragWindow > ul > li')[0].clientWidth;
      const newTreeHeight = document.querySelectorAll('#cdkDragWindow > ul > li')[0].clientHeight;
      const newCdkDragWindowULLeft = this.cdkDragWindowULLeft;
      const newdkDragWindowULTop = this.cdkDragWindowULTop;

      // check if the new width is the same. this in case of requestAnimationFrame activating multiple times
      if (this.checkZoomWidth !== newTreeWidth) {
        this.checkZoomWidth = newTreeWidth; // set new check

        // move the tree so that it zooms towards the center
        this.cdkDragLeftPosition +=
          (currentTreeWidth - newTreeWidth) / 2 - (currentCdkDragWindowULLeft - newCdkDragWindowULLeft) / 2;
        this.cdkDragTopPosition +=
          (currentTreeHeight - newTreeHeight) / 2 - (currentcdkDragWindowULTop - newdkDragWindowULTop) / 2;
      }
    });
  }

  public onDragEnded() {
    const cdkDragWindow = document.getElementById(`cdkDragWindow`);
    const transformWindows = cdkDragWindow.style.transform;
    const coordinates = transformWindows
      .replace('translate3d(', '')
      .replace(')', '')
      .split('px, ');
    this.cdkDragLeftPosition = parseInt(coordinates[0], 10);
    this.cdkDragTopPosition = parseInt(coordinates[1], 10);
  }

  private canBeDropped(event: CdkDragDrop<any, any>): boolean {
    // TODO the single equals is a lazy workaround, parent drop need to be fixed
    // eslint-disable-next-line eqeqeq
    return event.container.data.id !== event.previousContainer.id && event.container.data.id != event.item.data.id;
  }

  private cdkDragWindowStyle(slideId: number): void {
    const cdkDragWindowUL = document.querySelectorAll('#cdkDragWindow > ul')[0];
    const window = document.getElementById(`stScrollZoom`);
    const initCheck = this.cdkDragWindowULWidth === 0;
    this.cdkDragWindowULWidth = cdkDragWindowUL.clientWidth;
    this.cdkDragWindowULHeight = cdkDragWindowUL.clientHeight;

    if (-(cdkDragWindowUL.clientWidth * 2 - window.clientWidth) >= 0) this.cdkDragWindowULLeft = 0;
    else this.cdkDragWindowULLeft = cdkDragWindowUL.clientWidth * 2 - window.clientWidth;

    if (-(cdkDragWindowUL.clientHeight * 2 - window.clientHeight) >= 0) this.cdkDragWindowULTop = 0;
    else this.cdkDragWindowULTop = cdkDragWindowUL.clientHeight * 2 - window.clientHeight;
    if (initCheck) {
      this.cdkDragChange(document.getElementsByClassName(`tf-node-${slideId}`)[0], 0);
    }
  }
}
