import { Action, createReducer, on } from '@ngrx/store';
import { User } from '@storytemple/models';
import * as LoggedInUserActions from '../actions/loggedInUser.actions';

export interface LoggedInUserState {
  user: User;
}

const initialState: LoggedInUserState = {
  user: undefined,
};

export const loggedInUserReducer = createReducer(
  initialState,
  // Load
  on(LoggedInUserActions.setLoggedInUser, (state, { user }) => ({
    ...state,
    user,
  })),
);
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function loggedInUserReducerFunction(state: LoggedInUserState | undefined, action: Action) {
  return loggedInUserReducer(state, action);
}
