import { Component, Input, OnInit } from '@angular/core';
import { AiService } from '@shared/services/http/ai-service/ai.service';
import { Model } from '@shared/models/entities/AI/model';
import { Story } from '@shared/models/entities/story';
import { Store } from '@ngrx/store';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { updateStoryModel } from '@shared/store/actions/story.actions';

@Component({
  selector: 'st-model-element',
  templateUrl: './model-element.component.html',
  styleUrls: ['./model-element.component.scss'],
})
export class ModelElementComponent implements OnInit {
  @Input() private story: Story;
  public models: Model[];
  public selectedModel: Model;

  constructor(
    private aiService: AiService,
    private store: Store<AppState>
  ) {}

  public async ngOnInit() {

    this.models = await this.aiService.getAllModels().toPromise();
    if(this.story.modelId != null){
      this.selectedModel = this.models.find(model => model.MID === this.story.modelId);
    }
  }

  public saveNewModel(modelId) {
    const data: Partial<Story> = { id: this.story.id, modelId };
    this.store.dispatch(updateStoryModel({filter: data}));   
    this.aiService.setModelId(modelId);
  }

}
