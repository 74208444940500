import { AssetType } from '@shared/models/entities/asset/assetType';
import { Mime } from '@shared/models/entities/asset/mimeType';
import { SaveStoryAssetDto } from './saveStoryAsset.dto';

export class SaveVideoAssetDto extends SaveStoryAssetDto {
    constructor(public uuid: string, mimeType: Mime, assetType: AssetType,
        name: string,
        storyId: number) {
        super(mimeType, assetType, name, storyId);
    }
}