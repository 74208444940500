import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Story } from '@shared/models/entities/story';
import { UpdateDto } from '@shared/dto/update.dto';
import { environment } from 'src/environments/environment';
import { SharedStoryVisibilityType } from '@shared/dto/usergroupsorg.dto';

@Injectable({
  providedIn: 'root',
})
export class StoryService {
  public readonly URL = environment.API_URL;

  public presenterView = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  public getStories(): Observable<Story[]> {
    return this.http.get<Story[]>(`${this.URL}/story/findForUser`);
  }

  public getSharedWithUser(): Observable<Record<SharedStoryVisibilityType, Story[]>> {
    return this.http.get<Record<SharedStoryVisibilityType, Story[]>>(`${this.URL}/story/findSharedWithUser`);
  }

  public getOrgStories(): Observable<Story[]> {
    return this.http.get<Story[]>(`${this.URL}/story/findForUserOrg`);
  }

  public getGroupsStories(): Observable<Story[]> {
    return this.http.get<Story[]>(`${this.URL}/story/findForUserGroups`);
  }

  public getDirectlySharedStories(): Observable<Story[]> {
    return this.http.get<Story[]>(`${this.URL}/story/findForUserShared`);
  }

  public getNormalizedStory(storyId: number): Observable<any> {
    return this.http.get<Story[]>(`${this.URL}/story/findNormalizedStory/${storyId}`);
  }

  public getAllStories(): Observable<Story[]> {
    return this.http.get<Story[]>(`${this.URL}/story/findall`);
  }

  public getStoryById(id: number, shareToken: string): Observable<Story> {
    if (shareToken) {
      return this.http.get<Story>(`${this.URL}/story/findOne/${id}?shareToken=${shareToken}`);
    } else {
      return this.http.get<Story>(`${this.URL}/story/findOne/${id}`);
    }
  }

  public findUserPreviewStories(): Observable<Story[]> {
    return this.http.get<Story[]>(`${this.URL}/story/findUserPreviewStories`);
  }

  public getPreviewStory(storyId: number): Observable<Story> {
    return this.http.get<Story>(`${this.URL}/combiner/findPreviewSt/${storyId}`);
  }

  public delete(id: number) {
    return this.http.delete<any>(`${this.URL}/story/deleteOne/${id}`);
  }

  public setModel(data: Partial<Story>) {
    return this.http.put<any>(`${this.URL}/story/putModel`, data);
  }

  public update(key: Partial<Story>, filter: Partial<Story>) {
    const dto = new UpdateDto(key, filter);
    return this.http.put(`${this.URL}/story/update`, dto);
  }

  public duplicate(id: number): Observable<Story> {
    return this.http.get<Story>(`${this.URL}/story/duplicate/${id}`);
  }

  public createStoryFromPreview(storyId: number): Observable<Story> {
    return this.http.get<Story>(`${this.URL}/story/createStoryFromPreview/${storyId}`);
  }
}
