import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EditorService } from '@pages/editor/services/editor.service';

@Component({
  selector: 'st-multiplechoice-element',
  templateUrl: './multiplechoice-element.component.html',
  styleUrls: ['./multiplechoice-element.component.scss'],
})
export class MultiplechoiceElementComponent implements OnInit, OnChanges {
  @Output() public save = new EventEmitter<boolean>();

  // retreive changes from the text editor and if there is a change refresh the answers
  @Input() public changeAnswers: EventEmitter<any>;

  public answers = [];
  public question: HTMLElement;
  public selected: any;
  public intSlideForm: FormGroup;

  constructor(fb: FormBuilder, private editorServices: EditorService) {
    this.intSlideForm = fb.group({
      answer: [],
      seconds: [],
    });
  }

  /**
   * function selects question and sets timeToAnswer in seconds
   */
  public ngOnInit() {
    this.question = this.editorServices.slideDom.querySelector('#question1');
    this.intSlideForm
      .get('seconds')
      .setValue(Number(this.question.getAttribute('timetoanswer')) / 1000, { onlySelf: true });
    this.refreshAnswers();

    this.changeAnswers.subscribe(() => {
      this.refreshAnswers();
    });
  }

  /**
   * When the value in the form changes, the slide svg is edited and saved to db
   */
  public ngOnChanges(): void {
    this.intSlideForm.get('answer').valueChanges.subscribe(val => {
      this.question.setAttribute('correctanswer', val);
      this.save.emit(true);
    });
    this.intSlideForm.get('seconds').valueChanges.subscribe(val => {
      // timeToAnswer is set to miliseconds
      this.question.setAttribute('timetoanswer', (val * 1000) as unknown as string);
      this.save.emit(true);
    });
  }

  /**
   * Function to refresh answers in form select
   */

  public refreshAnswers() {
    this.answers = [];
    const answersEement = Array.from(this.editorServices.slideDom.querySelectorAll('[id*="answer"]'));
    answersEement.forEach(element => {
      this.answers.push(element.querySelector('p'));
    });
    this.selected = this.answers[0];
  }
}
