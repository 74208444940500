<div class="text-element">
  <mat-card-title>Text editor</mat-card-title>
  <form class="example-form">
    <mat-form-field>
      <mat-label>Font Family</mat-label>
      <input
        type="text"
        aria-label="Font"
        matInput
        name="font"
        [matAutocomplete]="auto"
        [(ngModel)]="selectedElement.stTextElement.font"
      />
      <mat-autocomplete (optionSelected)="changeFont($event)" #auto="matAutocomplete">
        <mat-option *ngFor="let font of filteredFonts | async" [value]="font.name">
          {{ font.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <mat-card-content>
    <div style="display: flex;">
      <mat-slide-toggle
        color="primary"
        [checked]="selectedElement.stTextElement.autoSize"
        (change)="toggleAutoSize($event)"
        >Auto Size</mat-slide-toggle
      >
      <div style="margin-left: auto">
        <button (click)="expandTextEditor()" mat-icon-button class="add-notes">
          <mat-icon class="icon" matTooltip="Expand" matTooltipPosition="after">open_in_new</mat-icon>
        </button>
      </div>
    </div>

    <st-rich-text-editor
      small
      [content]="selectedElement.stTextElement.content"
      (contentEvent)="onContentChanged($event)"
    >
    </st-rich-text-editor>
  </mat-card-content>
</div>
