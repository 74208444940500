import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PreviewStory } from '@shared/models/domain-models/previewStory/previewStory';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CombinerService {
  private readonly URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public findPreviewStory(storyId: number): Observable<PreviewStory> {
    return this.http.get<PreviewStory>(`${this.URL}/combiner/findPreviewStory/${storyId}`);
  }
}
