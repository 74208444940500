import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UpdateDto } from '@shared/dto/update.dto';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { BackgroundElement } from '@shared/models/domain-models/editor/parser/elements/backgroundElement';
import { ImageElement } from '@shared/models/domain-models/editor/parser/elements/imageElement';
import { StElementType } from '@shared/models/entities/stElements/stElementType';
import { StImageElement } from '@shared/models/entities/stElements/stImageElement.entity';
import { updateStElement } from '@shared/store/actions/story.actions';
import { AssetService } from '@shared/services/http/asset-service/asset.service';
import { ImageSelectorComponent } from './image-selector/image-selector.component';
import { ImageAsset } from '@shared/models/entities/asset/imageAsset';
import { AssetType } from '@shared/models/entities/asset/assetType';

@Component({
  selector: 'st-image-element',
  templateUrl: './image-element.component.html',
  styleUrls: ['./image-element.component.scss'],
})
export class ImageElementComponent implements OnInit, OnChanges {
  @Input() public selectedElement: ImageElement | BackgroundElement;
  @Input() public assets: ImageAsset[];
  @Input() public storyId: number;
  public activeAsset: ImageAsset;
  public imageType = AssetType.ORIGINAL_IMAGE;
  public icon: boolean;
  public image: any;
  private dialogRef: MatDialogRef<ImageSelectorComponent>;

  constructor(
    public dialog: MatDialog,
    public assetService: AssetService,
    private store: Store<AppState>,
    public changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    this.icon = false;
  }

  public ngOnChanges() {
    this.activeAsset = this.assets.find(asset => asset.id === this.selectedElement.stElement.assetId);
    if (this.dialogRef) {
      if (this.dialogRef.componentInstance) {
        this.dialogRef.componentInstance.setData(this.assets);
      }
    }
  }

  public updateImage(asset: ImageAsset) {
    this.activeAsset = asset;
    const dto = new UpdateDto<StImageElement>(
      { slotId: this.selectedElement.stElement.slotId, slideId: this.selectedElement.stElement.slideId },
      { stElementType: StElementType.STIMAGEELEMENT, assetId: asset.id, x: null, y: null, width: null, height: null },
    );
    this.selectedElement.changeImage(asset.imageUrl);
    this.store.dispatch(updateStElement({ dto, combine: false }));
  }

  public changeImage() {
    this.dialogRef = this.dialog.open(ImageSelectorComponent, {
      width: '500px',
      height: '500px',
      data: { selectedElement: this.selectedElement, assets: this.assets, storyId: this.storyId },
    });

    this.dialogRef.afterClosed().subscribe((result: ImageAsset) => {
      if (result) {
        this.updateImage(result);
      }
    });
  }

  public removeImage() {
    const dto = new UpdateDto<StImageElement>(
      { slotId: this.selectedElement.stElement.slotId, slideId: this.selectedElement.stElement.slideId },
      { stElementType: StElementType.STIMAGEELEMENT, assetId: null },
    );
    this.selectedElement.removeImage();
    this.store.dispatch(updateStElement({ dto, combine: false }));
  }
}
