import { Action, createReducer, on } from '@ngrx/store';
import { PreviewStory } from '@shared/models/domain-models/previewStory/previewStory';
import { Asset } from '@shared/models/entities/asset/asset';
import { Notes } from '@shared/models/entities/notes';
import { Slide } from '@shared/models/entities/slide';
import { SlideType } from '@shared/models/entities/slideType';
import { Story } from '@shared/models/entities/story';
import { Template } from '@shared/models/entities/template';
import { Touchpoint } from '@shared/models/entities/touchpoint';
import { TouchPointObject } from '@shared/models/entities/touchPointObject';
import { TouchPointTag } from '@shared/models/entities/touchpointTag';
import * as StoryActions from '../actions/story.actions';

export interface StoryState {
  story: Story;
  slides: Slide[];
  touchpoints: Touchpoint[];
  notes: Notes[];
  template: Template;
  assets: Asset[];
  loadingStory: boolean;
  previewStory: PreviewStory;
  error: Error;
}

const initialState: StoryState = {
  story: undefined,
  slides: [],
  touchpoints: [],
  notes: [],
  template: undefined,
  assets: [],
  loadingStory: false,
  previewStory: undefined,
  error: undefined,
};

export const storyReducer = createReducer(
  initialState,
  // load Story
  on(StoryActions.loadStory, state => ({ ...state, loadingStory: true })),
  on(StoryActions.loadStorySuccess, (state, { story, slides, touchpoints, template, assets, notes }) => ({
    ...state,
    story,
    slides,
    selectedSlide: slides.find(s => s.slideType === SlideType.ROOT),
    touchpoints,
    notes,
    template,
    assets,
    loadingStory: false,
    error: undefined,
  })),
  on(StoryActions.loadStoryFailure, (state, { error }) => ({ ...state, loadingStory: false, error })),

  // Update touchpoint parent
  on(StoryActions.updateTouchpointParentSucces, (state, { slideId, oldDarentSlideId, newParentId }) => ({
    ...state,
    touchpoints: state.touchpoints.map(tp =>
      tp.parentSlideId === oldDarentSlideId && tp.nextSlideId === slideId ? { ...tp, parentSlideId: newParentId } : tp,
    ),
  })),
  on(StoryActions.updateTouchpointParentFailure, (state, { error }) => ({ ...state, loadingStory: false, error })),

  // Update touchpoint
  on(StoryActions.updateTouchpointSucces, (state, { touchpoint }) => ({
    ...state,
    touchpoints: state.touchpoints.map(tp => (tp.id === touchpoint.id ? (tp = touchpoint) : tp)),
  })),
  on(StoryActions.updateTouchpointFailure, (state, { error }) => ({ ...state, error })),

  // Add slide
  on(StoryActions.addSlideSucces, (state, { slide, touchpoints }) => ({
    ...state,
    touchpoints: [...state.touchpoints, ...touchpoints],
    slides: [...state.slides, slide],
  })),
  on(StoryActions.addSlideFailure, (state, { error }) => ({ ...state, loadingStory: false, error })),

  // Remove Slide
  on(StoryActions.removeSlideSucces, (state, { removedSlideId, removedTouchpoints, changedTouchpoints }) => ({
    ...state,
    touchpoints: state.touchpoints
      .filter(tp => !removedTouchpoints.some(rmtp => rmtp === tp.id))
      .map(tp => {
        const changedTp = changedTouchpoints.find(ctp => ctp.tpId === tp.id);
        if (changedTp) {
          return { ...tp, parentSlideId: changedTp.newParentId };
        } else {
          return tp;
        }
      }),
    slides: state.slides.filter(s => s.id !== removedSlideId),
  })),
  on(StoryActions.removeSlideFailure, (state, { error }) => ({ ...state, loadingStory: false, error })),

  // Update Slide
  on(StoryActions.updateSlideSucces, (state, { key, filter }) => ({
    ...state,
    slides: state.slides.map(sl => {
      if (Object.keys(key).every(k => sl[k] === key[k])) {
        return { ...sl, ...filter } as Slide;
      } else {
        return sl;
      }
    }),
  })),
  on(StoryActions.updateTouchpointFailure, (state, { error }) => ({ ...state, error })),

  // Add touchpoint of type link
  on(StoryActions.addLinkTouchpointSucces, (state, { touchpoints }) => ({
    ...state,
    touchpoints: [...state.touchpoints, ...touchpoints],
  })),
  on(StoryActions.addLinkTouchpointFailure, (state, { error }) => ({ ...state, loadingStory: false, error })),

  // Remove touchpoint
  on(StoryActions.removeLinkTouchpointSucces, (state, { touchpointId }) => ({
    ...state,
    touchpoints: state.touchpoints.filter(tp => tp.id !== touchpointId),
  })),
  on(StoryActions.removeLinkTouchpointFailure, (state, { error }) => ({ ...state, loadingStory: false, error })),

  // update stElement
  on(StoryActions.updateStElementSucces, (state, { stElement }) => ({
    ...state,
    slides: state.slides.map(s =>
      s.id === stElement.slideId
        ? { ...s, stElements: s.stElements.map(ste => (ste.slotId === stElement.slotId ? stElement : ste)) }
        : s,
    ),
  })),

  // save stElement
  on(StoryActions.saveBackgroundStElementSucces, (state, { slide }) => ({
    ...state,
    slides: state.slides.map((s: Slide) => (s.id === slide.id ? slide : s)),
  })),

  // combiner slide succes
  on(StoryActions.combineSlideSucces, (state, { slide }) => ({
    ...state,
    slides: state.slides.map(s => (s.id === slide.id ? slide : s)),
  })),

  // combiner slide succes
  on(StoryActions.loadPreviewStorySucces, (state, { previewStory }) => ({
    ...state,
    previewStory,
  })),

  on(StoryActions.updateStory, state => ({ ...state })),

  on(StoryActions.updateStorySuccess, (state, { filter }) => ({
    ...state,
    story: { ...state.story, ...filter },
    error: undefined,
  })),

  on(StoryActions.updateStoryFailure, (state, { error }) => ({ ...state, error })),

  on(StoryActions.updateStoryModel, state => ({ ...state })),
  on(StoryActions.updateStoryModelSuccess, (state, {}) => ({
    ...state,
    touchpoints: state.touchpoints.map(t => {
      if (t.nextSlideId == null) {
        const touchObjects = t.touchpointObjects.map(to => ({ ...to, ...{ objectId: null } } as TouchPointObject));
        return { ...t, ...{ touchpointObjects: touchObjects } } as Touchpoint;
      }
      return t;
    }),
    error: undefined,
  })),
  on(StoryActions.updateStoryModelFailure, (state, { error }) => ({ ...state, error })),

  // Update touchpoint object
  on(StoryActions.updateTouchpointObject, state => ({ ...state })),
  on(StoryActions.updateTouchpointObjectSuccess, (state, { key, filter }) => ({
    ...state,
    touchpoints: state.touchpoints.map(t => {
      if (t.nextSlideId === null) {
        const touchObjects = t.touchpointObjects.map(to => {
          if (Object.keys(key).every(k => to[k] === key[k])) {
            return { ...to, ...filter } as TouchPointObject;
          }
          return to;
        });
        return { ...t, ...{ touchpointObjects: touchObjects } } as Touchpoint;
      }
      return t;
    }),
    error: undefined,
  })),
  on(StoryActions.updateTouchpointObjectFailure, (state, { error }) => ({ ...state, error })),

  // Update Touchpoint Tag
  on(StoryActions.updateTouchpointTag, state => ({ ...state })),
  on(StoryActions.updateTouchpointTagSuccess, (state, { key, filter }) => ({
    ...state,
    touchpoints: state.touchpoints.map(t => {
      if (t.nextSlideId === null) {
        const touchTags = t.touchpointTags.map(to => {
          if (to.touchPointId === key.touchPointId && to.nextSlideId === key.nextSlideId) {
            return { ...to, ...filter } as TouchPointTag;
          }
          return to;
        });
        return { ...t, ...{ touchpointTags: touchTags } } as Touchpoint;
      }
      return t;
    }),
    error: undefined,
  })),
  on(StoryActions.updateTouchpointObjectFailure, (state, { error }) => ({ ...state, error })),

  on(StoryActions.createTouchPointsForMode, state => ({ ...state })),
  on(StoryActions.createTouchPointsForModeSuccess, (state, { touchpoints }) => ({
    ...state,
    touchpoints: state.touchpoints.concat(touchpoints),
    error: undefined,
  })),
  on(StoryActions.updateTouchpointObjectFailure, (state, { error }) => ({ ...state, error })),

  on(StoryActions.changeSlideTemplate, state => ({ ...state })),
  on(StoryActions.changeSlideTemplateFailure, (state, { error }) => ({ ...state, error })),

  on(StoryActions.saveStoryAssetSucces, (state, { asset }) => ({
    ...state,
    assets: [...state.assets, asset],
  })),

  on(StoryActions.removeStoryAssetSucces, (state, { assetId }) => ({
    ...state,
    assets: state.assets.filter(asset => asset.id !== assetId),
  })),
  on(StoryActions.updateNotesSucces, (state, { key, filter }) => ({
    ...state,
    notes: state.notes.map(n => (n.id === key.id ? { ...n, ...filter } : n)),
  })),
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function storyReducerFunction(state: StoryState | undefined, action: Action) {
  return storyReducer(state, action);
}
