import { StVideoOptions } from '@shared/models/domain-models/video/stVideoOptions';
import { Element } from '@shared/models/domain-models/editor/parser/element';
import { ElementType } from './elementType';
import { StImageElement } from '@shared/models/entities/stElements/stImageElement.entity';
import { StVideoElement } from '@shared/models/entities/stElements/stVideoElement.entity';
import { BackgroundType } from '@shared/models/entities/backgroundType';

export class BackgroundElement implements Element {
  public slideDom: Document;
  public elementType: ElementType;

  constructor(
    public rootNode,
    public stElement: any,
    public stVideoElement: StVideoElement,
    public stImageElement: StImageElement,
  ) {
    this.elementType = ElementType.BACKGROUND_ELEMENT;
  }

  public getElement(): HTMLElement {
    return this.rootNode;
  }

  public getImageElement(): SVGImageElement {
    return this.rootNode.querySelector('image');
  }

  public editElement() {}

  public changeBackgroundType(backgroundType: BackgroundType) {
    const bgVideo = this.rootNode.querySelector('#backgroundVideo1') as HTMLElement;
    const bgImage = this.rootNode.querySelector('#backgroundImage1') as HTMLElement;
    const bgDefault = this.rootNode.querySelector('#backgroundDefault') as HTMLElement;
    switch (backgroundType) {
      case BackgroundType.IMAGE:
        bgVideo.style.display = 'none';
        bgImage.style.display = 'block';
        bgDefault.style.display = 'block';
        break;
      case BackgroundType.VIDEO:
        bgImage.style.display = 'none';
        bgVideo.style.display = 'block';
        bgDefault.style.display = 'none';
        break;
      case BackgroundType.DEFAULT:
        bgImage.style.display = 'none';
        bgVideo.style.display = 'none';
        bgDefault.style.display = 'block';
        break;
      default:
        break;
    }
  }

  public changeVideo(videoUrl: string, videoOptions?: StVideoOptions) {
    const video: HTMLElement = this.rootNode.querySelector(' iframe');
    video.setAttribute('src', videoUrl);
    video.setAttribute('allow', 'autoplay');
    video.setAttribute('data-videoOptions', JSON.stringify(videoOptions));
  }

  public editImage(data: any) {
    const svg = this.rootNode.querySelector('svg');
    svg.setAttribute('viewBox', `${data.x}, ${data.y}, ${data.width}, ${data.height}`);
  }

  public changeImage(imageUrl: string) {
    const svg = this.rootNode.querySelector('svg');
    const imageElement = svg.querySelector('image');
    const imageWidth = imageElement.getAttribute('width');
    const imageHeight = imageElement.getAttribute('height');
    svg.setAttribute('viewBox', `0, 0, ${imageWidth}, ${imageHeight}`);
    imageElement.setAttribute('xlink:href', imageUrl);
  }

  public getVideoOptions(): string {
    const video: HTMLElement = this.rootNode.querySelector(' iframe');
    return video.getAttribute('data-videooptions');
  }

  public removeImage() {
    this.rootNode.querySelector('image').setAttribute('xlink:href', '');
  }

  public changeBgColor(color: string) {
    const background = this.rootNode.querySelector('#backgroundDefault rect') as SVGRectElement;
    background.style.fill = color;
  }
}
