import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Story } from '@shared/models/entities/story';
import { StoryService } from '@shared/services/http/story-service/story.service';
import { v4 as uuid } from 'uuid';
import {
  createStoryFromPreview,
  createStoryFromPreviewFailure,
  createStoryFromPreviewSuccess,
  createTempDuplicateStory,
  duplicateStory,
  duplicateStoryFailure,
  duplicateStorySuccess,
  loadPreviewStories,
  loadPreviewStoriesFailure,
  loadPreviewStoriesSuccess,
  loadSharedStories,
  loadSharedStoriesFailure,
  loadSharedStoriesSuccess,
  loadStories,
  loadStoriesFailure,
  loadStoriesSuccess,
  removeStory,
  removeStoryFailure,
  removeStorySuccess,
  updateStories,
  updateStoriesFailure,
  updateStoriesSuccess,
} from '../actions/stories.actions';
import { Store } from '@ngrx/store';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';

@Injectable({
  providedIn: 'root',
})
export class StoriesEffects {
  public loadStories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStories),
      mergeMap(() =>
        this.storyService.getStories().pipe(
          map(data => loadStoriesSuccess({ list: data })),
          catchError(error => of(loadStoriesFailure(error))),
        ),
      ),
    ),
  );

  public loadSharedStories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSharedStories),
      mergeMap(() =>
        this.storyService.getSharedWithUser().pipe(
          map(data => loadSharedStoriesSuccess({ record: data })),
          catchError(error => of(loadSharedStoriesFailure(error))),
        ),
      ),
    ),
  );

  public loadPreviewStories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPreviewStories),
      mergeMap(() =>
        this.storyService.findUserPreviewStories().pipe(
          map(data => loadPreviewStoriesSuccess({ previewStories: data })),
          catchError(error => of(loadPreviewStoriesFailure(error))),
        ),
      ),
    ),
  );

  public createStoryFromPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createStoryFromPreview),
      mergeMap(action =>
        this.storyService.createStoryFromPreview(action.storyId).pipe(
          map(story => {
            story.slides = action.previewStory.slides;
            return createStoryFromPreviewSuccess({ story });
          }),
          catchError(error => of(createStoryFromPreviewFailure(error))),
        ),
      ),
    ),
  );

  public duplicateStory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(duplicateStory),
      mergeMap(action => {
        const tempId = uuid();
        const tempStory = new Story();
        (tempStory as any).tempId = tempId;
        tempStory.slides = action.story.slides;
        tempStory.name = `${action.story.name} | Copy`;
        tempStory.created = action.story.created;
        tempStory.updated = new Date();
        this.store.dispatch(createTempDuplicateStory({ story: tempStory, tempId }));
        return this.storyService.duplicate(action.storyId).pipe(
          map(data => duplicateStorySuccess({ story: data, tempId, originalSlides: action.story.slides })),
          catchError(error => of(duplicateStoryFailure(error))),
        );
      }),
    ),
  );

  public removeStory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeStory),
      mergeMap(action =>
        this.storyService.delete(action.storyId).pipe(
          map(data => removeStorySuccess({ storyId: data })),
          catchError(error => of(removeStoryFailure(error))),
        ),
      ),
    ),
  );

  public updateStories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStories),
      mergeMap(action =>
        this.storyService.update(action.key, action.filter).pipe(
          map(() => updateStoriesSuccess({ key: action.key, filter: action.filter })),
          catchError(error => of(updateStoriesFailure(error))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private storyService: StoryService, private store: Store<AppState>) {}
}
