import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/models/domain-models/appState';
import { Story } from '@shared/models/entities/story';
import { createStoryFromPreview } from '@shared/store/actions/stories.actions';
import { Observable } from 'rxjs';

@Component({
  selector: 'st-create-story-dialog',
  templateUrl: './create-story-dialog.component.html',
  styleUrls: ['./create-story-dialog.component.scss'],
})
export class CreateStoryDialogComponent {
  public selectedPreviewStory: Story;
  constructor(
    public dialogRef: MatDialogRef<CreateStoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public previewStories$: Observable<Story[]>,
    private store: Store<AppState>,
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public storyToSubmit() {
    this.store.dispatch(
      createStoryFromPreview({ storyId: this.selectedPreviewStory.id, previewStory: this.selectedPreviewStory }),
    );
    this.dialogRef.close();
  }

  public selectpreviewStory(previewStory) {
    this.selectedPreviewStory = previewStory;
  }
}
