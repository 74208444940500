import { NgModule, InjectionToken, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RoutingModule } from './routing.module';
import { AppComponent } from './app.component';
import { EditorModule } from './pages/editor/editor.module';
import { StoryViewModule } from './pages/story-view/story-view.module';
import { SocketIoModule } from 'ngx-socket-io';
import { StorySocket } from './shared/services/local/story-socket/story.event';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { TreeModule } from '@circlon/angular-tree-component';
import { SharedModule } from './shared/shared.module';
import { TreeEditorModule } from './pages/tree/treeEditor.module';
import { AuthenticationService } from './shared/services/local/authentication-service/authentication.service';
import { EditorGuard } from './shared/guards/editorguard.service';
import { RouterModule } from '@angular/router';
import { appRoutes } from './routing.module';
import { PlayerGuard } from './shared/guards/playerguard.service';
import { BrowserModule } from '@angular/platform-browser';
import { AngularPageVisibilityModule } from 'angular-page-visibility';
import { AnalyticsService } from './shared/services/local/analytics/analytics.service';
import { QRCodeModule } from 'angularx-qrcode';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './handlers/http-error.interceptor';
import * as Rollbar from 'rollbar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoriesEffects } from './shared/store/effects/stories.effects';
import { storiesReducerFunction } from './shared/store/reducers/stories.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { StoryEffects } from './shared/store/effects/story.effects';
import { storyReducerFunction } from './shared/store/reducers/story.reducer';
import { editorReducerFunction } from './shared/store/reducers/editor.reducer';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AuthenticationInterceptor } from './handlers/authentication.interceptor';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { StoryPdfComponent } from './pages/story-pdf/story-pdf/story-pdf.component';
import { LoggedInGuard } from '@shared/guards/loggedinGuard.service';
import { loggedInUserReducerFunction } from '@shared/store/reducers/loggedInUser.reducer';

const rollbarConfig = {
  accessToken: 'b75e0d2f497447b5b2e6bc0629fc5886',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
  declarations: [AppComponent, StoryPdfComponent],
  imports: [
    QRCodeModule,
    SharedModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    EditorModule,
    StoryViewModule,
    DashboardModule,
    RoutingModule,
    HttpClientModule,
    SocketIoModule,
    TreeModule,
    TreeEditorModule,
    BrowserModule,
    AngularPageVisibilityModule,
    StoreModule.forRoot({
      stories: storiesReducerFunction,
      story: storyReducerFunction,
      editor: editorReducerFunction,
      loggedInUser: loggedInUserReducerFunction,
    }),
    EffectsModule.forRoot([StoriesEffects, StoryEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    AngularSvgIconModule.forRoot(),
  ],
  providers: [
    StorySocket,
    AuthenticationService,
    AnalyticsService,
    EditorGuard,
    PlayerGuard,
    LoggedInGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: RollbarService,
      useFactory: rollbarFactory,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthenticationService) => () => authService.initCsrfToken(),
      deps: [AuthenticationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
