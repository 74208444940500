import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { EditorComponent } from './pages/editor/editor.component';
import { StoryViewComponent } from './pages/story-view/story-view.component';
import { TreeComponent } from './pages/tree/tree.component';
import { PlayerGuard } from './shared/guards/playerguard.service';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { StoryPdfComponent } from '@pages/story-pdf/story-pdf/story-pdf.component';
import { LoggedInGuard } from '@shared/guards/loggedinGuard.service';

export const appRoutes: Routes = [
  // enum in analytics will need to be updated to remove id's
  // first route had no component, this way all child routes will check if user is logged in
  {
    path: '',
    // canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'editor/:id',
        component: EditorComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'editor/:id/tree',
        component: TreeComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'editor/:id/:slideId',
        component: EditorComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'story',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'story/in-presenter-view/:id/:slide',
        component: StoryViewComponent,
        canActivate: [PlayerGuard],
      },
      {
        path: 'story/:id',
        component: StoryViewComponent,
        canActivate: [PlayerGuard],
      },
      {
        path: 'story/:id/:slideId',
        component: StoryViewComponent,
        canActivate: [PlayerGuard],
      },
      {
        path: 'to-pdf/:id',
        component: StoryPdfComponent,
        canActivate: [],
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class RoutingModule {}
