import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root', // TODO: change to editor module
})
export class PlaceholderService {
  constructor() {}

  public placePlaceholders() {
    (async () => {
      while (
        // wait until the svg is loaded in the editor
        document.querySelectorAll(`.svg-editor g[id*='image'] .select-rect`)[0] === undefined
      ) {
        // when the slide is loaded check all the editable fields and place icons
        await new Promise(resolve => setTimeout(resolve, 1000));

        try {
          // place in the empty image groups an image placeholder
          this.createImagePlaceholder();

          // A check to see if the svg is a multiple choice question
          // if so use different queryselector because the ids of the titles and text are different
          if (document.querySelectorAll('.svg-editor svg[id*=`INTERACTIVE_SLIDE`]').length <= 0) {
            // place holder for titles
            this.createTitlePlaceholder();
            // placeholder voor paragraphs
            this.createParagraphPlaceholder();
          } else {
            // Here the check is done for the interactive questions
            this.createQuestionPlaceholder();

            this.createAnswerPlaceholder();
            // if an interactive question has a title also add the placeholder title
            if (document.querySelectorAll(`.svg-editor g[id*='title'`).length >= 0) {
              this.createTitlePlaceholder();
            }
          }
        } catch (e) {}
      }
    })();
  }

  public createQuestionPlaceholder() {
    for (let index = 0; index < document.querySelectorAll(`.svg-editor g[id*='question']`).length; index++) {
      if (!document.querySelectorAll(`.svg-editor g[id*='question']`)[index].querySelector('.placeholder')) {
        // check if text still has text with the use of text.content
        if (
          document
            .querySelectorAll(`.svg-editor g[id*='question'] foreignobject`)
            [index].textContent.toString()
            .replace(/^\s+/, '')
            .replace(/\s+$/, '').length === 0 ||
          document.querySelectorAll(`.svg-editor g[id*='question'] foreignobject`)[index].textContent === undefined
        ) {
          const htmlimg = document.createElement('img');
          htmlimg.setAttribute('src', '/assets/placeholders/text-placeholder.png');
          htmlimg.setAttribute('class', 'placeholder');
          htmlimg.setAttribute(
            'style',
            ' width: 50px;height: 50px;position: absolute;  left: 50%;opacity: .3; margin-left: -25px; top: 50%; margin-top: -25px',
          );
          document.querySelectorAll(`.svg-editor g[id*='question'] foreignObject`)[index].appendChild(htmlimg);
        }
      }
    }
  }

  public createAnswerPlaceholder() {
    for (let index = 0; index < document.querySelectorAll(`.svg-editor g[id*='answer']`).length; index++) {
      if (!document.querySelectorAll(`.svg-editor g[id*='answer']`)[index].querySelector('.placeholder')) {
        // check if text still has text with the use of text.content
        if (
          document
            .querySelectorAll(`.svg-editor g[id*='answer'] foreignobject`)
            [index].textContent.toString()
            .replace(/^\s+/, '')
            .replace(/\s+$/, '').length === 0 ||
          document.querySelectorAll(`.svg-editor g[id*='answer'] foreignobject`)[index].textContent === undefined
        ) {
          const htmlimg = document.createElement('img');
          htmlimg.setAttribute('src', '/assets/placeholders/text-placeholder.png');
          htmlimg.setAttribute('class', 'placeholder');
          htmlimg.setAttribute(
            'style',
            ' width: 50px;height: 50px;position: absolute;  left: 50%;opacity: .3; margin-left: -25px; top: 50%; margin-top: -25px',
          );
          document.querySelectorAll(`.svg-editor g[id*='answer'] foreignObject`)[index].appendChild(htmlimg);
        }
      }
    }
  }

  public createTitlePlaceholder() {
    for (let index = 0; index < document.querySelectorAll(`.svg-editor g[id*='title']`).length; index++) {
      if (!document.querySelectorAll(`.svg-editor g[id*='title']`)[index].querySelector('.placeholder')) {
        // check if text still has text with the use of text.content
        if (
          document
            .querySelectorAll(`.svg-editor g[id*='title'] foreignobject`)
            [index].textContent.toString()
            .replace(/^\s+/, '')
            .replace(/\s+$/, '').length === 0 ||
          document.querySelectorAll(`.svg-editor g[id*='title'] foreignobject`)[index].textContent === undefined
        ) {
          const htmlimg = document.createElement('img');
          htmlimg.setAttribute('src', '/assets/placeholders/text-placeholder.png');
          htmlimg.setAttribute('class', 'placeholder');
          htmlimg.setAttribute(
            'style',
            ' width: 50px;height: 50px;position: absolute;  left: 50%;opacity: .3; margin-left: -25px; top: 50%; margin-top: -25px',
          );
          document.querySelectorAll(`.svg-editor g[id*='title'] foreignObject`)[index].appendChild(htmlimg);
        }
      }
    }
  }

  public createParagraphPlaceholder() {
    for (let index = 0; index < document.querySelectorAll(`.svg-editor g[id*='paragraph']`).length; index++) {
      if (!document.querySelectorAll(`.svg-editor g[id*='paragraph']`)[index].querySelector('.placeholder')) {
        // check if text still has text with the use of text.content
        if (
          document
            .querySelectorAll(`.svg-editor g[id*='paragraph'] foreignobject`)
            [index].textContent.toString()
            .replace(/^\s+/, '')
            .replace(/\s+$/, '').length === 0 ||
          document.querySelectorAll(`.svg-editor g[id*='paragraph'] foreignobject`)[index].textContent === undefined
        ) {
          const htmlimg = document.createElement('img');
          htmlimg.setAttribute('src', '/assets/placeholders/text-placeholder.png');
          htmlimg.setAttribute('class', 'placeholder');
          htmlimg.setAttribute(
            'style',
            ' width: 50px;height: 50px;position: absolute;  left: 50%;opacity: .3; margin-left: -25px; top: 50%; margin-top: -25px',
          );
          document.querySelectorAll(`.svg-editor g[id*='paragraph'] foreignObject`)[index].appendChild(htmlimg);
        }
      }
    }
  }

  public createImagePlaceholder() {
    const svgimg = this.getPlaceholder();
    const imgurl = '/assets/placeholders/image-placeholder.webp';

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < document.querySelectorAll('.svg-editor image').length; index++) {
      const imgParent = document.querySelectorAll('.svg-editor image')[index].closest('g');
      const x = parseInt(imgParent.querySelector('.select-rect').getAttribute('width'), 10) / 2;
      const y = parseInt(imgParent.querySelector('.select-rect').getAttribute('height'), 10) / 2;
      svgimg.setAttributeNS(null, 'x', x.toString());
      svgimg.setAttributeNS(null, 'y', y.toString());
      const svgimgClone = svgimg.cloneNode(true);
      const imgElement = imgParent.querySelector('image');

      if (!imgParent.querySelector('.placeholder')) {
        let imgHref;
        if (imgElement.getAttribute('xlink:href') !== undefined) {
          imgHref = imgElement.getAttribute('xlink:href');
        }
        if (imgHref == null || imgHref === '' || imgHref === undefined) {
          svgimg.setAttributeNS('http://www.w3.org/1999/xlink', 'href', imgurl);
          imgParent.appendChild(svgimgClone);
        }
        if (imgHref.includes('dummy')) {
          svgimg.setAttributeNS('http://www.w3.org/1999/xlink', 'href', imgurl);
          imgParent.appendChild(svgimgClone);
        }
      }
    }
  }

  public getPlaceholder() {
    const svgimg = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    svgimg.setAttributeNS(null, 'height', '200px');
    svgimg.setAttributeNS(null, 'width', '200px');
    svgimg.setAttributeNS(null, 'x', '10');
    svgimg.setAttributeNS(null, 'y', '10');
    svgimg.setAttributeNS(null, 'transform', 'translate(-100,-100)');

    svgimg.setAttributeNS(null, 'visibility', 'visible');
    svgimg.setAttributeNS(null, 'opacity', '.3');
    svgimg.setAttributeNS(null, 'class', 'placeholder');
    return svgimg;
  }
}
