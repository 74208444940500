import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Story } from '@shared/models/entities/story';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  public transform(stories$: Observable<Story[]>, filterText): any {
    if (filterText) {
      return stories$.pipe(
        map(stories => {
          const a = stories ? stories.filter(item => item.name.search(new RegExp(filterText, 'i')) > -1) : [];
          return a;
        }),
      );
    } else {
      return stories$;
    }
  }
}
