import { Component, EventEmitter, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SlideService } from '@shared/services/http/slide-service/slide.service';
import { EditorService } from '@pages/editor/services/editor.service';

@Component({
  selector: 'st-interactive-element',
  templateUrl: './interactive-element.component.html',
  styleUrls: ['./interactive-element.component.scss'],
})
export class InteractiveElementComponent implements OnChanges {
  // retreive changes from the text editor and if there is a change refresh the answers
  @Input() public changeAnswers: EventEmitter<any>;

  public answers = [];
  public question: HTMLElement;
  public selected: any;

  public intSlideForm: FormGroup;

  public showMultipleChoiceQuestion = false;
  public showWordcloudQuestion = false;
  public showSliderQuestion = false;

  public slideType: any;

  constructor(fb: FormBuilder, public service: SlideService, private editorServices: EditorService) {
    this.intSlideForm = fb.group({
      answer: [],
      seconds: [],
    });
  }

  /**
   * When the value in the form changes, the slide svg is edited and saved to db
   */
  public ngOnChanges(): void {
    this.slideType = this.editorServices.slideDom.querySelector('svg').dataset.questionType;

    this.intSlideForm.get('answer').valueChanges.subscribe(val => {
      this.question.setAttribute('correctanswer', val);
    });
    this.intSlideForm.get('seconds').valueChanges.subscribe(val => {
      // timeToAnswer is set to miliseconds
      this.question.setAttribute('timetoanswer', (val * 1000) as unknown as string);
    });
  }

  /**
   * Function to refresh answers in form select
   */

  public refreshAnswers() {
    this.answers = [];
    const answersEement = Array.from(this.editorServices.slideDom.querySelectorAll('[id*="answer"]'));
    answersEement.forEach(element => {
      this.answers.push(element.querySelector('p'));
    });
    this.selected = this.answers[0];
  }
}
