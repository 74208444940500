import { Injectable, EventEmitter } from '@angular/core';
import { Slide } from '@shared/models/entities/slide';
import { Story } from '@shared/models/entities/story';
import { Socket } from 'ngx-socket-io';
import { TouchpointStyle } from '@shared/models/entities/touchpointStyle';
import { BehaviorSubject } from 'rxjs';
import { Touchpoint } from '@shared/models/entities/touchpoint';
import { TemplateService } from '@shared/services/http/template-service/template.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentStoryService {
  public currentStoryId: number;
  public currentStory: Story;
  public sessionCode: string;
  public currentSlide: Slide;
  public rootSlide: Slide;
  public assetsLoaded = new BehaviorSubject<boolean>(false);
  public dependenciesLoaded = new BehaviorSubject<boolean>(false);
  public inPresenterView: boolean;
  public presenterViewId: string;
  public storyAppSocket: Socket;
  public touchpointChanged = new BehaviorSubject<Touchpoint>(new Touchpoint());
  public justInitialized: boolean;
  public animationEvents = new EventEmitter<object>();
  public dependencies = {};
  private localTouchpointStyle: TouchpointStyle[];

  constructor(private templateService: TemplateService) {}

  get storyInPresenterView(): boolean {
    return location.pathname.includes('in-presenter-view');
  }

  public get touchpointStyle(): Promise<TouchpointStyle[]> {
    return new Promise(async (resolve) => {
      if (this.localTouchpointStyle) {
        resolve(this.localTouchpointStyle);
      } else {
        this.localTouchpointStyle = await this.templateService
          .findTouchpointStyleByTemplateId(this.currentStory.templateId)
          .toPromise();
        resolve(this.localTouchpointStyle);
      }
    });
  }

  get presenterViewPath(): string {
    if (this.inPresenterView) {
      return '/in-presenter-view';
    } else {
      return '';
    }
  }
}
