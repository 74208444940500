<div class="image-element">
  <mat-card-title>Image editor</mat-card-title>
  <mat-card-content>
    <button mat-raised-button id="changeImageButton" (click)="changeImage()" color="primary">Change image</button>
    <button mat-raised-button (click)="removeImage()">Remove image</button>
    <st-image
      *ngIf="activeAsset?.type === imageType"
      [selectedElement]="selectedElement"
      [originalAsset]="activeAsset"
    ></st-image>
  </mat-card-content>
</div>
