<h2 mat-dialog-title>Interactive Session code</h2>
<mat-dialog-content class="mat-typography">
  <h3>Connect with QR-Code</h3>
  <div class="qrcode-cont">
    <qrcode [qrdata]="sessionCodeUrl" [width]="256" [errorCorrectionLevel]="'M'"> </qrcode>
  </div>

  <h4>or</h4>
  <h3>Connect with URL:</h3>
  <p (click)="copyToClipboard()" matTooltip="Copy to clipboard" matTooltipPosition="below">{{ sessionCodeUrl }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>close</button>
</mat-dialog-actions>
