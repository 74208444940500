import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AiService } from '@shared/services/http/ai-service/ai.service';
import { AssetService } from '@shared/services/http/asset-service/asset.service';
import { CombinerService } from '@shared/services/http/combiner-service/combiner.service';
import { EditorModeService } from '@shared/services/http/editor-mode-service/editor-mode.service';
import { SlideService } from '@shared/services/http/slide-service/slide.service';
import { StElementService } from '@shared/services/http/stElement-service/st-element.service';
import { StoryService } from '@shared/services/http/story-service/story.service';
import { TouchpointService } from '@shared/services/http/touchpoint-service/touchpoint.service';
import { setEditorMode } from '../actions/editor.actions';
import {
  addLinkTouchpoint,
  addLinkTouchpointFailure,
  addLinkTouchpointSucces,
  addSlide,
  addSlideFailure,
  addSlideSucces,
  combineSlide,
  combineSlideFailure,
  combineSlideSucces,
  loadPreviewStory,
  loadPreviewStoryFailure,
  loadPreviewStorySucces,
  loadStory,
  loadStoryFailure,
  loadStorySuccess,
  removeLinkTouchpoint,
  removeLinkTouchpointSucces,
  removeSlide,
  removeSlideFailure,
  removeSlideSucces,
  saveBackgroundStElement,
  saveBackgroundStElementSucces,
  updateSlide,
  updateSlideFailure,
  updateSlideSucces,
  updateStElement,
  updateStElementFailure,
  updateStElementSucces,
  updateTouchpoint,
  updateTouchpointFailure,
  updateTouchpointParent,
  updateTouchpointParentFailure,
  updateTouchpointParentSucces,
  updateTouchpointSucces,
  updateStory,
  updateStoryFailure,
  updateStorySuccess,
  updateStoryModel,
  updateStoryModelFailure,
  updateStoryModelSuccess,
  updateTouchpointObject,
  updateTouchpointObjectSuccess,
  updateTouchpointTag,
  updateTouchpointObjectFailure,
  updateTouchpointTagSuccess,
  updateTouchpointTagFailure,
  createTouchPointsForMode,
  createTouchPointsForModeSuccess,
  createTouchPointsForModeFailure,
  changeSlideTemplate,
  changeSlideTemplateFailure,
  saveStoryAsset,
  saveStoryAssetSucces,
  saveStoryAssetFailure,
  removeStoryAsset,
  removeStoryAssetSucces,
  removeStoryAssetFailure,
  updateNotes,
  updateNotesSucces,
  updateNotesFailure,
} from '../actions/story.actions';
import { ChangeSlideTemplateDto } from '@shared/dto/changeSlideTemplate.dto';
import { NotesService } from '@shared/services/http/notes-service/notes.service';

@Injectable({
  providedIn: 'root',
})
export class StoryEffects {
  public loadStory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStory),
      mergeMap(action =>
        this.storyService.getNormalizedStory(action.storyId).pipe(
          mergeMap(data => [
            loadStorySuccess({
              story: data.story,
              slides: data.slides,
              touchpoints: data.touchpoints,
              notes: data.notes,
              template: data.template,
              assets: data.assets,
            }),
          ]),
          catchError(error => of(loadStoryFailure(error))),
        ),
      ),
    ),
  );

  public updateTouchpointParent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTouchpointParent),
      mergeMap(action =>
        this.touchpointService
          .updateTouchpointTree({
            slideId: action.slideId,
            oldDarentSlideId: action.oldDarentSlideId,
            newParentId: action.newParentId,
          })
          .pipe(
            map(() =>
              updateTouchpointParentSucces({
                slideId: action.slideId,
                oldDarentSlideId: action.oldDarentSlideId,
                newParentId: action.newParentId,
              }),
            ),
            catchError(error => of(updateTouchpointParentFailure(error))),
          ),
      ),
    ),
  );

  public updateTouchpoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTouchpoint),
      mergeMap(action =>
        this.touchpointService.update(action.key, action.filter).pipe(
          map(data => updateTouchpointSucces({ touchpoint: data })),
          catchError(error => of(updateTouchpointFailure(error))),
        ),
      ),
    ),
  );

  public addSlide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSlide),
      mergeMap(action =>
        this.slideService.addSlide(action.addSlideDto).pipe(
          map(data => addSlideSucces({ slide: data.slide, touchpoints: data.touchpoints })),
          catchError(error => of(addSlideFailure(error))),
        ),
      ),
    ),
  );

  public removeSlide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeSlide),
      mergeMap(action =>
        this.slideService.removeOneSlideInTree({ slideId: action.slideId, parentSlideId: action.parentSlideId }).pipe(
          map(data =>
            removeSlideSucces({
              removedSlideId: data.slideId,
              removedTouchpoints: data.removedTps,
              changedTouchpoints: data.changedTps,
            }),
          ),
          catchError(error => of(removeSlideFailure(error))),
        ),
      ),
    ),
  );

  public updateSlide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSlide),
      mergeMap(action =>
        this.slideService.update(action.key, action.filter).pipe(
          map(slide => {
            if (action.combine === undefined || action.combine === true) {
              return combineSlide({ slideId: slide.id });
            } else {
              return updateSlideSucces({ key: action.key, filter: action.filter });
            }
          }),
          catchError(error => of(updateSlideFailure(error))),
        ),
      ),
    ),
  );

  public addLinkTouchpoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addLinkTouchpoint),
      mergeMap(action =>
        this.touchpointService.addTouchpoint(action.addTouchpointDto).pipe(
          map(data => addLinkTouchpointSucces({ touchpoints: data })),
          catchError(error => of(addLinkTouchpointFailure(error))),
        ),
      ),
    ),
  );

  public removeLinkTouchpoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeLinkTouchpoint),
      mergeMap(action =>
        this.touchpointService.removeLink(action.touchpointId).pipe(
          map(data => removeLinkTouchpointSucces({ touchpointId: data })),
          catchError(error => of(removeLinkTouchpointSucces(error))),
        ),
      ),
    ),
  );

  public saveStoryAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveStoryAsset),
      mergeMap(action =>
        this.assetService.saveStoryAsset(action.saveAssetDto).pipe(
          map(asset => saveStoryAssetSucces({ asset })),
          catchError(error => of(saveStoryAssetFailure(error))),
        ),
      ),
    ),
  );

  public removeStoryAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeStoryAsset),
      mergeMap(action =>
        this.assetService.deleteStoryAsset(action.storyId, action.assetId).pipe(
          mergeMap(() => [
            removeStoryAssetSucces({ assetId: action.assetId }),
            // TODO only reload slides from combiner service -> assetIds of stElements could have been set to null
            // Only reload slides that were affected
            loadStory({ storyId: action.storyId }),
          ]),
          catchError(error => of(removeStoryAssetFailure(error))),
        ),
      ),
    ),
  );

  public updateStElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStElement),
      mergeMap(action =>
        this.stElementService.updateStElement(action.dto).pipe(
          mergeMap(data => {
            const mergeArray = [];
            mergeArray.push(updateStElementSucces({ stElement: data }));
            if (action.combine === undefined || action.combine === true) {
              mergeArray.push(combineSlide({ slideId: data.slideId }));
            }
            return mergeArray;
          }),
          catchError(error => of(updateStElementFailure(error))),
        ),
      ),
    ),
  );

  public saveBackgroundStElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveBackgroundStElement),
      mergeMap(action =>
        this.stElementService.saveBackgroundStElement(action.dto).pipe(
          mergeMap(data => [saveBackgroundStElementSucces({ slide: data })]),
          catchError(error => of(updateStElementFailure(error))),
        ),
      ),
    ),
  );

  public combineSlide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(combineSlide),
      mergeMap(action =>
        this.slideService.getcombinedSlide(action.slideId).pipe(
          map(data => combineSlideSucces({ slide: data })),
          catchError(error => of(combineSlideFailure(error))),
        ),
      ),
    ),
  );

  public loadPreviewStory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPreviewStory),
      mergeMap(action =>
        this.combinerService.findPreviewStory(action.previewStoryid).pipe(
          map(data => loadPreviewStorySucces({ previewStory: data })),
          catchError(error => of(loadPreviewStoryFailure(error))),
        ),
      ),
    ),
  );

  public updateStory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStory),
      mergeMap(action =>
        this.storyService.update(action.key, action.filter).pipe(
          map(() => updateStorySuccess({ filter: action.filter })),
          catchError(error => of(updateStoryFailure(error))),
        ),
      ),
    ),
  );

  public updateStoryModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStoryModel),
      mergeMap(action =>
        this.storyService.setModel(action.filter).pipe(
          // Multiple reducer actions
          mergeMap(() => [updateStoryModelSuccess(), updateStorySuccess({ filter: action.filter })]),
          catchError(error => of(updateStoryModelFailure(error), updateStoryFailure(error))),
        ),
      ),
    ),
  );

  public updateTouchPointObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTouchpointObject),
      mergeMap(action =>
        this.aiService.updateTouchPointObject(action.key, action.filter).pipe(
          map(() => updateTouchpointObjectSuccess({ key: action.key, filter: action.filter })),
          catchError(error => of(updateTouchpointObjectFailure(error))),
        ),
      ),
    ),
  );

  public updateTouchPointTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTouchpointTag),
      mergeMap(action =>
        this.aiService.updateTouchPointTag(action.key, action.filter).pipe(
          map(() => updateTouchpointTagSuccess({ key: action.key, filter: action.filter })),
          catchError(error => of(updateTouchpointTagFailure(error))),
        ),
      ),
    ),
  );

  public createTouchPointsForMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createTouchPointsForMode),
      mergeMap(action =>
        this.editorModeService.createTouchPointsForMode(action.storyId, action.editorMode).pipe(
          mergeMap(newModeTouchPoints => [
            createTouchPointsForModeSuccess({ touchpoints: newModeTouchPoints }),
            setEditorMode({ editorMode: action.editorMode }),
          ]),
          catchError(error => of(createTouchPointsForModeFailure(error))),
        ),
      ),
    ),
  );

  public changeSlideTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeSlideTemplate),
      mergeMap(action =>
        this.slideService
          .changeSlideTemplate(new ChangeSlideTemplateDto(action.storyId, action.slideId, action.previewSlideId))
          .pipe(
            mergeMap(() => [combineSlide({ slideId: action.slideId })]),
            catchError(error => of(changeSlideTemplateFailure(error))),
          ),
      ),
    ),
  );

  public updateNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNotes),
      mergeMap(action =>
        this.notesService.update(action.key, action.filter).pipe(
          map(() => updateNotesSucces({ key: action.key, filter: action.filter })),
          catchError(error => of(updateNotesFailure(error))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private touchpointService: TouchpointService,
    private editorModeService: EditorModeService,
    private slideService: SlideService,
    private storyService: StoryService,
    private assetService: AssetService,
    private stElementService: StElementService,
    private combinerService: CombinerService,
    private aiService: AiService,
    private notesService: NotesService,
  ) {}
}
