import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorySocket extends Socket {
  constructor() {
    super({ url: `${environment.API_URL}/story`, options: {} });
  }
}
