import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TableElement } from '@shared/models/domain-models/editor/parser/elements/tableElement';
import { TableDataEditorComponent } from './table-data-editor/table-data-editor.component';
import { UpdateDto } from '@shared/dto/update.dto';
import { AppState } from '@shared/models/domain-models/appState';
import { Store } from '@ngrx/store';
import { updateStElement } from '@shared/store/actions/story.actions';
import { StTableElement } from '@shared/models/entities/stElements/stTableElement.entity';
import { StElementType } from '@shared/models/entities/stElements/stElementType';
import { setSelectedElement } from '@shared/store/actions/editor.actions';

@Component({
  selector: 'st-table-element',
  templateUrl: './table-element.component.html',
  styleUrls: ['./table-element.component.scss'],
})
export class TableElementComponent implements OnInit {
  @Input() public selectedElement: TableElement;
  public borderColor: string;
  public headerColor: string;
  public backgroundColor: string;
  public tableData = undefined;

  private dialogRef: MatDialogRef<TableDataEditorComponent>;
  private timeout: ReturnType<typeof setTimeout>;

  constructor(private store: Store<AppState>, public dialog: MatDialog, private toastr: ToastrService) {}

  public ngOnInit() {
    const table = this.selectedElement.stTableElement;
    this.borderColor = table.borderColor;
    this.headerColor = table.headerColor;
    this.backgroundColor = table.backgroundColor;
  }

  public changeColor(colorId, colorValue) {
    this[colorId] = colorValue;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const dto = new UpdateDto<StTableElement>(
        { slideId: this.selectedElement.stTableElement.slideId, slotId: this.selectedElement.stTableElement.slotId },
        { stElementType: StElementType.STTABLEELEMENT, [colorId]: this[colorId] },
      );
      this.store.dispatch(updateStElement({ dto }));
    }, 500);
  }

  public editDataDialog() {
    const table = this.selectedElement.stTableElement;
    this.dialogRef = this.dialog.open(TableDataEditorComponent, {
      data: { tableData: table.data },
    });

    this.dialogRef.afterClosed().subscribe(res => {
      // If data changes has been saved update database
      if (res) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          const dto = new UpdateDto<StTableElement>(
            {
              slideId: this.selectedElement.stTableElement.slideId,
              slotId: this.selectedElement.stTableElement.slotId,
            },
            { stElementType: StElementType.STTABLEELEMENT, data: res.data },
          );
          this.store.dispatch(updateStElement({ dto }));
        }, 100);
        this.toastr.success('Changes successfully saved!');
        this.store.dispatch(setSelectedElement({ element: undefined }));
      } else {
        this.store.dispatch(setSelectedElement({ element: undefined }));
      }
    });
  }
}
