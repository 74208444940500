import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoggedInUserState } from '@shared/store/reducers/loggedInUser.reducer';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { EnumFeatures } from '@storytemple/models';

@Injectable()
export class EditorGuard implements CanActivate {
  constructor(private store: Store<LoggedInUserState>, private router: Router) {}

  public async canActivate(): Promise<boolean> {
    const features = await this.store
      .pipe(
        select(store => store.user.features),
        take(1),
      )
      .toPromise();
    const hasEditorFeature = features.includes(EnumFeatures.EDITOR);
    return hasEditorFeature;
  }
}
