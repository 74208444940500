<div class="container">
  <p class="barName">{{ data.indeterminateBarName }}</p>
  <p class="counter">{{ indeterminateCounter }} of {{ indeterminateTotalCount }}</p>
  <mat-progress-bar
    *ngIf="!indeterminateBarIsDone; else doneIndeterminate"
    class="marginBottom"
    mode="indeterminate"
  ></mat-progress-bar>
  <ng-template #doneIndeterminate>
    <mat-progress-bar class="marginBottom" mode="determinate" value="100"></mat-progress-bar>
  </ng-template>
  <p class="barName">{{ data.bufferBarName }}</p>
  <p class="counter">{{ bufferCounter }} of {{ bufferTotalCount }}</p>
  <mat-progress-bar *ngIf="!bufferBarIsStarted" class="bufferBar" mode="determinate"></mat-progress-bar>
  <mat-progress-bar *ngIf="bufferBarIsStarted && !bufferBarIsDone" class="bufferBar" mode="buffer"></mat-progress-bar>
  <mat-progress-bar *ngIf="bufferBarIsDone" mode="determinate" value="100"></mat-progress-bar>
</div>
