<div class="url-menu">
  <mat-card-title>
    <mat-icon class="icon">link</mat-icon>
    <h1>Add URL Touchpoint</h1>

    <button class="close" mat-button (click)="closeDialog()">X</button>
  </mat-card-title>
  <div class="inner-card">
    <p class="style-header-start">Pick Style</p>
    <mat-grid-list cols="5">
      <mat-grid-tile style="cursor: pointer;" *ngFor="let tpStyle of tpStyles" (click)="setTpStyle(tpStyle.id)">
        <div
          [ngClass]="{ selected: tpStyle.id === selectedTpStyleId }"
          class="tpStyle"
          [innerHTML]="tpStyle.content | safeHTML"
        ></div>
      </mat-grid-tile>
    </mat-grid-list>
    <div style="font-size: 14px;">
      <p class="style-header-end">Link Type</p>
      <mat-radio-group [ngModel]="urlType" (change)="changeLinkType($event)">
        <mat-radio-button class="example-margin" value="web">Web</mat-radio-button>
        <mat-radio-button class="example-margin" value="mail">Mail</mat-radio-button>
      </mat-radio-group>
      <p class="style-header-end">Set Url</p>
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>Link Url</mat-label>
        <input matInput placeholder="app.storytemple.com" [formControl]="urlControl" required autocomplete="off" />
        <span *ngIf="urlType == 'web'" matPrefix>https://&nbsp;</span>
        <span *ngIf="urlType == 'mail'" matPrefix>mailto:&nbsp;</span>
        <mat-error *ngIf="urlControl.invalid">{{ getErrorMessage() }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <button
    mat-raised-button
    class="button-save"
    [disabled]="urlControl.invalid || selectedTpStyleId === undefined"
    color="primary"
    (click)="save()"
  >
    Save
  </button>
</div>
