<st-header></st-header>
<div class="tree-component" *ngIf="(story$ | async) as story; else storyDuplicating">
  <st-tree-story-panel
    [story]="story"
    [touchpoints]="touchpoints$ | async"
    [selectedSlide]="selectedSlide$ | async"
    [template]="template$ | async"
  ></st-tree-story-panel>
  <st-tree-overview
    [preview]="false"
    [story]="story"
    [template]="template$ | async"
    [selectedSlide]="selectedSlide$ | async"
  ></st-tree-overview>
</div>

<ng-template #storyDuplicating>
  <div class="tree-component-loading">
    <st-loading [text]="'Loading Tree'"></st-loading>
  </div>
</ng-template>