<div class="image-selector">
  <h1 mat-dialog-title>Change image</h1>
  <button class="close" mat-button (click)="onNoClick()">X</button>
  <div mat-dialog-actions class="image-selector__container">
    <div>
      <input hidden type="file" #uploader (change)="uploadImage($event)" />
    </div>
    <div class="image-selector__list">
      <st-add-button (click)="uploader.click()"></st-add-button>
      <ng-container *ngFor="let image of images.slice().reverse()">
        <div class="image-selector__list-item">
          <img
            (click)="selectImage(image)"
            [ngClass]="selectedImage === image ? 'selected' : ''"
            width="100px"
            height="100px"
            crossorigin="anonymous"
            [src]="image.imageUrl | safeUrl"
          />
          <svg viewBox="0 0 100 100" xml:space="preserve">
            <path
              d="M0,0v100h100V0H0z M69.89,25.05c0.08-0.1,0.16-0.18,0.26-0.25c1.57-1.36,3.55-1.98,5.48-1.87v0l0.09,0.01
                c1.94,0.13,3.84,0.99,5.24,2.55c1.42,1.59,2.07,3.61,1.96,5.58h0l-0.01,0.09c-0.13,1.91-0.96,3.77-2.47,5.16L48.7,74.97l0,0
                c-0.06,0.08-0.13,0.14-0.2,0.2c-1.49,1.31-3.36,1.95-5.23,1.91c-1.88-0.03-3.76-0.75-5.22-2.15l-18.61-17.9
                c-1.54-1.48-2.33-3.44-2.37-5.42c-0.04-1.98,0.68-3.97,2.15-5.51c1.48-1.54,3.44-2.33,5.42-2.37c1.98-0.04,3.97,0.67,5.51,2.15
                l13.29,12.78L69.89,25.05"
            />
          </svg>
          <button class="upload-button" (click)="removeImage(image)">
            <svg-icon src="assets/icons/remove.svg" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="image-selector__bottom">
    <button [disabled]="!selectedImage" mat-raised-button [mat-dialog-close]="selectedImage" color="primary">
      Insert image
    </button>
  </div>
</div>
