export const environment = {
  production: true,
  API_URL: 'https://api.storytemple.dev',
  appBackendServerUrl: 'https://be-iapp.storytemple.dev',
  appSocketServerUrl: 'https://socket-iapp.storytemple.dev',
  appAuthorisation: 'Basic c3RhVXNlcjpxeVtcdUwkKG1gNC9DVDk/',
  assetServicePath: '/asset/',
  authenticationServerUrl: 'https://auth.storytemple.dev',
  loginServiceUrl: 'https://login.storytemple.dev',
  vimeoService: 'https://vimeo.storytemple.dev',
  IAPP_URL: 'https://iapp.storytemple.dev/',
  secondScreenServerUrl: 'https://second-screen.storytemple.dev/api',
  GOOGLE_FONTS_KEY: 'AIzaSyAUKNbgc3S7cdGChKd4ljObqvVjJ0dprrw',
  GOOGLE_ANALYTICS_KEY: 'G-XC3BV3ML3P',
};
