<div *ngIf="selectedTouchpoint !== undefined" class="touchpoint-element">
  <div style="display: flex;">
    <mat-card-title>Touchpoint editor</mat-card-title>
    <div style="display: flex; align-items: center;">
      <button
        *ngIf="selectedTouchpoint.type === 'url'"
        class="remove-button"
        matTooltip="Remove"
        mat-raised-button
        (click)="removeUrlTp()"
        color="warn"
      >
        <svg-icon [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" src="assets/icons/remove.svg"></svg-icon>
      </button>
    </div>
  </div>
  <mat-card-content>
    <div *ngIf="this.selectedTouchpoint.type === 1">
      <p class="title">Link to Url</p>
      <mat-form-field class="example-full-width">
        <input matInput value="{{ this.selectedTouchpoint.link }}" (keyup)="updateTouchpointLink($event)" />
      </mat-form-field>
    </div>
    <mat-checkbox [checked]="selectedTouchpoint.isInvisible" (change)="tpInvisible($event)" color="primary"
      >Invisible</mat-checkbox
    >
    <div *ngIf="canChangeAspectRatio">
      <p class="title">Width</p>
      <div style="display: flex;">
        <mat-slider
          color="primary"
          thumbLabel
          (change)="changeWidth($event.value)"
          [displayWith]="formatLabel"
          [value]="selectedTouchpoint.width"
          min="1"
          max="100"
        ></mat-slider>
        <mat-form-field style="margin-left: 10px; width: 60px;">
          <mat-label>Value</mat-label>
          <input
            matInput
            [(ngModel)]="selectedTouchpoint.width"
            (ngModelChange)="changeWidth($event)"
            type="number"
            min="1"
            max="100"
          />
        </mat-form-field>
      </div>
      <p class="title">Height</p>
      <div style="display: flex;">
        <mat-slider
          color="primary"
          thumbLabel
          (change)="changeHeight($event.value)"
          [displayWith]="formatLabel"
          [value]="selectedTouchpoint.height"
          min="1"
          max="100"
        ></mat-slider>
        <mat-form-field style="margin-left: 10px; width: 60px;">
          <mat-label>Value</mat-label>
          <input
            matInput
            [(ngModel)]="selectedTouchpoint.height"
            (ngModelChange)="changeHeight($event)"
            type="number"
            min="1"
            max="100"
          />
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!canChangeAspectRatio">
      <p class="title">Size</p>
      <div style="display: flex;">
        <mat-slider
          color="primary"
          thumbLabel
          (change)="changeSize($event.value)"
          (input)="changeSizeToAspect($event)"
          [displayWith]="formatLabel"
          [value]="selectedTouchpoint.height"
          min="1"
          max="100"
        ></mat-slider>
        <mat-form-field style="margin-left: 10px; width: 60px;">
          <mat-label>Value</mat-label>
          <input
            matInput
            [(ngModel)]="selectedTouchpoint.height"
            (ngModelChange)="changeSize($event)"
            type="number"
            min="1"
            max="100"
          />
        </mat-form-field>
      </div>
    </div>

    <mat-form-field>
      <label>Touchpoint Style</label>
      <mat-select [value]="selectedTouchpoint.style" (selectionChange)="setNewTouchpointStyle($event)">
        <mat-option *ngFor="let touchpointStyle of template.touchpointStyles" [value]="touchpointStyle"
          >{{ touchpointStyle.name }}{{ touchpointStyle.default ? ' (default)' : '' }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <br />
    <mat-form-field>
      <mat-label>Touchpoint Title</mat-label>
      <input matInput (input)="changeTitle($event.target.value)" value="{{ touchpointTitle }}" />
    </mat-form-field>
    <span>{{ touchpointTitle }}</span>
    <br />
    <mat-form-field *ngIf="selectedTouchpoint.type === 'url'">
      <mat-label>Touchpoint Url</mat-label>
      <input matInput disabled value="{{ selectedTouchpoint.link }}" autocomplete="off" />
    </mat-form-field>

    <div *ngIf="editorMode === editorModeTypes.wt3 && showTouchpointObjectEditor">
      <mat-card-title style="font-size: 20px;">Touchpoint Objects</mat-card-title>
      <mat-form-field *ngFor="let touchObject of selectedTouchpoint.touchpointObjects" style="display: -webkit-box">
        <label>Attach Object to slide {{ touchObject.nextSlideId }}</label>
        <mat-select
          [value]="setTouchpointObject(touchObject)"
          (selectionChange)="setNewTouchpointObject($event, touchObject)"
        >
          <mat-option *ngFor="let obj of modelObjects" [value]="obj">
            {{ obj.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="editorMode === editorModeTypes.wt2 && showTagsEditor">
      <mat-card-title style="font-size: 20px;">Touchpoint Tags</mat-card-title>
      <mat-form-field *ngFor="let touchTag of selectedTouchpoint.touchpointTags" style="display: -webkit-box">
        <label>Attach Tag to slide {{ touchTag.nextSlideId }}</label>
        <mat-select [value]="setTouchpointTag(touchTag)" (selectionChange)="setNewTouchpointTag($event, touchTag)">
          <mat-option *ngFor="let tag of rfidTags" [value]="tag">
            {{ tag.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-card-content>
</div>
