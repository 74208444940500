import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceService } from '@shared/services/http/device-service/device.service';
import { Device } from '@shared/models/entities/device';
import { AnalyticsService } from '@shared/services/local/analytics/analytics.service';

@Component({
  selector: 'st-device-control',
  templateUrl: './device-control.component.html',
  styleUrls: ['./device-control.component.scss'],
})
export class DeviceControlComponent implements OnInit {
  public device: Device;

  constructor(
    public dialogRef: MatDialogRef<DeviceControlComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public deviceService: DeviceService,
    private snackBar: MatSnackBar,
    private analyticsService: AnalyticsService,
  ) {}

  public async ngOnInit() {
    this.device = await this.deviceService.findById(this.data.deviceId).toPromise();
    // this.analyticsService.publish(true, this.data.storyId);
    this.snackBar.open('Story gestart op ' + this.device.name, 'Close', {
      duration: 5000,
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
