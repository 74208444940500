import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from 'src/app/shared/models/domain-models/appState';
import { EditorMode } from 'src/app/shared/models/entities/editor-mode';
import { Slide } from 'src/app/shared/models/entities/slide';
import { createTouchPointsForMode, loadStory, updateSlideSucces } from 'src/app/shared/store/actions/story.actions';
import {
  getSlide,
  getStory,
  getTouchpoints,
  hasTouchpointsForMode,
} from 'src/app/shared/store/selectors/story.selector';
import { Story } from '../../shared/models/entities/story';
import { Touchpoint } from '../../shared/models/entities/touchpoint';
import { setEditorMode, setSelectedSlide } from 'src/app/shared/store/actions/editor.actions';
import { skip, take } from 'rxjs/operators';
import { getSelectedSlide } from 'src/app/shared/store/selectors/editor.selector';
import { SlideType } from '@shared/models/entities/slideType';
import { StoryType } from '@shared/models/entities/storyType';
import { EditorService } from './services/editor.service';
import { EnumFeatures } from '@storytemple/models';

@Component({
  selector: 'st-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  // tslint:disable-next-line
  encapsulation: ViewEncapsulation.None,
})
export class EditorComponent implements OnInit, OnDestroy {
  public editorMode$: Observable<EditorMode>;
  public selectedSlide$: Observable<Slide>;
  public slides$: Observable<Slide[]>;
  public touchpoints$: Observable<Touchpoint[]>;
  public story$: Observable<Story>;
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    public toaster: ToastrService,
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private editorService: EditorService,
  ) {}

  public async ngOnInit() {
    const storyId = Number(this.route.snapshot.paramMap.get('id'));
    this.store.dispatch(loadStory({ storyId }));

    this.editorMode$ = this.store.select(s => s.editor.editorMode);
    this.story$ = this.store.pipe(
      select(getStory),
      skip(1),
    );

    const st = await this.story$.pipe(take(1)).toPromise();
    if (st.storyType === StoryType.PREVIEW) {
      // Check whether user is templateManager
      const userFeatures = await this.store
        .pipe(
          select(store => store.loggedInUser.user.features),
          take(1),
        )
        .toPromise();
      if (!userFeatures.includes(EnumFeatures.TEMPLATEMANAGER)) {
        this.router.navigate(['/dashboard']);
        this.toastr.warning('You do not have access to this resource.');
      }
    }

    this.subscriptions.push(
      this.store.select(getSelectedSlide).subscribe(async slideId => {
        if (slideId !== undefined) {
          // Save state of currentSlide in NGRX on slideChange
          if (this.selectedSlide$) {
            const currentSlide = await this.selectedSlide$.pipe(take(1)).toPromise();
            if (currentSlide) {
              this.store.dispatch(
                updateSlideSucces({
                  key: { id: currentSlide.id },
                  filter: { content: this.editorService.slideDom.documentElement.outerHTML },
                }),
              );
            }
          }
          // Fetch new Slide
          const slideObservable = this.store.select(getSlide, slideId);
          const slide = await slideObservable.pipe(take(1)).toPromise();

          if (slide !== undefined) {
            this.selectedSlide$ = slideObservable;
            this.editorService.setSlide(slide);
          } else {
            const rootSlideId = st.slides.find(sl => sl.slideType === SlideType.ROOT).id;
            this.store.dispatch(setSelectedSlide({ slideId: rootSlideId }));
          }
        } else {
          const rootSlideId = st.slides.find(sl => sl.slideType === SlideType.ROOT).id;
          this.store.dispatch(setSelectedSlide({ slideId: rootSlideId }));
        }
        this.touchpoints$ = this.store.select(getTouchpoints, slideId);
      }),
    );
    this.slides$ = this.store.select(store => store.story.slides);
  }

  public async ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());

    const currentSlide = await this.selectedSlide$.pipe(take(1)).toPromise();
    if (currentSlide) {
      this.store.dispatch(
        updateSlideSucces({
          key: { id: currentSlide.id },
          filter: { content: this.editorService.slideDom.documentElement.outerHTML },
        }),
      );
    }
  }

  public async changeEditorMode(editorMode: EditorMode) {
    const storyId = Number(this.route.snapshot.paramMap.get('id'));
    const hasTpForMode = await this.store
      .select(hasTouchpointsForMode, editorMode)
      .pipe(take(1))
      .toPromise();
    if (!hasTpForMode) {
      // Also dispatches setEditormode on success
      this.store.dispatch(createTouchPointsForMode({ storyId, editorMode }));
    } else {
      this.store.dispatch(setEditorMode({ editorMode }));
    }
  }
}
