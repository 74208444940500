import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPageVisibilityService } from 'angular-page-visibility';
import { AnalyticsService } from './shared/services/local/analytics/analytics.service';
import { AuthenticationService } from './shared/services/local/authentication-service/authentication.service';
import { install } from 'ga-gtag';
import { environment } from '../environments/environment';
@Component({
  selector: 'st-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private angularPageVisibilityService: AngularPageVisibilityService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private analyticsService: AnalyticsService,
  ) {}

  public ngOnInit() {
    install(environment.GOOGLE_ANALYTICS_KEY, { send_page_view: false });
  }

  private routeEnum(url: string) {
    switch (true) {
      case /^$/.exec(url) !== null:
        return '';
      case /^(\/{0,1})editor\/\d*(\/{0,1})$/.exec(url) !== null:
        return 'editor/storyID';
      case /^(\/{0,1})editor\/\d*\/tree(\/{0,1})$/.exec(url) !== null:
        return 'editor/storyID/tree';
      case /^(\/{0,1})editor\/\d*\/\d*(\/{0,1})$/.exec(url) !== null:
        return 'editor/storyID/slideID';
      case /^(\/{0,1})dashboard(\/{0,1})$/.exec(url) !== null:
        return 'dashboard';
      case /^(\/{0,1})editor\/\d*\/\d*(\/{0,1})$/.exec(url) !== null:
        return 'story';
      case /^(\/{0,1})story\/in-presenter-view\/\d*\/\d*(\/{0,1})$/.exec(url) !== null:
        return 'story/in-presenter-view/storyID/slideID';
      case /^(\/{0,1})story\/\d*\/\d*(\/{0,1})$/.exec(url) !== null:
        return 'story/storyID/slideID';
      case /^(\/{0,1})story\/\d*(\/{0,1})$/.exec(url) !== null:
        return 'story/storyID';
      default:
        return url;
    }
  }
}
