import { Component, OnInit, EventEmitter, Output, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EditorService } from '@pages/editor/services/editor.service';

@Component({
  selector: 'st-slider-element',
  templateUrl: './slider-element.component.html',
  styleUrls: ['./slider-element.component.scss'],
})
export class SliderElementComponent implements OnInit, OnChanges {
  @Output() public save = new EventEmitter<boolean>();

  public sliderSlideForm: FormGroup;

  public svgDOM: SVGSVGElement;
  public question: HTMLElement;
  public range;

  constructor(fb: FormBuilder, private editorServices: EditorService) {
    this.sliderSlideForm = fb.group({
      radioButtons: [],
      seconds: [],
    });
  }

  public ngOnInit() {
    this.question = this.editorServices.slideDom.querySelector('#question1');
    this.svgDOM = this.editorServices.slideDom.querySelector('svg');
    this.range = this.svgDOM.dataset.range;
    this.sliderSlideForm
      .get('seconds')
      .setValue(Number(this.question.getAttribute('timetoanswer')) / 1000, { onlySelf: true });
  }

  public ngOnChanges(): void {
    this.sliderSlideForm.get('radioButtons').valueChanges.subscribe(val => {
      this.svgDOM.setAttribute('data-range', val);
      this.save.emit(true);
    });

    this.sliderSlideForm.get('seconds').valueChanges.subscribe(val => {
      // timeToAnswer is set to miliseconds
      this.question.setAttribute('timetoanswer', (val * 1000) as unknown as string);
      this.save.emit(true);
    });
  }
}
