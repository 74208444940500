import { createSelector } from '@ngrx/store';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { StoryState } from '../reducers/story.reducer';

import cloneDeep from 'lodash.clonedeep';
import { EditorMode } from '../../models/entities/editor-mode';
import { SlideType } from '../../models/entities/slideType';

export const selectStoryState = (state: AppState) => state.story;

export const getStory = createSelector(
  selectStoryState,
  (state: StoryState) => {
    if (state.story) {
      const a = { ...state.story, slides: [...state.slides] };
      a.slides = state.slides.map(s => ({
        ...s,
        touchpoints: state.touchpoints.filter(tp => tp.parentSlideId === s.id),
      }));
      return a;
    }
  },
);

export const getSlide = createSelector(
  selectStoryState,
  (state: StoryState, slideId: number) => {
    if (state.slides) {
      return state.slides.find(s => s.id === slideId);
    }
  },
);

//* Returns the root slide
export const getRootSlide = createSelector(
  selectStoryState,
  (state: StoryState) => {
    if (state.slides) {
      return cloneDeep(state.slides.find(s => s.slideType === SlideType.ROOT));
    }
  },
);

export const getTouchpoints = createSelector(
  selectStoryState,
  (state: StoryState, slideId: number) => {
    if (state.touchpoints) {
      return state.touchpoints
        .filter(tp => tp.parentSlideId === slideId)
        .map(ftp => {
          const ftpc = cloneDeep(ftp);
          ftpc.style = state.template.touchpointStyles.find(tps => tps.id === ftp.styleId);
          return ftpc;
        });
    }
  },
);

export const getTouchpoint = createSelector(
  selectStoryState,
  (state: StoryState, touchpointId: number) => {
    if (state.touchpoints.length > 0 && touchpointId) {
      const tp = cloneDeep(state.touchpoints.find(s => s.id === touchpointId));
      tp.style = state.template.touchpointStyles.find(tps => tps.id === tp.styleId);
      return tp;
    } else {
      return undefined;
    }
  },
);

export const hasTouchpointsForMode = createSelector(
  selectStoryState,
  (state: StoryState, editorMode: EditorMode) =>
    state.touchpoints.some(tp => tp.deviceType.toString() === editorMode.toString()),
);

//* Returns the root slide
export const getNotes = createSelector(
  selectStoryState,
  (state: StoryState, notesId: number) => state.notes.find(n => n.id === notesId),
);
