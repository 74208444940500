import { Component, OnInit, Inject } from '@angular/core';
import { Device } from '@shared/models/entities/device';
import { DeviceService } from '@shared/services/http/device-service/device.service';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceControlComponent } from './device-control/device-control.component';
import { CurrentStoryService } from '@shared/services/local/current-story/current-story.service';

@Component({
  selector: 'st-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.scss'],
})
export class PublishComponent implements OnInit {
  public devices: Device[];
  public storyId: number;
  public selectedDeviceId = new FormControl('');
  public deviceSelected = false;

  constructor(
    public dialogRef: MatDialogRef<PublishComponent>,
    public dialog: MatDialog,
    public deviceService: DeviceService,
    private currentStoryService: CurrentStoryService,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
  ) {}

  public ngOnInit() {
    this.storyId = this.dialogData.storyId;
    this.deviceService.getAll().subscribe((res) => {
      this.devices = res;
    });
  }

  public inputChanges() {
    this.deviceSelected = true;
  }

  public publishOnDevice() {
    const rootSlideId = this.currentStoryService.rootSlide
      ? this.currentStoryService.rootSlide.id
      : this.dialogData.rootSlideId;
    this.deviceService.cast(this.storyId, rootSlideId, Number(this.selectedDeviceId.value)).subscribe(() => {
      this.dialogRef.close();
      this.dialog.open(DeviceControlComponent, {
        width: '600px',
        data: { deviceId: Number(this.selectedDeviceId.value), storyId: this.storyId },
      });
    });
  }
}
