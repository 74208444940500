import { Action, createReducer, on } from '@ngrx/store';
import * as SlideActions from '../actions/editor.actions';
import { Element } from '@shared/models/domain-models/editor/parser/element';
import { EditorMode } from '@shared/models/entities/editor-mode';

export interface EditorState {
  selectedSlide: number;
  slideDom: Document;
  selectedElement: Element;
  selectedTouchpointId: number;
  editorMode: EditorMode;
}

const initialState: EditorState = {
  selectedSlide: undefined,
  slideDom: undefined,
  selectedElement: undefined,
  selectedTouchpointId: undefined,
  editorMode: EditorMode.web,
};

export const editorReducer = createReducer(
  initialState,
  on(SlideActions.setSelectedSlide, (state, { slideId }) => ({
    ...state,
    selectedSlide: slideId,
    selectedElement: undefined,
    selectedTouchpointId: undefined,
  })),

  on(SlideActions.setSelectedElement, (state, { element }) => ({
    ...state,
    selectedTouchpointId: undefined,
    selectedElement: element,
  })),

  on(SlideActions.setSelectedTouchpoint, (state, { touchpointId }) => ({
    ...state,
    selectedElement: undefined,
    selectedTouchpointId: touchpointId,
  })),

  on(SlideActions.setEditorMode, (state, { editorMode }) => ({
    ...state,
    selectedTouchpointId: undefined,
    editorMode,
  })),
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function editorReducerFunction(state: EditorState | undefined, action: Action) {
  return editorReducer(state, action);
}
