import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PublishComponent } from '../publish/publish.component';
import { RouterModule, Router } from '@angular/router';
import { CurrentStoryService } from '@shared/services/local/current-story/current-story.service';
import { AuthenticationService } from '@shared/services/local/authentication-service/authentication.service';
import { environment } from 'src/environments/environment';
import { MatMenu } from '@angular/material/menu';
import { EditorMode } from '@shared/models/entities/editor-mode';
import { select, Store } from '@ngrx/store';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { ShareMenuComponent } from '../share-menu/share-menu.component';
import { Observable, Subscription } from 'rxjs';
import { setEditorMode } from '../../store/actions/editor.actions';
import { Story } from '../../models/entities/story';
import { skipWhile, take } from 'rxjs/operators';
import { getSelectedSlide } from '../../store/selectors/editor.selector';

@Component({
  selector: 'st-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() public matMenu: MatMenu;
  public openPublishMenu = false;
  public isEditor: boolean;
  public isDashboard: boolean;
  public authenticationFrontEndURL = environment.loginServiceUrl;
  public editorMode$: Observable<EditorMode>;
  public story$: Observable<Story>;
  public story: Story;
  public isLightmode = true;
  private dialogRef: MatDialogRef<ShareMenuComponent>;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    public router: RouterModule,
    private currentStoryService: CurrentStoryService,
    private route: Router,
    private authenticationService: AuthenticationService,
    private store: Store<AppState>,
  ) {}

  public ngOnInit() {
    document.body.classList.add('light-theme');
    // if (!localStorage.getItem('lightmode')) {
    //   localStorage.setItem('lightmode', window.matchMedia('(prefers-color-scheme: light)').matches.toString());
    // }
    // this.isLightmode = localStorage.getItem('lightmode') === 'true' ? true : false;

    // if (this.isLightmode) {
    //   document.body.classList.add('light-theme');
    // } else {
    //   document.body.classList.add('dark-theme');
    // }

    this.isEditor = this.route.url.includes('editor');
    this.isDashboard = this.route.url.includes('dashboard') || this.route.url === '/';
    this.editorMode$ = this.store.select(s => s.editor.editorMode);
    this.story$ = this.store.pipe(
      select(s => s.story.story),
      skipWhile(val => val === undefined),
    );
    this.subscriptions.push(this.story$.subscribe(story => (this.story = story)));
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get storyId(): number {
    return this.currentStoryService.currentStoryId;
  }

  get rootSlideId(): number {
    const slide = this.currentStoryService.rootSlide;
    if (slide) {
      return slide.id;
    }
  }

  public toggleDarkmode() {
    // Click function works before the toggle so the if statement is reversed
    if (this.isLightmode) {
      document.body.classList.remove('light-theme');
      document.body.classList.add('dark-theme');
      localStorage.setItem('lightmode', 'false');
    } else {
      document.body.classList.remove('dark-theme');
      document.body.classList.add('light-theme');
      localStorage.setItem('lightmode', 'true');
    }
  }

  public async logout() {
    await this.authenticationService.logout();
  }

  public publish() {
    this.dialog.open(PublishComponent, {
      data: {
        storyId: this.story.id,
      },
      width: '600px',
    });
  }

  public toggleMenu() {
    this.openPublishMenu = !this.openPublishMenu;
  }

  public changeEditorMode(editorMode: EditorMode) {
    this.store.dispatch(setEditorMode({ editorMode }));
  }

  public async publishWeb() {
    if (this.isEditor) {
      const slideId = await this.store
        .select(getSelectedSlide)
        .pipe(take(1))
        .toPromise();
      this.route.navigate([`/story/${this.story.id}/${slideId}`]);
    } else {
      this.route.navigate([`/story/${this.story.id}`]);
    }
  }

  public share(): void {
    this.dialogRef = this.dialog.open(ShareMenuComponent, {
      width: '500px',
      height: '500px',
      data: { storyId: this.story.id },
    });
  }
}
