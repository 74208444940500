import { Injectable } from '@angular/core';
import { EditorMode } from '@shared/models/entities/editor-mode';
import { HttpClient } from '@angular/common/http';
import { Story } from '@shared/models/entities/story';
import { environment } from 'src/environments/environment';
import { Touchpoint } from '@shared/models/entities/touchpoint';

@Injectable({
  providedIn: 'root',
})
export class EditorModeService {
  private readonly URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public getTouchPointsForMode(storyId: number, mode: EditorMode) {
    return this.http.get<Story>(`${this.URL}/story/${storyId}/getTouchPointsForMode/${mode}`);
  }

  public createTouchPointsForMode(storyId: number, mode: EditorMode) {
    return this.http.post<Touchpoint[]>(`${this.URL}/story/createTouchPointsForMode`, { storyId, mode });
  }
}
