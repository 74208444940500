<div class="graph-element">
  <mat-card-title>Graph Editor</mat-card-title>
  <!-- Dropdown for changing graph types -->
  <mat-form-field>
    <label>Graph Type</label>
    <mat-select [value]="graphType" (selectionChange)="changeGraphType($event)">
      <mat-option *ngFor="let enum of graphTypeList | keyvalue" value="{{ enum.value }}">{{
        camelCaseToNormal(enum.value)
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <!-- Change titles -->
  <mat-form-field *ngIf="graphType !== 'radarChart'">
    <mat-label>Graph Title</mat-label>
    <input matInput (input)="changeTitle($event.target.id, $event.target.value)" id="title" value="{{ title }}" />
  </mat-form-field>
  <mat-form-field *ngIf="!excludeXTitleGraphType.includes(graphType)">
    <mat-label>X-axis Title</mat-label>
    <input
      matInput
      (input)="changeTitle($event.target.id, $event.target.value)"
      id="xAxisTitle"
      value="{{ xAxisTitle }}"
    />
  </mat-form-field>
  <mat-form-field *ngIf="!excludeYTitleGraphType.includes(graphType)">
    <mat-label>Y-axis Title</mat-label>
    <input
      matInput
      (input)="changeTitle($event.target.id, $event.target.value)"
      id="yAxisTitle"
      value="{{ yAxisTitle }}"
    />
  </mat-form-field>
  <!-- Change graph color -->
  <mat-form-field>
    <mat-label>Graph Color</mat-label>
    <input
      matInput
      minlength="4"
      maxlength="7"
      (input)="changeGraphColor($event.target.value)"
      value="{{ graphColor }}"
    />
    <mat-icon class="color-dot-inside-input" matSuffix [ngStyle]="{ color: graphColor }">brightness_1</mat-icon>
    <mat-hint>Applied for titles, axis lines and legend text</mat-hint>
  </mat-form-field>
  <!-- Change color scheme -->
  <h3 class="color-scheme-label">Color Scheme</h3>
  <mat-icon
    class="color-scheme-dot"
    *ngFor="let color of colorScheme; let position = index"
    [ngStyle]="{ color: color }"
    (click)="colorSchemeClickColor(position, color)"
  >
    brightness_1
  </mat-icon>
  <mat-icon class="color-scheme-dot add-color" (click)="colorSchemeChange('add')">add_circle_outline</mat-icon>
  <div *ngIf="colorSchemeEditStatus">
    <!-- Edit color input -->
    <mat-form-field class="update-color-input">
      <input matInput (input)="colorSchemeChange('edit', $event.target.value)" value="{{ selectedColor }}" />
      <mat-icon matSuffix class="color-dot-inside-input" [ngStyle]="{ color: selectedColor }">brightness_1</mat-icon>
    </mat-form-field>
    <!-- Edit color action buttons -->
    <button class="color-scheme-action-btn" mat-flat-button color="primary" (click)="colorSchemeChange('update')">
      <mat-icon>check</mat-icon>
    </button>
    <button class="color-scheme-action-btn" mat-flat-button (click)="colorSchemeChange('remove')">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <!-- Update data -->
  <button class="edit-graph-data-btn" mat-flat-button color="primary" (click)="editDataDialog()">
    Edit Graph Data
  </button>
</div>
