import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { AddSlideDto } from '@shared/dto/addSlide.dto';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { PreviewSlide } from '@shared/models/domain-models/previewStory/previewSlide';
import { PreviewStory } from '@shared/models/domain-models/previewStory/previewStory';
import { Slide } from '@shared/models/entities/slide';
import { SlideType } from '@shared/models/entities/slideType';
import { Story } from '@shared/models/entities/story';
import { TouchpointType } from '@shared/models/entities/touchpointType';
import { LinkModeService } from '@shared/services/local/link-mode/link-mode.service';
import { setSelectedSlide } from '@shared/store/actions/editor.actions';
import { addSlide, removeSlide } from '@shared/store/actions/story.actions';
import { SlideSelectorComponent } from '@shared/components/tree-overview/slide-selector/slide-selector.component';
import { Subscription } from 'rxjs';
import { LinkMode } from '@shared/models/domain-models/tree-structure/link-mode';

@Component({
  selector: 'st-slide-editor',
  templateUrl: './slide-editor.component.html',
  styleUrls: ['./slide-editor.component.scss'],
})
export class SlideEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public story: Story;
  @Input() public slideData: Slide;
  @Input() public parentData: Slide;
  @Input() public selectedSlideId: number;
  public previewStory: PreviewStory;
  private isSingleClick = true;
  private subscriptions: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private toaster: ToastrService,
    private router: Router,
    private store: Store<AppState>,
    private linkModeService: LinkModeService,
  ) {}
  public ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .select(store => store.story.previewStory)
        .subscribe(preview => {
          this.previewStory = preview;
        }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public ngOnChanges() {
    if (this.selectedSlideId === this.slideData.id) {
      this.setLinkBorder(this.slideData);
    }
  }

  public touchpointHasLink() {
    let linkActive = false;
    this.slideData.touchpoints.filter(touchpoint => {
      if (touchpoint.type === TouchpointType.LINK) {
        linkActive = true;
      }
    });
    return linkActive;
  }

  public addSlide(slide: Slide) {
    const DIALOG_REF = this.dialog.open(SlideSelectorComponent, {
      width: '1000px',
      height: '700px ',
      data: this.previewStory,
    });

    DIALOG_REF.afterClosed().subscribe((result: PreviewSlide) => {
      if (result) {
        const addSlideDto = new AddSlideDto(result.slideId, slide.id, this.story.id);
        this.store.dispatch(
          addSlide({
            addSlideDto,
          }),
        );
      }
    });
  }

  public removeSlide(slide: Slide) {
    if (slide.slideType !== SlideType.ROOT) {
      const DIALOG_REF = this.dialog.open(AlertComponent, {
        width: '340px',
        data: {
          title: 'Are you sure?',
          subtitle:
            'By deleting this slide you will also delete all the slides that it links to. This action can not be undone!',
        },
      });
      DIALOG_REF.afterClosed().subscribe(result => {
        if (result) {
          this.store.dispatch(removeSlide({ slideId: slide.id, parentSlideId: this.parentData.id }));
        }
      });
    }
  }

  public changeSlideTemplate(slide) {
    this.setSlide(slide);
    this.linkModeService.setLinkMode(LinkMode.PREVIEW);
  }

  public startLinkMode(slide: Slide) {
    this.setSlide(slide);
    this.linkModeService.setLinkMode(LinkMode.TP);
  }

  public setSlide(slide: Slide) {
    this.store.dispatch(setSelectedSlide({ slideId: slide.id }));
    requestAnimationFrame(() => {
      this.setLinkBorder(slide);
    });
  }

  public editSlideWithDblclick(event: Slide) {
    this.isSingleClick = false;
    this.linkModeService.setLinkMode(LinkMode.NONE);
    this.setSlide(event);
    this.router.navigate([`editor/${this.story.id}/${event.id}`], { replaceUrl: true });
  }

  public onSlideClick(event: Slide) {
    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        this.linkModeService.setLinkMode(LinkMode.NONE);
        this.setSlide(event);
      }
    }, 250);
  }

  //  TO-DO Set border of links to orange
  public setLinkBorder(treeSlide: Slide) {
    document.querySelectorAll('.linkBorder').forEach(e => e.classList.remove('linkBorder'));
    requestAnimationFrame(() => {
      treeSlide.touchpoints
        .filter(tp => tp.type === TouchpointType.LINK)
        .forEach(tp => {
          const slide = document.querySelector(`#slide-tree-${tp.nextSlideId}`);
          if (slide) {
            slide.classList.add('linkBorder');
          }
        });
    });
  }
}
