import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Story } from '@shared/models/entities/story';
import { loadStory } from '@shared/store/actions/story.actions';
import { getStory } from '@shared/store/selectors/story.selector';
import { Observable } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { EditorService } from '@pages/editor/services/editor.service';
import { Slide } from '@shared/models/entities/slide';
import { SlideType } from '@shared/models/entities/slideType';
import { TouchpointType } from '@shared/models/entities/touchpointType';


@Component({
  selector: 'st-story-pdf',
  templateUrl: './story-pdf.component.html',
  styleUrls: ['./story-pdf.component.scss']
})
export class StoryPdfComponent implements OnInit {
  public story$: Observable<Story>;
  public sortedSlides: Document[];
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private editorService: EditorService
    ) { }

  public async ngOnInit(){
    const storyId = Number(this.route.snapshot.paramMap.get('id'));
    this.store.dispatch(loadStory({ storyId }));
    this.story$ = this.store.pipe(select(getStory), skip(1));
    const story = await this.story$.pipe(take(1)).toPromise();
    this.sortedSlides = this.sortSlidesByRank(story).map((sl) => this.editorService.setSlide(sl));
    
  }

  private sortSlidesByRank(story: Story): Slide[]{
    const rootSlide = story.slides.find(sl => sl.slideType === SlideType.ROOT);
    const rankedSlides = this.rankSlides(rootSlide, story.slides, 0, []);
    return rankedSlides.sort((a,b) => a[1] - b[1]).map(tuple => tuple[0]);
  }

  private rankSlides(slide: Slide, slideList: Slide[], rank: number, rankedSlides: [Slide, number][]): [Slide, number][]{
    rankedSlides.push([slide, rank]);
    slide.touchpoints.filter(tp => tp.type !== TouchpointType.LINK && tp.type !== TouchpointType.URL).forEach(tp => {
      const nextSlide = slideList.find(sl => sl.id === tp.nextSlideId);
      this.rankSlides(nextSlide, slideList, rank+1, rankedSlides);
    })
    return rankedSlides;
  }


}
