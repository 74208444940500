<div [ngClass]="{ 'grid-overlay': isDragging === true }" class="touchpoint-container" #touchpointContainer>
  <div
    cdkDrag
    *ngFor="let touchpoint of touchPointsForMode"
    id="{{ touchpoint.id }}"
    class="touch-point"
    (mouseenter)="mouseOverTouchpoint(touchpoint)"
    (mouseleave)="mouseOutTouchpoint(touchpoint)"
    (dblclick)="doubleClick(touchpoint)"
    [ngStyle]="touchPointStyles(touchpoint)"
    [style.visibility]="show ? 'visible' : 'hidden'"
    (click)="editTouchpoint(touchpoint)"
    [ngClass]="{ 'tp-invisible': touchpoint.isInvisible === true }"
    (cdkDragStarted)="start()"
    (cdkDragEnded)="drop($event, touchpoint)"
    cdkDragBoundary=".story-slide"
    [innerHTML]="touchpoint.style.content | safeHTML"
    [attr.data-x]="touchpoint.x"
    [attr.data-y]="touchpoint.y"
    [attr.data-width]="touchpoint.width"
    [attr.data-height]="touchpoint.height"
  ></div>
</div>
