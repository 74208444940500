import { Directive, ElementRef, EventEmitter, Output, OnDestroy } from '@angular/core';

@Directive({
  // tslint:disable-next-line
  selector: '[appAppMutationObserver]',
})
export class AppMutationObserverDirective implements OnDestroy {
  @Output() public innerHtmlRendered = new EventEmitter();
  public _observer: MutationObserver;

  constructor(private el: ElementRef) {
    this._observer = new MutationObserver(mutations => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          this.innerHtmlRendered.emit();
        }
      });
    });
    this._observer.observe(this.el.nativeElement, { attributes: true, childList: true, characterData: true });
  }

  public ngOnDestroy() {
    if (this._observer) {
      this._observer.disconnect();
    }
  }
}
