import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { ToastrService } from 'ngx-toastr';
import { GraphType } from '@shared/models/entities/stElements/enums/graphType';
import { SerieStatus } from '@shared/models/entities/stElements/enums/serieStatus';

export interface GraphDataEditorElement {
  graphType: GraphType;
  graphData: object[];
  legend: string[];
  series: SerieStatus;
}
@Component({
  selector: 'st-graph-data-editor',
  templateUrl: 'graph-data-editor.component.html',
  styleUrls: ['./graph-data-editor.component.scss'],
})
export class GraphDataEditorComponent implements OnInit {
  public graphType: GraphType;
  public graphData: object[];
  public columns: string[];
  public legend: string[];
  public firstLegendValue: string; // Needed for Scatter/Bubble to save and add later
  public series: SerieStatus;
  public keyColumnNumericType: string[] = ['scatterChart', 'bubbleChart'];
  public singleSerieSupportedCharts: string[] = ['bubbleChart', 'funnel', 'pieChart', 'doughnutChart'];
  private timeout: ReturnType<typeof setTimeout>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GraphDataEditorElement,
    public dialogGraphDataEditor: MatDialogRef<GraphDataEditorComponent>,
    public dialogDeleteConfirmation: MatDialog,
    private toastr: ToastrService,
  ) {}

  public ngOnInit() {
    this.legend = this.data.legend;
    this.graphType = this.data.graphType;

    // If type is scatter/bubble remove first legend item, which is the "X-Values".
    // X-values are already the key.
    if (this.keyColumnNumericType.includes(this.graphType)) {
      this.firstLegendValue = this.legend.shift();
    }

    // Add 'key' column as default for the key values in the array
    this.columns = ['key'].concat(this.legend).concat('Actions');
    this.graphData = this.convertDataToTableStructure(this.data.graphData, this.legend);
  }

  public saveChanges() {
    this.graphData = this.convertTableToOriginalStructure(this.graphData, this.legend);

    // Add the first removed legend item back to the legend
    if (this.keyColumnNumericType.includes(this.graphType)) {
      this.legend = [this.firstLegendValue, ...this.legend];
    }

    this.dialogGraphDataEditor.close({ data: this.graphData, series: this.series, legend: this.legend });
  }

  public changeTableValue(objectIdx: number, keyLabel: string, value: any) {
    // Convert string number to number
    if (Number(value)) {
      value = +value;
    }

    this.graphData[objectIdx][keyLabel] = value;
  }

  public changeColumnValue(columnIdx: number, currentName: string, value: string) {
    if (this.legend.indexOf(value) > -1) {
      this.toastr.warning(`Column "${value}" already exists.`);
    } else {
      clearTimeout(this.timeout);

      // Set timeout before updating all table cells related to that column
      this.timeout = setTimeout(() => {
        const oldColumnName = currentName;
        const newColumnName = value;
        this.legend[columnIdx] = value;

        // Check every object and create new kay value pair with the renamed column delete old columns
        this.graphData.forEach(item => {
          item[newColumnName] = item[oldColumnName];
          delete item[oldColumnName];
        });

        // Update columns to show new legend names
        this.columns = ['key'].concat(this.legend).concat('Actions');
      }, 500);
    }
  }

  public addColumn() {
    // Add new column with a default name
    const newLegend = [...this.legend, `New Column ${this.legend.length + 1}`];
    this.legend = [...newLegend];

    // Create a new key value pair for each row
    this.graphData.forEach(item => {
      item[this.legend[this.legend.length - 1]] = 0;
    });

    // Replace current columns with new columns
    this.columns = ['key'].concat(this.legend).concat('Actions');
  }

  public removeColumn(column: string) {
    if (this.legend.length === 2 && [GraphType.BARCHARTLINE, GraphType.BUBBLE_CHART].includes(this.graphType)) {
      this.toastr.warning('This graph needs at least two columns.');
    } else {
      if (this.legend.length !== 1) {
        const dialogDeleteColumn = this.dialogDeleteConfirmation.open(AlertComponent, {
          width: '370px',
          data: `Do you want to delete this column "${column}" ?`,
        });

        dialogDeleteColumn.afterClosed().subscribe(confirmedToDelete => {
          if (confirmedToDelete) {
            // Remove legend from the array
            this.legend = this.legend.filter(l => l !== column);
            this.columns = ['key'].concat(this.legend).concat('Actions');

            const toBeDeletedColumn = column;
            // Remove each value of the deleted column in every object
            this.graphData.forEach(item => {
              delete item[toBeDeletedColumn];
            });
          }
        });
      } else {
        this.toastr.warning('Graph needs at least one column.');
      }
    }
  }

  public rowActions(type: string, deletedRow?: object) {
    if (type === 'add') {
      this.graphData = [...this.graphData, {}];
    } else {
      const dialogDelete = this.dialogDeleteConfirmation.open(AlertComponent, {
        width: '340px',
        data: 'Do you want to delete this row?',
      });

      dialogDelete.afterClosed().subscribe(confirmedToDelete => {
        if (confirmedToDelete) {
          this.graphData = this.graphData.filter(i => i !== deletedRow);
        }
      });
    }
  }

  /**
   * Convert array of data objects to the correct structure for Angular Material Table
   *
   * @param data Array with objects
   * @param series Data serie indication
   * @param legend Array with legend values/data serie names.
   * @returns New data array
   */
  public convertDataToTableStructure(data: any, legend: string[]): object[] {
    // If graph has single a data serie rename the value key to the data serie
    if (legend.length === 1) {
      data.forEach(obj => {
        obj[legend[0]] = obj.value;
        delete obj.value;
      });

      return data;
    }

    // If graph has multiple data series convert array values to key:pair values
    const dataCollection: object[] = [];

    for (let i = 0; i < Object.keys(data).length; i++) {
      const dataObject = {};

      for (let j = 0; j < legend.length; j++) {
        dataObject['key'] = data[i].key;
        dataObject[legend[j]] = data[i].value[j];
      }
      dataCollection.push(dataObject);
    }

    return dataCollection;
  }

  /**
   * Convert table structure back to original structure like in the database
   *
   * @param data Array with objects
   * @param graphType Type of the graph based on the enum
   * @param series Data serie indication
   * @param legend Array with legend values/data serie names.
   * @returns New data array
   */
  public convertTableToOriginalStructure(data: any, legend: string[]): object[] {
    if (legend.length === 1) {
      // Rename the single object keys to value
      data.forEach(obj => {
        obj['value'] = obj[legend[0]];
        delete obj[legend[0]];
      });

      this.series = SerieStatus.SINGLE;

      return data;
    } else {
      // If graph has multiple data series convert key:pair values back to array with values
      const dataCollection = [];
      for (let i = 0; i < Object.keys(data).length; i++) {
        const dataObject = {};
        const arrayWithSerieValues = [];

        dataObject['key'] = data[i]['key'];

        // Get all values from the data series and put it in one array
        for (let j = 0; j < legend.length; j++) {
          arrayWithSerieValues[j] = data[i][legend[j]];
        }

        // Assign array to value key
        dataObject['value'] = arrayWithSerieValues;
        dataCollection.push(dataObject);
      }

      this.series = SerieStatus.MULTI;

      return dataCollection;
    }
  }
}
