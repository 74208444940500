import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { EditorService } from '@pages/editor/services/editor.service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { ElementFactory } from '@shared/models/domain-models/editor/parser/ElementFactory';
import { EditorMode } from '@shared/models/entities/editor-mode';
import { Slide } from '@shared/models/entities/slide';
import { Touchpoint } from '@shared/models/entities/touchpoint';
import { SlideService } from '@shared/services/http/slide-service/slide.service';
import { setSelectedElement, setSelectedTouchpoint } from '@shared/store/actions/editor.actions';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UrlTpAdderComponent } from './components/url-tp-adder/url-tp-adder.component';
import { SlideInOutAnimation } from './notes-animation';
import { getNotes } from '@shared/store/selectors/story.selector';
import { Notes } from '@shared/models/entities/notes';

@Component({
  selector: 'st-slide-panel',
  templateUrl: './slide-panel.component.html',
  styleUrls: ['./slide-panel.component.scss'],
  animations: [SlideInOutAnimation],
  // tslint:disable-next-line
  encapsulation: ViewEncapsulation.None,
})
export class SlidePanelComponent implements OnChanges, OnInit {
  @Input() public slide: Slide;
  @Input() public slides: Slide[];
  @Input() public touchpoints: Touchpoint[];
  public editorMode$: Observable<EditorMode>;
  public allTouchpoints$: Observable<Touchpoint[]>;
  public notes$: Observable<Notes>;
  public animationState = 'small';
  public showTouchpoints = true;
  public videos: any[];
  public editorMode: EditorMode;
  public editorModes = EditorMode;

  constructor(
    public service: SlideService,
    private toastr: ToastrService,
    private store: Store<AppState>,
    public editorService: EditorService,
    private dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    this.editorMode$ = this.store.select(store => store.editor.editorMode);
    this.allTouchpoints$ = this.store.select(store => store.story.touchpoints);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['slide']) {
      if (changes['slide'].previousValue && changes['slide'].previousValue.id !== changes['slide'].currentValue.id) {
        this.notes$ = this.store.select(getNotes, this.slide.notesId);
      } else if (!changes['slide'].previousValue) {
        this.notes$ = this.store.select(getNotes, this.slide.notesId);
      }
    }
  }

  public async addUrlTouchpoint() {
    const template = await this.store
      .select(store => store.story.template)
      .pipe(take(1))
      .toPromise();
    this.dialog.open(UrlTpAdderComponent, {
      width: '500px',
      height: '550px',
      data: { tpStyles: template.touchpointStyles, slideId: this.slide.id },
    });
  }

  public toggleShowNotesField(divName: string) {
    if (divName === 'notesField') {
      this.animationState = this.animationState === 'small' ? 'large' : 'small';
    }
  }

  /**
   * svgClick emits clicked element to be edited.
   *
   * @param event - event generated by click on slide used to detirmine wich element is clicked
   */
  public svgClick(event) {
    this.store.dispatch(setSelectedTouchpoint({ touchpointId: undefined }));
    const elementFactory = new ElementFactory(this.editorService.slideDom);
    const element = elementFactory.createEditableElement(event, this.slide.stElements, this.slide.backgroundType);
    if (element === null) {
      this.toastr.warning('Selected element not editable');
    } else if (element !== undefined) {
      this.store.dispatch(setSelectedElement({ element }));
    }
  }

  public innerHtmlChanged() {
    this.videos = [].slice.call(document.querySelector('st-slide-panel').querySelectorAll('[id*="video"]'));
  }
}
