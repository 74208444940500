import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Model } from '@shared/models/entities/AI/model';
import { TouchPointObject } from '@shared/models/entities/touchPointObject';
import { UpdateDto } from '@shared/dto/update.dto';
import { RfidTag } from '@shared/models/entities/rfidTag';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  private readonly URL = environment.API_URL;

  // Can notify subscribers of modelId change in editor
  private model: Subject<number> = new Subject<number>();

  constructor(private http: HttpClient) {}

  public getModelSubject() {
    return this.model;
  }

  public setModelId(modelId: number) {
    this.model.next(modelId);
  }

  // TODO three calls
  public getModels(): Observable<any> {
    return this.http.get<any>(`${this.URL}/model/findForUser`);
  }

  public getAllModels(): Observable<Model[]> {
    return this.http.get<Model[]>(`${this.URL}/model/getAllModels`);
  }

  public getModelById(modelId: number): Observable<Model> {
    return this.http.get<Model>(`${this.URL}/model/getModel/${modelId}`);
  }

  public updateTouchPointObject(key: Partial<TouchPointObject>, filter: Partial<TouchPointObject>) {
    const dto = new UpdateDto(key, filter);
    return this.http.put<any>(`${this.URL}/touchpointObject/updateObject`, dto);
  }

  public updateTouchPointTag(key: Partial<TouchPointObject>, filter: Partial<TouchPointObject>) {
    const dto = new UpdateDto(key, filter);
    return this.http.put<any>(`${this.URL}/touchpointTag/updateTag`, dto);
  }

  public getOrganizationTags(): Observable<RfidTag[]> {
    return this.http.get<RfidTag[]>(`${this.URL}/touchpointTag/getOrganizationTags`);
  }
}
