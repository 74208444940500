<div class="quill-wrapper">
  <quill-editor
    class="content-editor"
    [placeholder]="''"
    [formats]="quillFormats"
    [styles]="{
      'min-height': '120px',
      'border-top': '1px solid black',
      'border-right': 'none',
      'border-bottom': 'none',
      'border-left': 'none'
    }"
    [formControl]="control"
    (onEditorCreated)="editorCreated($event)"
  >
    <div quill-editor-toolbar>
      <span matTooltip="Font size" class="ql-formats">
        <select class="ql-size">
          <option value="0.4em">4</option>
          <option value="0.6em">6</option>
          <option value="0.8em">8</option>
          <option value="1em" selected>10</option>
          <option value="1.2em">12</option>
          <option value="1.4em">14</option>
          <option value="1.6em">16</option>
        </select>
      </span>
      <!-- TODO Line height dropdown -->
      <span matTooltip="Line height" class="ql-formats">
        <select class="ql-line-height">
          <option value="1" selected>1</option>
          <option value="1.1">1.1</option>
          <option value="1.2">1.2</option>
          <option value="1.3">1.3</option>
          <option value="1.4">1.4</option>
          <option value="1.6">1.6</option>
          <option value="1.8">1.8</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>
      </span>
      <span class="ql-formats">
        <select class="ql-color">
          <!-- <option value="inherit"></option> -->
          <option value="#0b0b0b"></option>
          <option value="#434343"></option>
          <option value="#666666"></option>
          <option value="#999999"></option>
          <option value="#b7b7b7"></option>
          <option value="#cccccc"></option>
          <option value="#d9d9d9"></option>
          <option value="#efefef"></option>
          <option value="#f3f3f3"></option>
          <option value="#ffffff"></option>

          <option value="#980000"></option>
          <option value="#ff0000"></option>
          <option value="#ff9900"></option>
          <option value="#ffff00"></option>
          <option value="#00ff00"></option>
          <option value="#00ffff"></option>
          <option value="#4a86e8"></option>
          <option value="#0000ff"></option>
          <option value="#9900ff"></option>
          <option value="#ff00ff"></option>

          <option value="#e6b8af"></option>
          <option value="#f4cccc"></option>
          <option value="#fce5cd"></option>
          <option value="#fff2cc"></option>
          <option value="#d9ead3"></option>
          <option value="#d0e0e3"></option>
          <option value="#c9daf8"></option>
          <option value="#cfe2f3"></option>
          <option value="#d9d2e9"></option>
          <option value="#ead1dc"></option>

          <option value="#dd7e6b"></option>
          <option value="#ea9999"></option>
          <option value="#f9cb9c"></option>
          <option value="#ffe599"></option>
          <option value="#b6d7a8"></option>
          <option value="#a2c4c9"></option>
          <option value="#a4c2f4"></option>
          <option value="#9fc5e8"></option>
          <option value="#b4a7d6"></option>
          <option value="#d5a6bd"></option>

          <option value="#cc4125"></option>
          <option value="#e06666"></option>
          <option value="#f6b26b"></option>
          <option value="#ffd966"></option>
          <option value="#93c47d"></option>
          <option value="#76a5af"></option>
          <option value="#6d9eeb"></option>
          <option value="#6fa8dc"></option>
          <option value="#8e7cc3"></option>
          <option value="#c27ba0"></option>

          <option value="#a61c00"></option>
          <option value="#cc0000"></option>
          <option value="#e69138"></option>
          <option value="#f1c232"></option>
          <option value="#6aa84f"></option>
          <option value="#45818e"></option>
          <option value="#3c78d8"></option>
          <option value="#3d85c6"></option>
          <option value="#674ea7"></option>
          <option value="#a64d79"></option>

          <option value="#85200c"></option>
          <option value="#990000"></option>
          <option value="#b45f06"></option>
          <option value="#bf9000"></option>
          <option value="#38761d"></option>
          <option value="#134f5c"></option>
          <option value="#1155cc"></option>
          <option value="#0b5394"></option>
          <option value="#351c75"></option>
          <option value="#741b47"></option>

          <option value="#5b0f00"></option>
          <option value="#660000"></option>
          <option value="#783f04"></option>
          <option value="#7f6000"></option>
          <option value="#274e13"></option>
          <option value="#0c343d"></option>
          <option value="#1c4587"></option>
          <option value="#073763"></option>
          <option value="#20124d"></option>
          <option value="#4c1130"></option>
        </select>
        <select class="ql-background">
          <!-- <option value="inherit"></option> -->
          <option value="#0b0b0b"></option>
          <option value="#434343"></option>
          <option value="#666666"></option>
          <option value="#999999"></option>
          <option value="#b7b7b7"></option>
          <option value="#cccccc"></option>
          <option value="#d9d9d9"></option>
          <option value="#efefef"></option>
          <option value="#f3f3f3"></option>
          <option value="#ffffff"></option>

          <option value="#980000"></option>
          <option value="#ff0000"></option>
          <option value="#ff9900"></option>
          <option value="#ffff00"></option>
          <option value="#00ff00"></option>
          <option value="#00ffff"></option>
          <option value="#4a86e8"></option>
          <option value="#0000ff"></option>
          <option value="#9900ff"></option>
          <option value="#ff00ff"></option>

          <option value="#e6b8af"></option>
          <option value="#f4cccc"></option>
          <option value="#fce5cd"></option>
          <option value="#fff2cc"></option>
          <option value="#d9ead3"></option>
          <option value="#d0e0e3"></option>
          <option value="#c9daf8"></option>
          <option value="#cfe2f3"></option>
          <option value="#d9d2e9"></option>
          <option value="#ead1dc"></option>

          <option value="#dd7e6b"></option>
          <option value="#ea9999"></option>
          <option value="#f9cb9c"></option>
          <option value="#ffe599"></option>
          <option value="#b6d7a8"></option>
          <option value="#a2c4c9"></option>
          <option value="#a4c2f4"></option>
          <option value="#9fc5e8"></option>
          <option value="#b4a7d6"></option>
          <option value="#d5a6bd"></option>

          <option value="#cc4125"></option>
          <option value="#e06666"></option>
          <option value="#f6b26b"></option>
          <option value="#ffd966"></option>
          <option value="#93c47d"></option>
          <option value="#76a5af"></option>
          <option value="#6d9eeb"></option>
          <option value="#6fa8dc"></option>
          <option value="#8e7cc3"></option>
          <option value="#c27ba0"></option>

          <option value="#a61c00"></option>
          <option value="#cc0000"></option>
          <option value="#e69138"></option>
          <option value="#f1c232"></option>
          <option value="#6aa84f"></option>
          <option value="#45818e"></option>
          <option value="#3c78d8"></option>
          <option value="#3d85c6"></option>
          <option value="#674ea7"></option>
          <option value="#a64d79"></option>

          <option value="#85200c"></option>
          <option value="#990000"></option>
          <option value="#b45f06"></option>
          <option value="#bf9000"></option>
          <option value="#38761d"></option>
          <option value="#134f5c"></option>
          <option value="#1155cc"></option>
          <option value="#0b5394"></option>
          <option value="#351c75"></option>
          <option value="#741b47"></option>

          <option value="#5b0f00"></option>
          <option value="#660000"></option>
          <option value="#783f04"></option>
          <option value="#7f6000"></option>
          <option value="#274e13"></option>
          <option value="#0c343d"></option>
          <option value="#1c4587"></option>
          <option value="#073763"></option>
          <option value="#20124d"></option>
          <option value="#4c1130"></option>
        </select>
      </span>
      <span class="ql-formats" *ngIf="!disableLists">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
      </span>

      <span class="ql-formats">
        <select class="ql-align">
          <option selected></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-clean"></button>
      </span>
      <span class="ql-formats">
        <!-- <mat-icon class="link-blot" (click)="setLink()">link</mat-icon> -->
        <button class="ql-link" matTooltip="Add link" (click)="setLink()"></button>
      </span>
    </div>
  </quill-editor>
</div>
