import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { PlayerModule } from '@storytemple/player';
import { QRCodeModule } from 'angularx-qrcode';
import { environment } from 'src/environments/environment';
import { StoryViewComponent } from './story-view.component';
@NgModule({
  declarations: [StoryViewComponent],
  imports: [
    SharedModule,
    RouterModule,
    QRCodeModule,
    PlayerModule.forRoot({
      environment,
    }),
  ],
})
export class StoryViewModule {}
