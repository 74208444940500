import { createSelector } from '@ngrx/store';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { EditorState } from '../reducers/editor.reducer';
import cloneDeep from 'lodash.clonedeep';

export const getEditorState = (state: AppState) => state.editor;

export const getSelectedElement =
  createSelector(
    getEditorState,
    (state: EditorState) => cloneDeep(state.selectedElement),
  );

  export const getSelectedSlide = createSelector(
    getEditorState,
    (state: EditorState) => state.selectedSlide,
  );

  export const getSelectedTouchpointId = createSelector(
    getEditorState,
    (state: EditorState) => state.selectedTouchpointId,
  );