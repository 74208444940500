import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'st-text-editor-popup',
  templateUrl: './text-editor-popup.component.html',
  styleUrls: ['./text-editor-popup.component.scss'],
})
export class TextEditorPopupComponent implements OnInit {
  public content: string;
  private result: string;

  constructor(public dialogRef: MatDialogRef<TextEditorPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.beforeClosed().subscribe(() => dialogRef.close(this.result));
  }

  public ngOnInit(): void {
    this.content = this.data.content;
    this.result = this.data.content;
  }

  public onTextChanged(text: string) {
    this.result = text;
  }

  public closeDialog() {
    this.dialogRef.close(this.result);
  }
}
