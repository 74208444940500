import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorService } from '@pages/editor/services/editor.service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { Slide } from '@shared/models/entities/slide';
import { setSelectedSlide } from '@shared/store/actions/editor.actions';

@Component({
  selector: 'st-slide-preview',
  templateUrl: './slide-preview.component.html',
  styleUrls: ['../slide-editor/slide-editor.component.scss'],
})
export class SlidePreviewComponent implements OnChanges {
  @Input() public slideData: Slide;
  @Input() public selectedSlideId: number;
  public useEditorSlide = false;
  constructor(private store: Store<AppState>, public editorService: EditorService) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedSlideId']) {
      if (this.selectedSlideId === this.slideData.id) {
        this.useEditorSlide = true;
      } else {
        this.useEditorSlide = false;
      }
    }
  }

  public selectSlide(slide: Slide) {
    this.store.dispatch(setSelectedSlide({ slideId: slide.id }));
  }
}
