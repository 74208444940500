import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { ImageElement } from '@shared/models/domain-models/editor/parser/elements/imageElement';
import { ImageAsset } from '@shared/models/entities/asset/imageAsset';
import { UpdateDto } from '@shared/dto/update.dto';
import { StImageElement } from '@shared/models/entities/stElements/stImageElement.entity';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/models/domain-models/appState';
import { updateStElement } from '@shared/store/actions/story.actions';
import { StElementType } from '@shared/models/entities/stElements/stElementType';

@Component({
  selector: 'st-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnChanges {
  @ViewChild('cropper') public imageCropper: ImageCropperComponent;
  @Input() public selectedElement: ImageElement;
  @Input() public originalAsset: ImageAsset;
  public imageBase64: string;
  public aspectRatio: number;
  public initialCrop = true;
  public showCropper = false;
  private cropperWidth: number;
  private cropperHeight: number;

  constructor(private store: Store<AppState>) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['originalAsset']) {
      if (this.originalAsset) {
        this.showCropper = false;
        this.initialCrop = true;
        this.setImageToBase64(this.originalAsset.imageUrl);
      }
    }
    if (this.selectedElement) {
      this.setAspectRatio();
    }
  }

  public async setImageToBase64(imageLink: string) {
    const result: any = await fetch(imageLink);
    const blob = await result.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      this.imageBase64 = reader.result as string;
      this.showCropper = true;
    };
  }

  public imageCropped(imageCropped: ImageCroppedEvent) {
    // Skip first crop to prevent resetting image to full size
    if (!this.initialCrop) {
      const x = Math.round(
        (imageCropped.cropperPosition.x1 / this.cropperWidth) *
          Number(this.selectedElement.getImageElement().getAttribute('width')),
      );
      const y = Math.round(
        (imageCropped.cropperPosition.y1 / this.cropperHeight) *
          Number(this.selectedElement.getImageElement().getAttribute('height')),
      );
      const width = Math.round(
        ((imageCropped.cropperPosition.x2 - imageCropped.cropperPosition.x1) / this.cropperWidth) *
          Number(this.selectedElement.getImageElement().getAttribute('width')),
      );
      const height = Math.round(
        ((imageCropped.cropperPosition.y2 - imageCropped.cropperPosition.y1) / this.cropperHeight) *
          Number(this.selectedElement.getImageElement().getAttribute('height')),
      );

      const slideId = this.selectedElement.stElement.slideId;
      const slotId = this.selectedElement.stElement.slotId;
      const dto: UpdateDto<StImageElement> = {
        key: { slideId, slotId },
        filter: { x, y, width, height, stElementType: StElementType.STIMAGEELEMENT },
      };
      this.selectedElement.editImage({ x, y, width, height });
      this.store.dispatch(updateStElement({ dto, combine: false }));
    }
    this.initialCrop = false;
  }

  // Set cropper position relative to original image height / width
  public cropperReady() {
    this.cropperWidth = this.imageCropper.cropper.x2;
    this.cropperHeight = this.imageCropper.cropper.y2;
    // Check whether size has been set before
    if (this.selectedElement.stElement.height && this.selectedElement.stElement.width) {
      this.imageCropper.cropper.x1 =
        (this.selectedElement.stElement.x / Number(this.selectedElement.getImageElement().getAttribute('width'))) *
        this.cropperWidth;
      this.imageCropper.cropper.x2 =
        ((this.selectedElement.stElement.x + this.selectedElement.stElement.width) /
          Number(this.selectedElement.getImageElement().getAttribute('width'))) *
        this.cropperWidth;
      this.imageCropper.cropper.y1 =
        (this.selectedElement.stElement.y / Number(this.selectedElement.getImageElement().getAttribute('height'))) *
        this.cropperHeight;
      this.imageCropper.cropper.y2 =
        ((this.selectedElement.stElement.y + this.selectedElement.stElement.height) /
          Number(this.selectedElement.getImageElement().getAttribute('height'))) *
        this.cropperHeight;
    }
  }

  private setAspectRatio() {
    const imageNode = this.selectedElement.getImageElement();
    const width: any = Number(imageNode.getAttribute('width'));
    const height: any = Number(imageNode.getAttribute('height'));
    this.aspectRatio = width / height;
  }
}
