<div class="story-card" [@.disabled]="false" [@cardExitAnimation]="leave ? 'open' : 'closed'">
  <mat-card class="story-content mat-card-white">
    <div
      mat-card-image
      *ngIf="story.slides && story.slides.length > 0; else image_placeholder"
      class="story-image"
      [innerHtml]="story.slides[0].content | safeHTML"
    ></div>
    <ng-template #image_placeholder>
      <svg-icon
        mat-card-image
        class="story-image story-image-placeholder"
        src="assets/icons/image.svg"
        [svgStyle]="{ 'width.px': 100, 'height.px': 100 }"
      ></svg-icon>
    </ng-template>

    <div class="story-card__content">
      <mat-card-content class="content">
        <mat-card-title *ngIf="!editTitle" (click)="editTitle = true">
          {{ this.storyName }}
        </mat-card-title>
        <mat-form-field *ngIf="editTitle" appearance="fill" style="width: 100%;">
          <input matInput placeholder="Story name" [(ngModel)]="storyName" />
          <button
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="changeTitle(story.id, story.name); this.editTitle = false"
          >
            <mat-icon>done</mat-icon>
          </button>
        </mat-form-field>
        <div *ngIf="story.storyType === 'preview'">This is a preview story</div>
        <p class="date">
          Creation date: {{ story.created | date: 'dd-MM-yyy' }} at
          {{ story.created | date: 'HH:mm' }}
        </p>
        <p *ngIf="story.updated" class="date">
          Last time updated:
          {{ story.updated | date: 'dd-MM-yyy' }} at
          {{ story.updated | date: 'HH:mm' }}
        </p>
      </mat-card-content>
    </div>
  </mat-card>
  <div class="icon-wrapper">
    <button mat-icon-button class="play-button">
      <mat-icon class="icon" [routerLink]="['/story', story.id]" matTooltip="Publish story" matTooltipPosition="after"
        >play_circle_filled
      </mat-icon>
    </button>
    <!-- <button mat-icon-button class="play-button" [matMenuTriggerFor]="publishMenu">
      <mat-icon class="icon" matTooltip="Publish story" matTooltipPosition="after">play_circle_filled </mat-icon>
    </button>
    <mat-menu #publishMenu="matMenu" xPosition="after">
      <button mat-menu-item (click)="publish(story.id)">WhiteTable</button>
      <a mat-menu-item [routerLink]="['/story', story.id]">Web</a>
    </mat-menu> -->
    <button mat-icon-button class="duplicate-button" (click)="duplicate(story.id, story)">
      <mat-icon class="icon" matTooltip="Duplicate Story" matTooltipPosition="after">content_copy</mat-icon>
    </button>
    <button *ngIf="navTab === 'My stories'" mat-icon-button class="edit-button" (click)="edit(story.id)">
      <mat-icon class="icon" matTooltip="Edit Story" matTooltipPosition="after">edit</mat-icon>
    </button>
    <button
      *ngIf="story.storyType !== 'preview' && navTab === 'My stories'"
      mat-icon-button
      class="delete-button"
      (click)="remove(story.id)"
    >
      <mat-icon class="icon" matTooltip="Delete Story" matTooltipPosition="after">delete</mat-icon>
    </button>
    <button
      *ngIf="story.storyType !== 'preview' && navTab === 'My stories'"
      mat-icon-button
      class="share-button"
      (click)="share(story.id)"
    >
      <mat-icon class="icon" matTooltip="Share Story" matTooltipPosition="after">share</mat-icon>
    </button>
  </div>
</div>
