import { StImageElement } from '@shared/models/entities/stElements/stImageElement.entity';
import { Element } from '../element';
import { ElementType } from './elementType';

export class ImageElement implements Element {
  public elementType: ElementType;

  constructor(private rootNode: HTMLElement, public stElement: StImageElement) {
    this.elementType = ElementType.IMAGE_ELEMENT;
  }

  public getElement(): HTMLElement {
    return this.rootNode;
  }

  public getImageElement(): SVGImageElement {
    return this.rootNode.querySelector('image');
  }

  public editElement(imageElement: Partial<StImageElement>) {
    const svg = this.rootNode.querySelector('svg');
    svg.setAttribute('viewBox', `${imageElement.x}, ${imageElement.y}, ${imageElement.width}, ${imageElement.height}`);
  }

  public editImage(imageElement: Partial<StImageElement>) {
    const svg = this.rootNode.querySelector('svg');
    svg.setAttribute('viewBox', `${imageElement.x}, ${imageElement.y}, ${imageElement.width}, ${imageElement.height}`);
  }

  public changeImage(imageUrl: string) {
    const svg = this.rootNode.querySelector('svg');
    const imageElement = svg.querySelector('image');
    const imageWidth = imageElement.getAttribute('width');
    const imageHeight = imageElement.getAttribute('height');
    svg.setAttribute('viewBox', `0, 0, ${imageWidth}, ${imageHeight}`);
    imageElement.setAttribute('xlink:href', imageUrl);
  }

  public removeImage() {
    this.rootNode.querySelector('image').setAttribute('xlink:href', '');
  }
}
