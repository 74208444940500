import { Injectable } from '@angular/core';
import { Slide } from '@shared/models/entities/slide';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UpdateDto } from '@shared/dto/update.dto';
import { AddSlideDto } from '@shared/dto/addSlide.dto';
import { ChangeSlideTemplateDto } from '@shared/dto/changeSlideTemplate.dto';

@Injectable({
  providedIn: 'root',
})
export class SlideService {
  private readonly URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public update(key: Partial<Slide>, filter: Partial<Slide>): Observable<Slide> {
    const dto = new UpdateDto(key, filter);
    return this.http.put<Slide>(`${this.URL}/slide/update`, dto);
  }

  public addSlide(addSlideDto: AddSlideDto): Observable<any> {
    return this.http.post<Slide>(`${this.URL}/slide/addSlide`, addSlideDto);
  }

  public removeOneSlideInTree(data): Observable<any> {
    return this.http.post<any>(`${this.URL}/slide/removeOneSlideInTree`, data);
  }

  public getcombinedSlide(slideId: number): Observable<Slide> {
    return this.http.get<Slide>(`${this.URL}/combiner/findOneSlide/${slideId}`);
  }

  public changeSlideTemplate(changeSlideTemplateDto: ChangeSlideTemplateDto): Observable<void>{
    return this.http.put<void>(`${this.URL}/slide/changeSlideTemplate`, changeSlideTemplateDto);
  }
}