<div class="graph-data-editor">
  <mat-dialog-content>
    <h3 class="edit-data-header">Edit Graph Data</h3>
    <table mat-table [dataSource]="graphData" class="mat-elevation-z8 data-table">
      <!-- Define column header -->
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>

      <!-- Define table data -->
      <ng-container
        *ngFor="let item of columns.slice(0, columns.length - 1); let itemIndex = index"
        matColumnDef="{{ item }}"
      >
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field class="column-input-field" floatLabel="never">
            <input
              [readonly]="itemIndex === 0"
              [matTooltip]="
                itemIndex === 0 ? 'Key column contains the X-Values.' : 'This column contains the Y-Values.'
              "
              matInput
              placeholder="Enter column name here..."
              value="{{ item }}"
              (input)="changeColumnValue(itemIndex - 1, item, $event.target.value)"
            />
            <button *ngIf="itemIndex !== 0" mat-icon-button matPrefix (click)="removeColumn(item)">
              <mat-icon>highlight_off</mat-icon>
            </button>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element; let objectIndex = index">
          <mat-form-field floatLabel="never">
            <input
              matInput
              [type]="!keyColumnNumericType.includes(graphType) && itemIndex === 0 ? 'text' : 'number'"
              placeholder="Enter data here..."
              value="{{ element[item] }}"
              (input)="changeTableValue(objectIndex, item, $event.target.value)"
            />
          </mat-form-field>
        </td>
      </ng-container>
      <!-- Define actions for table row -->
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button class="action-button-row" mat-icon-button (click)="rowActions('remove', element)">
            <mat-icon matSuffix>highlight_off</mat-icon>
          </button>
        </td>
      </ng-container>
    </table>
  </mat-dialog-content>
  <!-- Dialog Action Buttons -->
  <mat-dialog-actions>
    <div class="add-btn">
      <button mat-raised-button (click)="rowActions('add')">
        Add Row
      </button>
      <button mat-raised-button [disabled]="singleSerieSupportedCharts.includes(graphType)" (click)="addColumn()">
        <span
          matTooltipPosition="above"
          [matTooltip]="
            singleSerieSupportedCharts.includes(graphType) ? 'This graph does not support multiple data series.' : ''
          "
        >
          Add Column</span
        >
      </button>
    </div>
    <button mat-raised-button mat-dialog-close>
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="saveChanges()">
      Save Changes
    </button>
  </mat-dialog-actions>
</div>
