import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { Font, supportedFonts } from '@storytemple/models';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  private readonly GOOGLE_FONTS_KEY = environment.GOOGLE_FONTS_KEY;
  constructor(private http: HttpClient) {}

  public getFonts(): Observable<Font[]> {
    return of(supportedFonts);
    // TODO Include google fonts in future
    // this.http.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${this.GOOGLE_FONTS_KEY}&sort=popularity`).pipe(
    //     map((res:any) =>
    //       res.items.map((font) => new Font(font.family)),

    //     )
    // )
  }
}
