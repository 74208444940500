import { Element } from '../element';
import { ElementType } from './elementType';
import { StVideoElement } from '@shared/models/entities/stElements/stVideoElement.entity';
import { StVideoOptions } from '@shared/models/domain-models/video/stVideoOptions';

export class VideoElement implements Element {
  public elementType: ElementType;

  constructor(private rootNode: HTMLElement, public stElement: StVideoElement) {
    this.elementType = ElementType.VIDEO_ELEMENT;
  }

  public getElement(): HTMLElement {
    return this.rootNode;
  }

  public editElement(videoId) {
    const video: HTMLElement = this.rootNode.querySelector('iframe');
    video.setAttribute('data-videoId', videoId);
    video.setAttribute('src', `https://player.vimeo.com/video/${videoId}`);
  }

  public changeVideo(videoUrl: string, videoOptions?: StVideoOptions) {
    const video: HTMLElement = this.rootNode.querySelector(' iframe');
    video.setAttribute('src', videoUrl);
    video.setAttribute('allow', 'autoplay');
    video.setAttribute('data-videoOptions', JSON.stringify(videoOptions));
  }

  public getVideoOptions(): string {
    const video: HTMLElement = this.rootNode.querySelector(' iframe');
    return video.getAttribute('data-videooptions');
  }
}
