import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SafeHTMLPipe } from './pipes/safe-html/safe-html.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { PublishComponent } from './components/publish/publish.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceControlComponent } from './components/publish/device-control/device-control.component';
import { RouterModule } from '@angular/router';
import { MatSliderModule } from '@angular/material/slider';
import { HttpClientModule } from '@angular/common/http';
import { AlertComponent } from './components/alert/alert.component';
import { UrlToStringPipe } from './pipes/link-to-string/url-to-string.pipe';
import { ContainsPipe } from './pipes/contains/contains.pipe';
import { LoadingComponent } from './components/loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SafeUrlPipe } from './pipes/safe-url/safe-url.pipe';
import { UploadStatusComponent } from './components/upload-status/upload-status.component';
import { AppMutationObserverDirective } from './directives/app-mutation-observer.directive';
import { DependencyInjectorComponent } from './components/dependency-injector/dependency-injector.component';
import { TreeOverviewComponent } from './components/tree-overview/tree-overview.component';
import { SlideSelectorComponent } from './components/tree-overview/slide-selector/slide-selector.component';
import { MouseWheelDirective } from './directives/mouse-wheel.directive';
import { TreeModule } from '@circlon/angular-tree-component';
import { SessioncodeComponent } from './components/sessioncode/sessioncode.component';
import { QRCodeModule } from 'angularx-qrcode';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SlideComponent } from './components/tree-overview/slide/slide.component';
import { SlideEditorComponent } from './components/tree-overview/slide/slide-editor/slide-editor.component';
import { SlidePreviewComponent } from './components/tree-overview/slide/slide-preview/slide-preview.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ShareMenuComponent } from './components/share-menu/share-menu.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { GenericFilterPipe } from './pipes/filter/generic-filter.pipe';
import { RichTextEditorModule } from './components/rich-text-editor/rich-text-editor.module';
import { TextEditorPopupComponent } from './components/text-editor/text-editor-popup/text-editor-popup.component';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
    declarations: [
        SafeHTMLPipe,
        FilterPipe,
        HeaderComponent,
        PublishComponent,
        DeviceControlComponent,
        AlertComponent,
        LoadingComponent,
        ContainsPipe,
        UrlToStringPipe,
        SafeUrlPipe,
        GenericFilterPipe,
        UploadStatusComponent,
        AppMutationObserverDirective,
        DependencyInjectorComponent,
        TreeOverviewComponent,
        SessioncodeComponent,
        MouseWheelDirective,
        SlideSelectorComponent,
        SlideComponent,
        SlideEditorComponent,
        SlidePreviewComponent,
        ShareMenuComponent,
        TextEditorPopupComponent,
    ],
    imports: [
        MatNativeDateModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatDividerModule,
        MatSlideToggleModule,
        QRCodeModule,
        CommonModule,
        ClipboardModule,
        BrowserAnimationsModule,
        MatGridListModule,
        MatMenuModule,
        MatCardModule,
        MatToolbarModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatSliderModule,
        HttpClientModule,
        MatTooltipModule,
        MatProgressBarModule,
        TreeModule,
        MatSelectModule,
        MatFormFieldModule,
        DragDropModule,
        MatSlideToggleModule,
        AngularSvgIconModule.forRoot(),
        MatAutocompleteModule,
        MatListModule,
        RichTextEditorModule,
        ColorPickerModule,
    ],
    exports: [
        SessioncodeComponent,
        SafeHTMLPipe,
        FilterPipe,
        HeaderComponent,
        PublishComponent,
        AlertComponent,
        BrowserAnimationsModule,
        MatSlideToggleModule,
        MatGridListModule,
        MatMenuModule,
        MatCardModule,
        MatToolbarModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        RouterModule,
        MatSliderModule,
        HttpClientModule,
        UrlToStringPipe,
        MatTooltipModule,
        ContainsPipe,
        SafeUrlPipe,
        GenericFilterPipe,
        AppMutationObserverDirective,
        DependencyInjectorComponent,
        TreeModule,
        TreeOverviewComponent,
        MatSelectModule,
        FormsModule,
        LoadingComponent,
        MatFormFieldModule,
        MatInputModule,
        AngularSvgIconModule,
        MatCheckboxModule,
        MatDividerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatListModule,
        ColorPickerModule,
    ]
})
export class SharedModule {}
