import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@shared/services/local/authentication-service/authentication.service';
import { Observable, retry, throwError } from 'rxjs';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const csrfToken = this.authService.getCsrfToken();
    request = request.clone({
      withCredentials: true,
      headers: csrfToken ? request.headers.set('x-csrf-token', csrfToken) : undefined,
    });
    return next.handle(request).pipe(
      retry({
        count: 1,
        delay: (error: HttpErrorResponse) => {
          if (error.status === 401 || error.status === 403) {
            if (error.error.message === 'Invalid JWT') {
              return this.authService.refresh();
            }

            if (error.error.message === 'Invalid Refresh Token') {
              return this.authService.logout();
            }
          }
          return throwError(() => error);
        },
      }),
    );
  }
}
