import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TouchpointStyle } from '@shared/models/entities/touchpointStyle';
import { SlidePanelComponent } from '../../slide-panel.component';
import { ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { AddUrlTouchpointDto } from '@shared/dto/addUrlTouchpoint.dto';
import { Store } from '@ngrx/store';
import { addLinkTouchpoint } from '@shared/store/actions/story.actions';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'st-url-tp-adder',
  templateUrl: './url-tp-adder.component.html',
  styleUrls: ['./url-tp-adder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UrlTpAdderComponent implements OnInit {
  public tpStyles: TouchpointStyle[];
  public selectedTpStyleId: number;
  public urlControl: FormControl;
  public urlType: 'web' | 'mail' = 'web';
  private slideId: number;

  constructor(
    public dialogRef: MatDialogRef<SlidePanelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.urlControl = new FormControl('', [Validators.required, UrlValidator(this.urlType)]);
    this.tpStyles = this.data.tpStyles;
    this.slideId = this.data.slideId;
  }

  public changeLinkType(event: MatRadioChange) {
    this.urlType = event.value;
    this.urlControl.clearValidators();
    this.urlControl.setValidators([Validators.required, UrlValidator(this.urlType)]);
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public setTpStyle(tpStyleId: number) {
    this.selectedTpStyleId = tpStyleId;
  }

  public save() {
    let url;
    if (this.urlType === 'web') {
      url = `https://${this.urlControl.value}`;
    } else {
      url = `mailto:${this.urlControl.value}`;
    }
    const dto = new AddUrlTouchpointDto(this.slideId, url, this.selectedTpStyleId);
    this.store.dispatch(addLinkTouchpoint({ addTouchpointDto: dto }));
    this.closeDialog();
  }

  public getErrorMessage() {
    if (this.urlControl.hasError('required')) {
      return 'You must enter a value';
    }
    return this.urlControl.hasError('pattern') ? this.urlControl.errors['pattern'] : '';
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function UrlValidator(urlType): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const urlRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)$/;
    // eslint-disable-next-line max-len
    const emailRegex = /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    const errorMap = {};
    if (control.value) {
      const validEmail = emailRegex.test(control.value);
      if (urlType === 'web') {
        if (!urlRegex.test(`https://${control.value}`)) {
          errorMap['pattern'] = 'Not a valid url';
        }
      } else if (urlType === 'mail' && !validEmail) {
        errorMap['pattern'] = 'Not a valid mail';
      }
    }

    return Object.keys(errorMap).length !== 0 ? errorMap : null;
  };
}
