<mat-icon *ngIf="slideData.touchpoints.length > 0 && touchpointHasLink()" (click)="startLinkMode(slideData)" class="link slide-link">link_off</mat-icon>

<div
  cdkDragHandle
  id="slide-tree-{{ slideData.id }}"
  class="card-body slide-img "
  [ngClass]="{'selected-slide': selectedSlideId === slideData.id}"
  [innerHTML]="slideData.content | safeHTML"
  (click)="onSlideClick(slideData)"
  (dblclick)="editSlideWithDblclick(slideData)"
></div>

<button [matMenuTriggerFor]="menu" class="slide-menu" mat-icon-button>
  <mat-icon class="icon" matTooltip="Add slide" matTooltipPosition="after">
    more_horiz
  </mat-icon>
</button>

<mat-menu #menu="matMenu" xPosition="before">
  <button mat-menu-item class="root-button" (click)="changeSlideTemplate(slideData)">
    <mat-icon>build</mat-icon>
    <span>Change slide template</span>
  </button>
  <button mat-menu-item class="play-button" (click)="addSlide(slideData)">
    <mat-icon>add_to_queue</mat-icon>
    <span>Add slide</span>
  </button>
  <button mat-menu-item class="edit-button" (click)="startLinkMode(slideData)" >
    <mat-icon>link</mat-icon>
    <span>Add link</span>
  </button>
  <button mat-menu-item class="clone-button" (click)="removeSlide(slideData)">
    <mat-icon>delete</mat-icon>
    <span>Remove slide</span>
  </button>
</mat-menu>
