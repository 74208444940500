import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Device } from '@shared/models/entities/device';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private readonly URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public saveDevice(device: Device): Observable<Device> {
    return this.http.post<Device>(this.URL + '/device', device);
  }

  public findById(id: number): Observable<Device> {
    return this.http.get<Device>(this.URL + `/device/${id}`);
  }

  public getAll(): Observable<Device[]> {
    return this.http.get<Device[]>(this.URL + '/device');
  }

  public cast(presentationIdRequest: number, rootSlide: number, deviceIdRequest: number): Observable<Device> {
    const request = { presentationId: presentationIdRequest, rootSlide, deviceId: deviceIdRequest };
    return this.http.post<Device>(this.URL + '/device/cast', request);
  }

  public stopCasting(deviceId: number): Observable<Device> {
    const request = { deviceId };
    return this.http.post<Device>(this.URL + '/device/stopCasting', request);
  }
}
