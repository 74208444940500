import { Component, OnInit, Input } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GraphElement } from '@shared/models/domain-models/editor/parser/elements/graphElement';
import { GraphType } from '@shared/models/entities/stElements/enums/graphType';
import { SerieStatus } from '@shared/models/entities/stElements/enums/serieStatus';
import { GraphDataEditorComponent } from './graph-data-editor/graph-data-editor.component';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { UpdateDto } from '@shared/dto/update.dto';
import { AppState } from '@shared/models/domain-models/appState';
import { Store } from '@ngrx/store';
import { updateStElement } from '@shared/store/actions/story.actions';
import { StGraphElement } from '@shared/models/entities/stElements/stGraphElement.entity';
import { StElementType } from '@shared/models/entities/stElements/stElementType';
import { setSelectedElement } from '@shared/store/actions/editor.actions';

@Component({
  selector: 'st-graph-element',
  templateUrl: './graph-element.component.html',
  styleUrls: ['./graph-element.component.scss'],
})
export class GraphElementComponent implements OnInit {
  @Input() public selectedElement: GraphElement;
  public graphType: string;
  public graphTypeList = GraphType;
  public excludeXTitleGraphType: string[] = ['pieChart', 'doughnutChart', 'radarChart', 'funnel'];
  public excludeYTitleGraphType: string[] = ['pieChart', 'doughnutChart', 'radarChart'];
  public title: string;
  public xAxisTitle: string;
  public yAxisTitle: string;
  public series: SerieStatus;
  public legend: string[];
  public graphColor: string;
  public colorScheme: string[];
  public colorSchemeEditStatus = false;
  public graphData = undefined;

  private editDialogRef: MatDialogRef<GraphDataEditorComponent>;
  private selectedColor: string;
  private selectedColorIndex: number;
  private timeout: ReturnType<typeof setTimeout>;

  constructor(private store: Store<AppState>, public dialog: MatDialog, private toastr: ToastrService) {}

  public ngOnInit() {
    const graph = this.selectedElement.stGraphElement;
    this.graphData = graph.data;
    this.graphType = graph.graphType;
    this.title = graph.title;
    this.xAxisTitle = graph.xAxisTitle;
    this.yAxisTitle = graph.yAxisTitle;
    this.series = graph.series;
    this.legend = graph.legend;
    this.graphColor = graph.graphColor;
    this.colorScheme = graph.colorScheme;
  }

  public changeGraphType(select: MatSelectChange) {
    const hasNumericKeyColumn = ['scatterChart', 'bubbleChart'];
    const supportMultiSerie = ['barChart', 'lineChart', 'barChartLine', 'radarChart'];
    const supportOnlySingleSerie = ['pieChart', 'doughnutChart', 'bubbleChart', 'funnel'];
    const type = Object.keys(GraphType)[Object.values(GraphType).indexOf(select.value)];
    const currentGraphType = this.graphType;
    const startMessage = `If you change your ${this.camelCaseToNormal(currentGraphType)} to a ${this.camelCaseToNormal(
      GraphType[type],
    )}, `;

    let message = '';
    this.graphType = GraphType[type];

    if (
      this.series === SerieStatus.MULTI &&
      supportMultiSerie.includes(currentGraphType) &&
      supportOnlySingleSerie.includes(select.value)
    ) {
      // Multi serie to only single serie graphType won't be showing all data.
      message = startMessage.concat('some data might be not visible.');
    } else if (supportMultiSerie.includes(currentGraphType) && hasNumericKeyColumn.includes(select.value)) {
      // Multi serie to scatter/bubble will lose the values of the 'key' column (X-values), if string to numeric
      message = startMessage.concat('you will need to edit your data.');
    } else if (hasNumericKeyColumn.includes(currentGraphType) && supportMultiSerie.includes(select.value)) {
      // Scatter/bubble to multi serie can cause some lost data
      message = startMessage.concat('some data might be lost.');
    } else {
      this.updateGraphTypeDatabase(type);
    }

    if (message !== '') {
      const dialogConfirmGraphType = this.dialog.open(AlertComponent, {
        width: '500px',
        data: message,
      });

      dialogConfirmGraphType.afterClosed().subscribe(confirmed => {
        if (confirmed) {
          this.updateGraphTypeDatabase(type);
        } else {
          this.graphType = currentGraphType;
        }
      });
    }
  }

  /**
   * Update graph title, X-axis and Y-axis title
   *
   * @param titleId input id
   * @param titleValue input value
   */
  public changeTitle(titleId, titleValue) {
    this[titleId] = titleValue;
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const dto = new UpdateDto<StGraphElement>(
        { slideId: this.selectedElement.stGraphElement.slideId, slotId: this.selectedElement.stGraphElement.slotId },
        { stElementType: StElementType.STGRAPHELEMENT, [titleId]: this[titleId] },
      );
      this.store.dispatch(updateStElement({ dto }));
    }, 500);
  }

  public changeGraphColor(colorHex) {
    this.graphColor = colorHex;
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const dto = new UpdateDto<StGraphElement>(
        { slideId: this.selectedElement.stGraphElement.slideId, slotId: this.selectedElement.stGraphElement.slotId },
        { stElementType: StElementType.STGRAPHELEMENT, graphColor: this.graphColor },
      );
      this.store.dispatch(updateStElement({ dto }));
    }, 500);
  }

  public colorSchemeClickColor(position, value) {
    this.selectedColor = value;
    this.selectedColorIndex = position;
    this.colorSchemeEditStatus = !this.colorSchemeEditStatus;
  }

  public colorSchemeChange(type: string, value?: string) {
    switch (type) {
      case 'add': {
        this.colorScheme = [...this.colorScheme, '#000'];
        break;
      }
      case 'edit': {
        this.selectedColor = value;
        break;
      }
      case 'update': {
        const currentScheme = [...this.colorScheme];
        currentScheme[this.selectedColorIndex] = this.selectedColor;
        this.colorScheme = currentScheme;
        this.updateColorSchemeDatabase();
        break;
      }
      case 'remove': {
        // Always keep one color
        if (this.colorScheme.length !== 1) {
          this.colorScheme = this.colorScheme.filter((c, idx) => idx !== this.selectedColorIndex);
          this.updateColorSchemeDatabase();
        } else {
          this.toastr.warning('Color scheme needs at least one color!');
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  public editDataDialog() {
    this.editDialogRef = this.dialog.open(GraphDataEditorComponent, {
      data: { graphType: this.graphType, graphData: this.graphData, legend: this.legend, series: this.series },
    });

    this.editDialogRef.afterClosed().subscribe(res => {
      if (res) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          const dto = new UpdateDto<StGraphElement>(
            {
              slideId: this.selectedElement.stGraphElement.slideId,
              slotId: this.selectedElement.stGraphElement.slotId,
            },
            { stElementType: StElementType.STGRAPHELEMENT, data: res.data, series: res.series, legend: res.legend },
          );
          this.store.dispatch(updateStElement({ dto }));
        }, 100);

        this.toastr.success('Changes successfully saved!');
        this.store.dispatch(setSelectedElement({ element: undefined }));
      } else if (this.graphType === GraphType.SCATTER_CHART || this.graphType === GraphType.BUBBLE_CHART) {
        this.store.dispatch(setSelectedElement({ element: undefined }));
      }
    });
  }
  // Insert a space before all caps, uppercase the first character
  public camelCaseToNormal(value: string) {
    return value.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
  }

  private updateColorSchemeDatabase() {
    this.colorSchemeEditStatus = false;
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const dto = new UpdateDto<StGraphElement>(
        { slideId: this.selectedElement.stGraphElement.slideId, slotId: this.selectedElement.stGraphElement.slotId },
        { stElementType: StElementType.STGRAPHELEMENT, colorScheme: this.colorScheme },
      );
      this.store.dispatch(updateStElement({ dto }));
    }, 100);
  }

  private updateGraphTypeDatabase(value) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const dto = new UpdateDto<StGraphElement>(
        { slideId: this.selectedElement.stGraphElement.slideId, slotId: this.selectedElement.stGraphElement.slotId },
        { stElementType: StElementType.STGRAPHELEMENT, graphType: GraphType[value] },
      );
      this.store.dispatch(updateStElement({ dto }));
    }, 100);
  }
}
