export enum Mime {
    PDF = 'application/pdf',
    SVG = 'image/svg+xml',
    PNG = 'image/png',
    JPEG = 'image/jpeg',
    POWERPOINT = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    MP4 = 'video/mp4',
}

// Add additional supported video Mimes.
export const supportedVideoMimes: Mime[] = [Mime.MP4];

// Add additional supported image Mimes.
export const supportedImageMimes: Mime[] = [Mime.JPEG, Mime.PNG, Mime.SVG];