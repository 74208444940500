import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { EditorMode } from '@shared/models/entities/editor-mode';
import { Model } from '@shared/models/entities/AI/model';
import { RfidTag } from '@shared/models/entities/rfidTag';
import { Template } from '@shared/models/entities/template';
import { Touchpoint } from '@shared/models/entities/touchpoint';
import { TouchpointHandleAspectRatio } from '@shared/models/entities/touchpointHandleAspectRatio';
import { TouchPointObject } from '@shared/models/entities/touchPointObject';
import { TouchPointTag } from '@shared/models/entities/touchpointTag';
import { AiService } from '@shared/services/http/ai-service/ai.service';
import { TouchpointStyleService } from '@shared/services/http/touchpointStyle-service/touchpoint-style.service';
import {
  removeLinkTouchpoint,
  updateTouchpoint,
  updateTouchpointObject,
  updateTouchpointTag,
} from '@shared/store/actions/story.actions';
import { AIObject } from '@shared/models/entities/AI/aiObject';
import { Story } from '@shared/models/entities/story';
import { TouchpointStyle } from '@shared/models/entities/touchpointStyle';
import { setSelectedElement } from '@shared/store/actions/editor.actions';

@Component({
  selector: 'st-touchpoint-element',
  templateUrl: './touchpoint-element.component.html',
  styleUrls: ['./touchpoint-element.component.scss'],
})
export class TouchpointElementComponent implements OnInit, OnDestroy {
  @Input() public selectedTouchpoint: Touchpoint;
  @Input() public template: Template;
  @Input() public story: Story;
  @Input() public editorMode: EditorMode;

  public touchpointTitle = '';
  public modelObjects: AIObject[];
  public showTouchpointObjectEditor = false;
  public showTagsEditor = false;
  public loadedObjectTags = false;
  public editorModeTypes = EditorMode;
  private modelSubscription: Subscription;
  private rfidTags: RfidTag[];
  private timeOut: ReturnType<typeof setTimeout>;

  constructor(
    private aiService: AiService,
    private store: Store<AppState>,
    private touchpointStyleService: TouchpointStyleService,
  ) {}

  public async ngOnInit() {
    // Nextslide id null is identified as a root slide for editormode wt3.
    if (this.selectedTouchpoint.nextSlideId == null) {
      if (this.editorMode === EditorMode.wt3) {
        const modelId = this.story.modelId;
        if (modelId != null) {
          const model: Model = await this.aiService.getModelById(modelId).toPromise();
          // Show dropdowns in touchpoint editor
          this.showTouchpointObjectEditor = true;
          this.modelObjects = model.objects;
        } else {
          this.modelObjects = [];
        }

        // Notification when user changes model when inside of the touchpoint editor
        this.modelSubscription = this.aiService.getModelSubject().subscribe(async mid => {
          const model: Model = await this.aiService.getModelById(mid).toPromise();
          // Show dropdowns in touchpoint editor
          this.showTouchpointObjectEditor = true;
          this.modelObjects = model.objects;
        });
      } else if (this.editorMode === EditorMode.wt2) {
        // Not yet implemented
        this.rfidTags = await this.aiService.getOrganizationTags().toPromise();
        this.showTagsEditor = true;
      }
    }
    this.loadedObjectTags = true;
  }

  public ngOnDestroy() {
    if (this.showTouchpointObjectEditor) {
      this.modelSubscription.unsubscribe();
    }
  }

  public setTouchpointObject(touchObject: TouchPointObject): AIObject {
    return this.modelObjects.find(obj => obj.OID === touchObject.objectId);
  }

  public setTouchpointTag(touchpointTag: TouchPointTag): RfidTag {
    return this.rfidTags.find(tag => tag.RFID_CHIP === touchpointTag.tagId);
  }

  public setNewTouchpointTag(event, touchpointTag: TouchPointTag) {
    const rfidTag: RfidTag = event.value;

    this.selectedTouchpoint.touchpointTags.forEach(tag => {
      if (tag.tagId === rfidTag.RFID_CHIP) {
        const key: Partial<TouchPointTag> = {
          touchPointId: tag.touchPointId,
          nextSlideId: tag.nextSlideId,
        };
        const filter: Partial<TouchPointTag> = { tagId: null };
        this.store.dispatch(updateTouchpointTag({ key, filter }));
      }
    });
    const key: Partial<TouchPointTag> = {
      touchPointId: touchpointTag.touchPointId,
      nextSlideId: touchpointTag.nextSlideId,
    };
    const filter: Partial<TouchPointTag> = { tagId: rfidTag.RFID_CHIP };
    this.store.dispatch(updateTouchpointTag({ key, filter }));
  }

  public setNewTouchpointObject(event, touchObject: TouchPointObject) {
    const object: AIObject = event.value;
    this.selectedTouchpoint.touchpointObjects.forEach(touchpointObject => {
      if (touchpointObject.objectId === object.OID) {
        // Sets touchpointTag tagid to null if similar value is picked
        const key: Partial<TouchPointObject> = {
          touchPointId: touchpointObject.touchPointId,
          nextSlideId: touchpointObject.nextSlideId,
        };
        const filter: Partial<TouchPointObject> = { objectId: touchpointObject.objectId };
        this.store.dispatch(updateTouchpointObject({ key, filter }));
      }
    });
    const key: Partial<TouchPointObject> = {
      touchPointId: touchObject.touchPointId,
      nextSlideId: touchObject.nextSlideId,
    };
    const filter: Partial<TouchPointObject> = { objectId: object.OID };
    this.store.dispatch(updateTouchpointObject({ key, filter }));
  }

  get canChangeAspectRatio(): boolean {
    return this.selectedTouchpoint.style.handleAspectRatio === TouchpointHandleAspectRatio.ENABLE;
  }

  get aspectRatio() {
    const styleContent = this.selectedTouchpoint.style.content;
    // + 9 is for the amount of characters in 'viewBox="'
    const removeFirstBit = styleContent.substr(styleContent.indexOf('viewBox="') + 9, styleContent.length);
    const viewBox = removeFirstBit.substr(0, removeFirstBit.indexOf('"')).split(' ');
    // Remove x and y value
    viewBox.shift();
    viewBox.shift();
    return Number(viewBox[0]) / Number(viewBox[1]);
  }

  public setNewTouchpointStyle(event) {
    const newTpStyle: TouchpointStyle = event.value;
    this.store.dispatch(
      updateTouchpoint({ key: { id: this.selectedTouchpoint.id }, filter: { styleId: newTpStyle.id } }),
    );
  }

  public changeHeight(height) {
    clearTimeout(this.timeOut);
    this.selectedTouchpoint.height = height;
    this.timeOut = setTimeout(() => {
      this.store.dispatch(updateTouchpoint({ key: { id: this.selectedTouchpoint.id }, filter: { height } }));
    }, 100);
  }

  public changeWidth(width) {
    clearTimeout(this.timeOut);
    this.selectedTouchpoint.width = width;
    this.timeOut = setTimeout(() => {
      this.store.dispatch(updateTouchpoint({ key: { id: this.selectedTouchpoint.id }, filter: { width } }));
    }, 100);
  }

  public changeSizeToAspect(event) {
    this.selectedTouchpoint.height = event.value;
    this.selectedTouchpoint.width = this.aspectRatio * (event.value / 16) * 9;
  }

  public formatLabel(value: number | null) {
    return value;
  }

  public changeSize(height) {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.store.dispatch(
        updateTouchpoint({
          key: { id: this.selectedTouchpoint.id },
          filter: { width: this.aspectRatio * (height / 16) * 9, height },
        }),
      );
    }, 100);
  }

  public tpInvisible(event) {
    this.selectedTouchpoint.isInvisible = event.checked;
    this.store.dispatch(
      updateTouchpoint({ key: { id: this.selectedTouchpoint.id }, filter: { isInvisible: event.checked } }),
    );
  }

  public async changeTitle(input) {
    const dom = new DOMParser().parseFromString(this.selectedTouchpoint.style.content, 'image/svg+xml');
    dom.querySelector('#touchpointtitle').querySelector('p').innerHTML = input;
    this.selectedTouchpoint.style.content = dom.documentElement.outerHTML;
    await this.touchpointStyleService
      .update({ id: this.selectedTouchpoint.style.id }, { content: this.selectedTouchpoint.style.content })
      .toPromise();
  }

  public removeUrlTp() {
    const tpId = this.selectedTouchpoint.id;
    this.store.dispatch(setSelectedElement({ element: undefined }));
    this.store.dispatch(removeLinkTouchpoint({ touchpointId: tpId }));
  }
}
