import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TouchpointStyle } from '@shared/models/entities/touchpointStyle';
import { UpdateDto } from '@shared/dto/update.dto';

@Injectable({
  providedIn: 'root',
})
export class TouchpointStyleService {
  private readonly URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public update(key: Partial<TouchpointStyle>, filter: Partial<TouchpointStyle>) {
    const updateDto = new UpdateDto(key, filter);
    return this.http.put<any>(`${this.URL}/touchpointStyle/update`, updateDto);
  }
}
