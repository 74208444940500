import { StGraphElement } from '@shared/models/entities/stElements/stGraphElement.entity';
import { Element } from '../element';
import { ElementType } from './elementType';

export class GraphElement implements Element {
  public elementType: ElementType;

  constructor(private rootNode: HTMLElement, public stGraphElement: StGraphElement) {
    this.elementType = ElementType.GRAPH_ELEMENT;
  }

  public getElement(): HTMLElement {
    return this.rootNode;
  }

  public editElement() {}
}
