import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AddLinkTouchpointDto } from '@shared/dto/addLinkTouchpoint.dto';
import { AppState } from '@shared/models/domain-models/appState';
import { Slide } from '@shared/models/entities/slide';
import { Story } from '@shared/models/entities/story';
import { Template } from '@shared/models/entities/template';
import { Touchpoint } from '@shared/models/entities/touchpoint';
import { TouchpointType } from '@shared/models/entities/touchpointType';
import { addLinkTouchpoint, removeLinkTouchpoint } from '@shared/store/actions/story.actions';

@Component({
  selector: 'st-slide-linker',
  templateUrl: './slide-linker.component.html',
  styleUrls: ['./slide-linker.component.scss']
})
export class SlideLinkerComponent implements OnChanges {

  @Input() public story: Story;
  @Input() public touchpoints: Touchpoint[];
  @Input() public selectedSlide: Slide;
  @Input() public template: Template;
  public linkSlides: Slide[];
  public slideLinkTouchpointsIds: number[];


  constructor(private router: Router, private store: Store<AppState>) {}

  public ngOnChanges() {
      this.linkSlides = this.story.slides.filter(s => s.id !== this.selectedSlide.id);
      this.slideLinkTouchpointsIds = this.touchpoints
        .filter(tp => tp.parentSlideId === this.selectedSlide.id && tp.type === TouchpointType.LINK)
        .map(tp => tp.nextSlideId);
  }

  public linkSlide(slide: Slide) {
    const dto = new AddLinkTouchpointDto(
      this.selectedSlide.id,
      TouchpointType.LINK,
      this.template.touchpointStyles.find(tps => tps.default).id,
      slide.id,
    );
    this.store.dispatch(addLinkTouchpoint({ addTouchpointDto: dto }));
  }

  public removeLink(slide: Slide) {
    this.touchpoints.forEach((tp: Touchpoint) => {
      if (
        tp.nextSlideId === slide.id &&
        tp.parentSlideId === this.selectedSlide.id &&
        tp.type === TouchpointType.LINK
      ) {
        this.store.dispatch(removeLinkTouchpoint({ touchpointId: tp.id }));
      }
    });
  }

  public mouseOverSlide(slide: Slide) {
    const preview = document.querySelector(`#slide-tree-${slide.id}`);
    preview.classList.add('linkBorder-hover');
  }

  public mouseOutSlide(slide: Slide) {
    const preview = document.querySelector(`#slide-tree-${slide.id}`);
    preview.classList.remove('linkBorder-hover');
  }

}
