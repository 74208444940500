import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SaveStoryAssetDto } from '@shared/dto/saveStoryAsset.dto';
import { Asset } from '@shared/models/entities/asset/asset';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private readonly URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public saveStoryAsset(dto: SaveStoryAssetDto){
    return this.http.post<Asset>(`${this.URL}/storyAsset/saveStoryAsset`, dto);
  }

  public deleteStoryAsset(storyId: number, assetId: number){
    return this.http.delete<any>(`${this.URL}/storyAsset/deleteStoryAsset/${storyId}/${assetId}`);
  }
}
