import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '@shared/models/domain-models/appState';
import { EnumFeatures } from '@storytemple/models';
import { take } from 'rxjs/operators';

@Injectable()
export class PlayerGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const shareToken = route.queryParams.shareToken;
    if (shareToken) {
      return true;
    } else {
      const features = await this.store
        .pipe(
          select(store => store.loggedInUser.user.features),
          take(1),
        )
        .toPromise();
      const hasEditorFeature = features.includes(EnumFeatures.EDITOR);
      return hasEditorFeature;
    }
  }
}
