import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VimeoService {
  private readonly URL = environment.vimeoService;
  constructor(private http: HttpClient) {}

  /**
   * Uploads the video file to the backend and returns the following object:
   *
   * @param storyId - Corresponding story ID.
   * @param video - The video file.
   */
  public uploadVideoAndReturnVimeoIdAndTusLink(storyId: number, video: File) {
    // Create formData.
    const formData = new FormData();
    // Add video to formData.
    formData.append('video', video);
    // Upload video.
    return this.http.post<FormData>(`${this.URL}/st/uploadVideo/${storyId}`, formData);
  }

  /**
   * Receives a response when uploading to Vimeo is done.
   *
   * @param vimeoId - ID of the video to check.
   */
  public awaitVideoTranscodedByVimeoId(vimeoId: number): Promise<any> {
    return this.http
      .get(`${this.URL}/st/awaitVideoTranscodedByVimeoId/${vimeoId}`)
      .toPromise()
      .catch(error => {
        if (error.status === 0) {
          // Request timeout when error.status is 0, restarting request (recursive).
          return this.awaitVideoTranscodedByVimeoId(vimeoId);
        } else {
          // Throw error if error.status is not 0.
          throw error;
        }
      });
  }

  /**
   * Receives a response when uploading to Vimeo is done.
   *
   * @param vimeoTusLink - The Tus (upload protocol) link which is used to upload the file to Vimeo.
   */
  public awaitVideoUploadedByVimeoTusLink(vimeoTusLink: string) {
    return this.http
      .post(`${this.URL}/st/awaitVideoUploadedByVimeoTusLink/`, {
        vimeoTusLink,
      })
      .toPromise()
      .catch(error => {
        if (error.status === 0) {
          // Request timeout when error.status is 0, restarting request (recursive).
          return this.awaitVideoUploadedByVimeoTusLink(vimeoTusLink);
        } else {
          // Throw error if error.status is not 0.
          throw error;
        }
      });
  }
}
