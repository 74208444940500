<div class="table-data-editor">
  <mat-dialog-content>
    <h3 class="edit-data-header">Edit Table Data</h3>
    <table mat-table [dataSource]="tableData" class="mat-elevation-z8 data-table">
      <!-- Define column header -->
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>

      <!-- Define table data -->
      <ng-container
        *ngFor="let item of columns.slice(0, columns.length - 1); let itemIndex = index"
        matColumnDef="{{ item }}"
      >
        <!-- Column data values -->
        <th mat-header-cell *matHeaderCellDef matBadge="15" matBadgeColor="warn">
          <mat-form-field class="column-input-field" floatLabel="never">
            <input
              matInput
              placeholder="Enter column name here..."
              value="{{ item }}"
              (input)="changeColumnValue(itemIndex, item, $event.target.value)"
            />
            <button mat-icon-button matPrefix (click)="removeColumn(itemIndex, item)">
              <mat-icon>highlight_off</mat-icon>
            </button>
          </mat-form-field>
        </th>
        <!-- Table data values -->
        <td mat-cell *matCellDef="let element; let objectIndex = index">
          <mat-form-field floatLabel="never">
            <input
              matInput
              placeholder="Enter data here..."
              value="{{ element[item] }}"
              (input)="changeTableValue(objectIndex, item, $event.target.value)"
            />
          </mat-form-field>
        </td>
      </ng-container>
      <!-- Define actions for table row -->
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button class="action-button-row" mat-icon-button (click)="rowActions('remove', element)">
            <mat-icon matSuffix>highlight_off</mat-icon>
          </button>
        </td>
      </ng-container>
    </table>
  </mat-dialog-content>
  <!-- Dialog Action Buttons -->
  <mat-dialog-actions>
    <div class="add-btn">
      <button mat-raised-button (click)="rowActions('add')">
        Add Row
      </button>
      <button mat-raised-button (click)="addColumn()">
        Add Column
      </button>
    </div>
    <button mat-raised-button mat-dialog-close>
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="saveChanges()">
      Save Changes
    </button>
  </mat-dialog-actions>
</div>
