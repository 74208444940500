import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { Story } from '@shared/models/entities/story';
import { StoryService } from '@shared/services/http/story-service/story.service';
import { loadPreviewStories, loadSharedStories, loadStories } from '@shared/store/actions/stories.actions';
import { CardAnimations } from './animations/card.animation';
import { take } from 'rxjs/operators';
import { CreateStoryDialogComponent } from './components/create-story-dialog/create-story-dialog.component';
import { SharedStoryVisibilityType } from '@shared/dto/usergroupsorg.dto';

@Component({
  selector: 'st-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  // tslint:disable-next-line
  encapsulation: ViewEncapsulation.None,
  animations: [CardAnimations.cardAnimation, CardAnimations.cardExitAnimation],
})
export class DashboardComponent implements OnInit {
  public filterVal: string;
  public searchVal: string;
  public navTab: 'My stories' | 'Shared with me' = 'My stories';
  public stories$: Observable<Story[]>;
  public sharedStoriesLists$: Observable<Record<SharedStoryVisibilityType, Story[]>>;
  public loadingStories$: Observable<boolean>;

  constructor(public service: StoryService, public dialog: MatDialog, private store: Store<AppState>) {}

  public async changeDashboard(navTab: 'My stories' | 'Shared with me') {
    if (
      navTab === 'Shared with me' &&
      !(await this.store
        .pipe(
          select(store => store.stories.sharedStoriesLists),
          take(1),
        )
        .toPromise())
    ) {
      this.store.dispatch(loadSharedStories());
    }
    this.navTab = navTab;
  }
  public ngOnInit() {
    this.pauseIframes();
    this.filterVal = 'updated';

    this.store.dispatch(loadStories());

    this.stories$ = this.store.select(store => store.stories.list.slice());
    this.sharedStoriesLists$ = this.store.select(store => store.stories.sharedStoriesLists);
    this.loadingStories$ = this.store.select(store => store.stories.loading);
  }

  public pauseIframes() {
    requestAnimationFrame(() => {
      // get all the background iframes and delete autoplay and loop and disable controls so the video is only the thumbnail
      for (let i = 0; i < document.querySelectorAll('.video-background iframe').length; i++) {
        document.querySelectorAll('.video-background iframe')[i].setAttribute(
          'src',
          document
            .querySelectorAll('.video-background iframe')
            [i].getAttribute('src')
            .split('?')[0] + '?controls=0',
        );
      }
    });
  }

  public changeFilter(event) {
    this.filterVal = event.value;
  }

  public async openAddStoryDialog() {
    if (
      !(await this.store
        .pipe(
          select(store => store.stories.previewStories),
          take(1),
        )
        .toPromise())
    ) {
      this.store.dispatch(loadPreviewStories());
    }
    const previewStories$ = this.store.select(store => store.stories.previewStories);

    this.dialog.open(CreateStoryDialogComponent, {
      width: '1000px',
      height: '700px',
      data: previewStories$,
    });
  }
}
