import { Directive, HostListener } from '@angular/core';
import { TreeOverviewComponent } from '../components/tree-overview/tree-overview.component';

@Directive({
  selector: '[stMouseWheel]',
})
export class MouseWheelDirective {
  public scrollUp = false;
  public clientWidth = 0;
  public clientHeight = 0;
  public clientTop = 0;
  public clientLeft = 0;

  constructor(private treeOverviewComponent: TreeOverviewComponent) {}
  
  @HostListener('mousewheel', ['$event'])
  public onMousewheel(event) {
    event.preventDefault()
    if (this.treeOverviewComponent.zoomAction) {
      this.updateClientRes();

      if (event.wheelDelta > 0) {
        this.scrollUp = true;
      }
      if (event.wheelDelta < 0) {
        this.scrollUp = false;
      }
      this.treeOverviewComponent.sidewaysZoom(this.scrollUp, event.ctrlKey, event.altKey);
    }
  }

  public updateClientRes() {
    const EL = document.querySelector('#stScrollZoom');
    this.clientWidth = EL ? EL.clientWidth : 0;
    this.clientHeight = EL ? EL.clientHeight : 0;
    this.clientTop = EL ? EL.getBoundingClientRect().top : 0;
    this.clientLeft = EL ? EL.getBoundingClientRect().left : 0;
  }
}
