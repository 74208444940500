<div class="link-panel">
    <h3 class="link-panel-title">Change Root Slide</h3>
    <div class="link-panel-scroll">
      <div *ngFor="let slide of previewSlides">
        <div class="link-slide" [innerHTML]="slide.content | safeHTML"></div>
          <div
            matTooltip="Use Template"
            matTooltipPosition="after"
            class="background"
            (click)="changeRootSlide(slide.slideId)"
          >
            <mat-icon class="not-linked" matTooltipPosition="after">build</mat-icon>
          </div>
      </div>
    </div>
  </div>
