import { createAction, props } from '@ngrx/store';
import { SharedStoryVisibilityType } from '@shared/dto/usergroupsorg.dto';
import { Slide } from '@shared/models/entities/slide';
import { Story } from '@shared/models/entities/story';

export const loadStories = createAction('[Stories] loadStories');
export const loadStoriesSuccess = createAction('[Stories] loadStoriesSuccess', props<{ list: Story[] }>());
export const loadStoriesFailure = createAction('[Stories] loadStoriesFailure', props<{ error: Error }>());

export const loadSharedStories = createAction('[Stories] loadSharedStories');
export const loadSharedStoriesSuccess = createAction(
  '[Stories] loadSharedStoriesSuccess',
  props<{ record: Record<SharedStoryVisibilityType, Story[]> }>(),
);
export const loadSharedStoriesFailure = createAction('[Stories] loadSharedStoriesFailure', props<{ error: Error }>());

export const loadPreviewStories = createAction('[Stories] loadPreviewStories');
export const loadPreviewStoriesSuccess = createAction(
  '[Stories] loadPreviewStoriesSuccess',
  props<{ previewStories: Story[] }>(),
);
export const loadPreviewStoriesFailure = createAction('[Stories] loadPreviewStoriesFailure', props<{ error: Error }>());

export const duplicateStory = createAction('[Story] duplicateStory', props<{ storyId: number; story: Story }>());
export const createTempDuplicateStory = createAction(
  '[Story] createTempDuplicateStory',
  props<{ story: Story; tempId: string }>(),
);
export const duplicateStorySuccess = createAction(
  '[Story] duplicateStorySuccess',
  props<{ story: Story; tempId: string; originalSlides: Slide[] }>(),
);
export const duplicateStoryFailure = createAction('[Story] duplicateStoryFailure', props<{ error: Error }>());

export const removeStory = createAction('[Story] removeStory', props<{ storyId: number }>());
export const removeStorySuccess = createAction('[Story] removeStorySuccess', props<{ storyId: number }>());
export const removeStoryFailure = createAction('[Story] removeStoryFailure', props<{ error: Error }>());

export const updateStories = createAction(
  '[Story] updateStories',
  props<{ key: Partial<Story>; filter: Partial<Story> }>(),
);
export const updateStoriesSuccess = createAction(
  '[Story] updateStoriesSuccess',
  props<{ key: Partial<Story>; filter: Partial<Story> }>(),
);
export const updateStoriesFailure = createAction('[Story] updateStoriesFailure', props<{ error: Error }>());

export const createStoryFromPreview = createAction(
  '[Stories] createStoryFromPreview',
  props<{ storyId: number; previewStory: Story }>(),
);
export const createStoryFromPreviewSuccess = createAction(
  '[Stories] createStoryFromPreviewSuccess',
  props<{ story: Story }>(),
);
export const createStoryFromPreviewFailure = createAction(
  '[Stories] createStoryFromPreviewFailure',
  props<{ error: Error }>(),
);
