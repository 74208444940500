import { Slide } from './slide';
import { TouchpointType } from './touchpointType';
import { TouchpointStyle } from './touchpointStyle';
import { TouchPointObject } from './touchPointObject';
import { TouchpointDeviceType } from './touchpointDeviceType';
import { TouchPointTag } from './touchpointTag';

export class Touchpoint {
  public id: number;
  public x: number;
  public y: number;
  public width: number;
  public height: number;
  public gridLocationIndex: number;
  public nextSlide: Slide;
  public nextSlideId: number;
  public parentSlide: Slide;
  public isInvisible: boolean;
  public parentSlideId: number;
  public type: TouchpointType;
  public link: string;
  public style: TouchpointStyle;
  public styleId: number;
  public touchpointObjects: TouchPointObject[];
  public touchpointTags: TouchPointTag[];
  public deviceType: TouchpointDeviceType;
}
