<div class="navbar">
  <div class="navbar__brand">
    <a routerLink="/dashboard">
      <div class="svg-logo"></div>
    </a>
  </div>
  <div class="navbar__nav">
    <ng-content></ng-content>
  </div>

  <div class="navbar__profile">
    <button *ngIf="isEditor" mat-icon-button class="share-button" (click)="share(story.id)">
      <mat-icon class="icon" matTooltip="Share Story" matTooltipPosition="after">share</mat-icon>
    </button>

    <div class="navbar__profile-darkmode">
      <svg-icon src="assets/icons/moon.svg" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
      <mat-slide-toggle [(ngModel)]="isLightmode" color="primary" (click)="toggleDarkmode()"></mat-slide-toggle>
      <svg-icon src="assets/icons/sun.svg" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
    </div>
    <button *ngIf="isDashboard" mat-raised-button color="primary" (click)="logout()">Logout</button>
    <!-- <button *ngIf="isEditor" mat-raised-button color="primary" (click)="publishWeb()">
      Publish
    </button> -->
    <button *ngIf="isEditor" mat-raised-button color="primary" [matMenuTriggerFor]="publishMenu">
      Publish
    </button>
    <mat-menu #publishMenu="matMenu" xPosition="before">
      <button mat-menu-item (click)="publish()">WhiteTable</button>
      <a mat-menu-item (click)="publishWeb()">Web</a>
    </mat-menu>
  </div>
</div>
