import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { WTGridItem } from '@shared/models/domain-models/editor/grid/wtGridItem';
import { EditorMode } from '@shared/models/entities/editor-mode';
import { Slide } from '@shared/models/entities/slide';
import { Touchpoint } from '@shared/models/entities/touchpoint';
import { TouchpointDeviceType } from '@shared/models/entities/touchpointDeviceType';
import { TouchpointType } from '@shared/models/entities/touchpointType';
import { setSelectedSlide, setSelectedTouchpoint } from '@shared/store/actions/editor.actions';
import { updateTouchpoint } from '@shared/store/actions/story.actions';

@Component({
  selector: 'st-whitetable-grid',
  templateUrl: './whitetable-grid.component.html',
  styleUrls: ['./whitetable-grid.component.scss'],
})
export class WhitetableGridComponent implements AfterViewChecked, OnChanges {
  @ViewChild('storySlide') public storySlide: ElementRef;
  @ViewChild('touchpointContainer') public touchpointContainer: ElementRef;
  @Output() public selectTouchpoint = new EventEmitter<Touchpoint>();
  @Input() public touchpoints: Touchpoint[];
  @Input() public editorMode: EditorMode;
  @Input() public allTouchpoints: Touchpoint[];
  @Input() public slide: Slide;
  @Input() public slides: Slide[];
  public touchPointsForMode: Touchpoint[];
  public grid: WTGridItem[];
  public gridSize = 9;
  private isSingleClick = true;

  constructor(private toastr: ToastrService, private store: Store<AppState>) {}

  @HostListener('window:resize')
  public setTouchPointContainerStyles(): void {
    const storySlideBox = document.querySelector('#storySlide').getBoundingClientRect();
    const width = Math.min((storySlideBox.height / 9) * 16, storySlideBox.width);
    const height = Math.min((storySlideBox.width / 16) * 9, storySlideBox.height);
    this.touchpointContainer.nativeElement.style.width = `${width}px`;
    this.touchpointContainer.nativeElement.style.height = `${height}px`;
  }

  public ngOnChanges(): void {
    if (this.editorMode) {
      this.initGrid(this.gridSize);
      this.touchPointsForMode = this.touchpoints.filter(tp => tp.deviceType.toString() === this.editorMode.toString());
      this.touchPointsForMode.forEach(t => {
        this.grid[t.gridLocationIndex - 1].touchpoints.push(t);
      });
    }
  }

  public ngAfterViewChecked(): void {
    this.setTouchPointContainerStyles();
  }

  // When a grid size potentially becomes draggable
  // --> also recalculate touchpoint gridlocations
  public initGrid(gridSize: number) {
    this.grid = [];
    for (let i = 1; i <= gridSize; i++) {
      this.grid.push(new WTGridItem(i));
    }
  }

  public gridStyles() {
    const sizePresentage = 100 / Math.sqrt(this.gridSize);
    const styles: { [k: string]: any } = {
      'width.%': sizePresentage,
      'height.%': sizePresentage,
    };
    return styles;
  }

  public touchPointStyles(touchPoint: Touchpoint): object {
    const styles: { [k: string]: any } = {
      'width.%': touchPoint.width,
      'height.%': touchPoint.height,
    };

    return styles;
  }

  public mouseOverTouchpoint(touchpoint: Touchpoint) {
    if (touchpoint.type === null || touchpoint.type === TouchpointType.LINK) {
      // When on the rootslide on mode wt2 or wt2 nextslide cannot be highlighted
      if (touchpoint.nextSlideId !== null) {
        const preview = document.querySelector(`#slide-tree-${touchpoint.nextSlideId}`);
        preview.classList.add('next-slide');
      }
    }
  }

  public mouseOutTouchpoint(touchpoint: Touchpoint) {
    if (touchpoint.type === null || touchpoint.type === TouchpointType.LINK) {
      // When on the rootslide on mode wt2 or wt2 nextslide cannot be highlighted
      if (touchpoint.nextSlideId !== null) {
        const preview = document.querySelector(`#slide-tree-${touchpoint.nextSlideId}`);
        preview.classList.remove('next-slide');
      }
    }
  }

  public canDrop(newGridItem: WTGridItem, dragTouchpoint: Touchpoint) {
    // let gridItem: WTGridItem = droplist.data;
    const currentSlideId: number = this.slide.id;
    if (newGridItem.touchpoints.length !== 0) {
      this.toastr.warning('Only one touchpoint is allowed in each cell.');
      return false;
    }
    // Check child / children gridlocations
    if (dragTouchpoint.nextSlideId === null) {
      if (this.editorMode === EditorMode.wt2) {
        const aChildHasSameTouchpointLoc = dragTouchpoint.touchpointTags
          .map(tag =>
            this.allTouchpoints
              .filter(tp => tp.parentSlideId === tag.nextSlideId)
              .filter(tp => tp.deviceType.toString() === this.editorMode.toString())
              .some(tp => tp.gridLocationIndex === newGridItem.gridIndex),
          )
          .some((val: boolean) => val === true);
        if (aChildHasSameTouchpointLoc) {
          this.toastr.warning('A child from the root already has a touchpoint on this location');
          return false;
        }
      } else if (this.editorMode === EditorMode.wt3) {
        const aChildHasSameTouchpointLoc = dragTouchpoint.touchpointObjects
          .map(obj =>
            this.allTouchpoints
              .filter(tp => tp.parentSlideId === obj.nextSlideId)
              .filter(tp => tp.deviceType.toString() === this.editorMode.toString())
              .some(tp => tp.gridLocationIndex === newGridItem.gridIndex),
          )
          .some((val: boolean) => val === true);
        if (aChildHasSameTouchpointLoc) {
          this.toastr.warning('A child from the root already has a touchpoint on this location');
          return false;
        }
      }
    } else {
      const childHasSameTouchPointLoc: boolean = this.allTouchpoints
        .filter(tp => tp.parentSlideId === dragTouchpoint.nextSlideId)
        .filter(tp => tp.deviceType.toString() === this.editorMode.toString())
        .some(tp => tp.gridLocationIndex === newGridItem.gridIndex);

      if (childHasSameTouchPointLoc) {
        this.toastr.warning('The nextslide of this touchpoint already has a touchpoint on this location');
        return false;
      }
    }

    // Check parentSlide gridlocations
    const parentSlide: Slide = this.findParentSlide(currentSlideId);
    if (!parentSlide) {
      return true;
    }
    const parentGridLocations = parentSlide.touchpoints
      .filter(tp => tp.deviceType.toString() === this.editorMode.toString())
      .map(tp => tp.gridLocationIndex);
    if (parentGridLocations.some(loc => loc === newGridItem.gridIndex)) {
      this.toastr.warning('The parent slide already has a touchpoint in this position');
      return false;
    }
    return true;
  }

  public drop(event, newGridItem: WTGridItem) {
    const className = event.item.element.nativeElement.classList[2];
    document.querySelectorAll(`.${className}`).forEach(e => e.classList.remove('touchpoint-error'));

    const oldGridIndex = event.previousContainer.data[0].gridLocationIndex;
    if (oldGridIndex !== newGridItem.gridIndex) {
      const tp = this.grid[oldGridIndex - 1].touchpoints[0];
      if (this.canDrop(newGridItem, tp)) {
        tp.gridLocationIndex = newGridItem.gridIndex;
        this.store.dispatch(
          updateTouchpoint({ key: { id: tp.id }, filter: { gridLocationIndex: tp.gridLocationIndex } }),
        );
        // Update for wt2 and if tp.devicetype is wt3 and vice vers
        const otherWtTp = this.allTouchpoints.find(
          touch =>
            touch.nextSlideId === tp.nextSlideId &&
            touch.type !== TouchpointType.LINK &&
            touch.type !== TouchpointType.URL &&
            touch.deviceType !== TouchpointDeviceType.web &&
            touch.deviceType !== tp.deviceType,
        );
        if (otherWtTp !== undefined) {
          this.store.dispatch(
            updateTouchpoint({ key: { id: otherWtTp.id }, filter: { gridLocationIndex: newGridItem.gridIndex } }),
          );
          this.grid[newGridItem.gridIndex - 1].touchpoints.push(tp);
          this.grid[oldGridIndex - 1].touchpoints = [];
        }
      }
    }
  }

  public dropBoxEntered(event, gridItem) {
    const tpId = parseInt(event.item.element.nativeElement.id, 10);

    if (gridItem.touchpoints.length > 0 && gridItem.touchpoints.filter(element => element.id === tpId).length === 0) {
      const className = event.item.element.nativeElement.classList[2];
      document.querySelectorAll(`.${className}`).forEach(e => e.classList.add('touchpoint-error'));
    }
  }

  public dropBoxLeft(event) {
    const className = event.item.element.nativeElement.classList[2];
    document.querySelectorAll(`.${className}`).forEach(e => e.classList.remove('touchpoint-error'));
  }

  public getDropLists() {
    return this.grid.map(g => `gridIndex${g.gridIndex}`);
  }

  /**
   * Called when touchpoint is clicked and creates an touchpoint element to be edited.
   *
   * @param touchpoint - clicked touchpoint
   */
  public editTouchpoint(touchpoint: Touchpoint) {
    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        this.store.dispatch(setSelectedTouchpoint({ touchpointId: touchpoint.id }));
      }
    }, 250);
  }

  public doubleClick(touchpoint: Touchpoint) {
    this.isSingleClick = false;
    this.store.dispatch(setSelectedSlide({ slideId: touchpoint.nextSlideId }));
  }

  private findParentSlide(slideId: number): Slide {
    return this.slides.find(() =>
      // TODO also filter out touchpoints that are type link??
      this.touchpoints
        .filter(tp => tp.deviceType.toString() === this.editorMode.toString() && tp.type !== TouchpointType.LINK && tp.type !== TouchpointType.URL)
        .some(tp => {
          if (tp.nextSlideId === null) {
            if (this.editorMode.toString() === TouchpointDeviceType.wt2.toString()) {
              return tp.touchpointTags.some(tag => tag.nextSlideId === slideId);
            } else if (this.editorMode.toString() === TouchpointDeviceType.wt3.toString()) {
              return tp.touchpointObjects.some(tpObject => tpObject.nextSlideId === slideId);
            }
          } else {
            return tp.nextSlideId === slideId;
          }
        }),
    );
  }
}
