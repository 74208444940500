import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@storytemple/models';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly AUTH_URL = environment.API_URL;
  private authenticationFrontEndURL = environment.loginServiceUrl;
  private csrfToken: string;

  constructor(private http: HttpClient) {}

  public async initCsrfToken() {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
    });
    this.csrfToken = await this.http
      .get(`${this.AUTH_URL}/auth/csrf-token`, { responseType: 'text', headers })
      .toPromise();
  }

  public getCsrfToken() {
    return this.csrfToken;
  }

  public loggedIn(): Promise<User> {
    return this.http.get<any>(this.AUTH_URL + '/auth/loggedin').toPromise();
  }

  public async logout() {
    await this.http.get<any>(this.AUTH_URL + '/auth/logout').toPromise();
    window.location.href = `${this.authenticationFrontEndURL}/login`;
  }

  public refresh() {
    return this.http.get<any>(this.AUTH_URL + '/auth/refresh');
  }
}
