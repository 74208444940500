<mat-card *ngIf="story" class="story-panel">
  <div class="story-panel__header">
    <mat-card-title>Storyline</mat-card-title>
    <button class="edit-storyline" (click)="treeEditor(story.id)" mat-raised-button>Edit storyline</button>
  </div>
  <div class="story-panel__tree">
    <st-tree-overview
      [zoomValue]="4"
      [zoomButtons]="false"
      [zoomAction]="false"
      [preview]="true"
      [story]="story"
    ></st-tree-overview>
  </div>
</mat-card>
