import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateDto } from '@shared/dto/update.dto';
import { Notes } from '@shared/models/entities/notes';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  private readonly URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public update(key: Partial<Notes>, filter: Partial<Notes>): Observable<any> {
    const dto = new UpdateDto(key, filter);
    return this.http.put<Notes>(`${this.URL}/notes/update`, dto);
  }
}
