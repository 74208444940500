import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from 'src/app/shared/models/domain-models/appState';
import { Element } from 'src/app/shared/models/domain-models/editor/parser/element';
import { ElementType } from 'src/app/shared/models/domain-models/editor/parser/elements/elementType';
import { EditorMode } from 'src/app/shared/models/entities/editor-mode';
import { SlideType } from 'src/app/shared/models/entities/slideType';
import { Story } from 'src/app/shared/models/entities/story';
import { Template } from 'src/app/shared/models/entities/template';
import { SlideService } from 'src/app/shared/services/http/slide-service/slide.service';
import { getSelectedElement, getSelectedTouchpointId } from 'src/app/shared/store/selectors/editor.selector';
import { getTouchpoint } from 'src/app/shared/store/selectors/story.selector';
import { Iframe } from 'src/app/shared/models/entities/iframe';
import { Touchpoint } from 'src/app/shared/models/entities/touchpoint';
import { EditorService } from '../../services/editor.service';
import { setSelectedElement, setSelectedTouchpoint } from 'src/app/shared/store/actions/editor.actions';
import { Asset } from '@shared/models/entities/asset/asset';
import { AssetType } from '@shared/models/entities/asset/assetType';

@Component({
  selector: 'st-edit-panel',
  templateUrl: './edit-panel.component.html',
  styleUrls: ['./edit-panel.component.scss'],
})
export class EditPanelComponent implements OnInit, OnDestroy {
  @Input() public selectedIframe: Iframe;
  @Input() public reloadStory: EventEmitter<number>;
  @Input() public editorMode: EditorMode;
  // onchange in text editor emit to interactive element to refresh the answers.
  @Input() public changeAnswers = new EventEmitter<any>();
  public assetType = AssetType;
  public selectedElement$: Observable<Element>;
  public selectedTouchpoint$: Observable<Touchpoint>;
  public selectedTouchpointId: number;
  public assets$: Observable<Asset[]>;
  public story$: Observable<Story>;
  public template$: Observable<Template>;
  public slideTypes = SlideType;
  public elementType = ElementType;
  private subsriptions: Subscription[] = [];

  constructor(public service: SlideService, private store: Store<AppState>, public editorService: EditorService) {}

  public ngOnInit() {
    this.selectedElement$ = this.store.select(getSelectedElement);
    // Subscribe on seletedTouchpointId
    this.subsriptions.push(this.store.select(getSelectedTouchpointId).subscribe(selectedTouchpointId => {
      this.selectedTouchpointId = selectedTouchpointId;
      if(selectedTouchpointId){
        this.selectedTouchpoint$ = this.store.select(getTouchpoint, selectedTouchpointId);
      }
    }));
    
    this.assets$ = this.store.select(store => store.story.assets);
    this.story$ = this.store.select(store => store.story.story);
    this.template$ = this.store.select(store => store.story.template);
  }

  public ngOnDestroy(){
    this.subsriptions.forEach(sub => sub.unsubscribe());
  }

  public closeEditor() {
    this.store.dispatch(setSelectedElement({ element: undefined }));
    this.store.dispatch(setSelectedTouchpoint({ touchpointId: undefined }));
  }
}
