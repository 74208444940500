import { StTextElement } from '@shared/models/entities/stElements/stTextElement.entity';
import { Element } from '../element';
import { ElementType } from './elementType';

export class TitleElement implements Element {
  public elementType: ElementType;

  constructor(private rootNode, public stTextElement: StTextElement) {
    this.elementType = ElementType.TEXT_ELEMENT;
  }

  public getElement(): any {
    return this.rootNode;
  }

  public changeFont(font: string) {
    this.rootNode.querySelector('foreignObject').style.fontFamily = font;
  }

  /**
   * Creates clone of element that is dynamically edited to fit new content
   *
   * @param content string of content returned from text-editor-component
   */
  public editElement(data: any, autoSize = true) {
    if (!data.includes('<div')) {
      data = `<div>${data}</div>`;
    }

    const foreignObject = this.rootNode.querySelector('foreignObject');
    // Create deep clone
    const elementClone = foreignObject.cloneNode(true) as HTMLElement;
    elementClone.id = 'clone';
    elementClone.innerHTML = data;
    elementClone.style.overflow = 'visible';
    elementClone.style.opacity = '0';
    // Search for local element in windows and add clone
    const element = document.querySelector(`#storySlide .svg-editor #${this.rootNode.id}`).querySelector('DIV');
    element.parentElement.parentElement.appendChild(elementClone);
    // fit content in element
    if (this.fitContent(elementClone, autoSize)) {
      // Merge changes of clone with local element and remove clone
      const clone = elementClone.cloneNode(true) as HTMLElement;
      clone.removeAttribute('id');
      elementClone.remove();
      if (clone.classList.contains('extended')) {
        foreignObject.classList.add('extended');
      } else {
        foreignObject.classList.remove('extended');
      }
      foreignObject.attributes.getNamedItem('height').value = clone.attributes.getNamedItem('height').value;
      foreignObject.style.fontSize = clone.style.fontSize;
      foreignObject.innerHTML = clone.innerHTML;
      return {
        text: clone.querySelector('DIV').innerHTML,
        fontSize: Number(foreignObject.style.fontSize.slice(0, -2)),
      };
    } else {
      elementClone.remove();
    }
  }

  // NOT USED in newer templates
  public fitContent(clone: HTMLElement, autoSize: boolean) {
    // Get clone from window and search for contentWrapper
    const content = document.querySelector('#storySlide #clone div');
    const fontSize = parseFloat(getComputedStyle(content.parentElement).fontSize);
    const baseFontSize = parseFloat(content.parentElement.getAttribute('basefontsize'));
    if (autoSize) {
      if (this.isOverflown(content)) {
        clone.style.fontSize = `${fontSize - 1}px`;
        return this.fitContent(clone, autoSize);
        // If content does fit in box --> scale to box.
      } else if (!this.isOverflown(content)) {
        clone.style.fontSize = `${fontSize + 1}px`;
        if (this.isOverflown(content)) {
          clone.style.fontSize = `${fontSize}px`;
          return true;
        } else {
          return this.fitContent(clone, autoSize);
        }
      } else {
        return true;
      }
    } else {
      clone.style.fontSize = `${baseFontSize}px`;
      return true;
    }
  }

  private isOverflown(content) {
    const foreignObject = this.rootNode.querySelector('foreignObject');
    const width1 = foreignObject.width.baseVal.valueAsString as number;
    const width2 = content.scrollWidth;
    return width2 > width1;
  }
}
