import { Action, createReducer, on } from '@ngrx/store';
import { SharedStoryVisibilityType } from '@shared/dto/usergroupsorg.dto';
import { Story } from '@shared/models/entities/story';
import * as StoriesActions from '../actions/stories.actions';

export interface StoriesState {
  list: Story[];
  sharedStoriesLists: Record<SharedStoryVisibilityType, Story[]>;
  previewStories: Story[];
  loading: boolean;
  error: Error;
}

const initialState: StoriesState = {
  list: [],
  sharedStoriesLists: undefined,
  previewStories: undefined,
  loading: false,
  error: undefined,
};

export const storiesReducer = createReducer(
  initialState,
  on(StoriesActions.loadStories, state => ({ ...state, loading: true })),
  on(StoriesActions.loadStoriesSuccess, (state, { list: stories }) => ({
    ...state,
    list: stories,
    loading: false,
    error: undefined,
  })),
  on(StoriesActions.loadStoriesFailure, (state, { error }) => ({ ...state, loading: false, error })),

  on(StoriesActions.loadSharedStories, state => ({ ...state, loading: true })),
  on(StoriesActions.loadSharedStoriesSuccess, (state, { record: stories }) => ({
    ...state,
    sharedStoriesLists: stories,
    loading: false,
    error: undefined,
  })),
  on(StoriesActions.loadSharedStoriesFailure, (state, { error }) => ({ ...state, loading: false, error })),

  on(StoriesActions.loadPreviewStories, state => ({ ...state })),
  on(StoriesActions.loadPreviewStoriesSuccess, (state, { previewStories }) => ({
    ...state,
    previewStories,
  })),

  on(StoriesActions.duplicateStory, state => ({ ...state })),
  on(StoriesActions.createTempDuplicateStory, (state, { story: story }) => ({
    ...state,
    list: [...state.list, story],
    error: undefined,
  })),
  on(StoriesActions.duplicateStorySuccess, (state, { story: story, tempId: tempId, originalSlides }) => ({
    ...state,
    list: state.list.map((s: any) =>
      s.tempId !== undefined && s.tempId === tempId ? ((s = story), (s = { ...s, slides: originalSlides })) : s,
    ),
    error: undefined,
  })),
  on(StoriesActions.duplicateStoryFailure, (state, { error }) => ({ ...state, error })),

  on(StoriesActions.removeStory, state => ({ ...state })),
  on(StoriesActions.removeStorySuccess, (state, { storyId: storyId }) => ({
    ...state,
    list: state.list.filter(story => story.id !== storyId),
    error: undefined,
  })),
  on(StoriesActions.removeStoryFailure, (state, { error }) => ({ ...state, error })),

  on(StoriesActions.updateStories, state => ({ ...state })),
  on(StoriesActions.updateStoriesSuccess, (state, { key, filter }) => ({
    ...state,
    list: state.list.map(s => {
      if (Object.keys(key).every(k => s[k] === key[k])) {
        return { ...s, ...filter } as Story;
      } else {
        return s;
      }
    }),
    error: undefined,
  })),
  on(StoriesActions.updateStoriesFailure, (state, { error }) => ({ ...state, error })),

  on(StoriesActions.createStoryFromPreview, state => ({ ...state })),
  on(StoriesActions.createStoryFromPreviewSuccess, (state, { story }) => ({
    ...state,
    list: [...state.list, story],
  })),
  on(StoriesActions.createStoryFromPreviewFailure, (state, { error }) => ({ ...state, error })),
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function storiesReducerFunction(state: StoriesState | undefined, action: Action) {
  return storiesReducer(state, action);
}
