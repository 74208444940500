import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { Story } from '@shared/models/entities/story';
import { StoryService } from '@shared/services/http/story-service/story.service';
import { CardAnimations } from '@pages/dashboard/animations/card.animation';

@Component({
  selector: 'st-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss'],
  animations: [CardAnimations.cardAnimation, CardAnimations.cardExitAnimation],
})
export class StoriesComponent implements OnInit {
  @Input() public title: string;
  public stories$: Observable<Story[]>;
  public loadingStories$: Observable<boolean>;
  public filterVal: string;
  public searchVal: string;
  public animations = false;

  constructor(public storyService: StoryService, public dialog: MatDialog, private store: Store<AppState>) {}

  public ngOnInit() {
    this.stories$ = this.store.select(store => store.stories.list.slice());
    this.loadingStories$ = this.store.select(store => store.stories.loading);
  }

  public changeFilter(event) {
    this.filterVal = event.value;
  }
}
