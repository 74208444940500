import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Notes } from '@shared/models/entities/notes';
import { updateNotes } from '@shared/store/actions/story.actions';

@Component({
  selector: 'st-slide-notes',
  templateUrl: './slide-notes.component.html',
  styleUrls: ['./slide-notes.component.scss'],
})
export class SlideNotesComponent implements OnInit {
  @Input() public notes: Notes;
  private timeout: any;

  constructor(private store: Store) {}

  public ngOnInit() {}

  public editText(html: string) {
    if (this.notes.content !== html) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.store.dispatch(updateNotes({ key: { id: this.notes.id }, filter: { content: html } }));
      }, 500);
    }
  }
}
