import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SaveBackgroundStElementDto } from '@shared/dto/saveBackgroundStElement.dto';
import { UpdateDto } from '@shared/dto/update.dto';
import { Slide } from '@shared/models/entities/slide';
import { StElement } from '@shared/models/entities/stElements/stElement';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StElementService {
  private readonly URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  public updateStElement(dto: UpdateDto<StElement>): Observable<StElement> {
    return this.http.put<StElement>(`${this.URL}/stElement/update`, dto);
  }

  public saveBackgroundStElement(dto: SaveBackgroundStElementDto): Observable<Slide> {
    return this.http.post<Slide>(`${this.URL}/stElement/saveBackground`, dto);
  }
}
