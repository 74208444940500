import { Component, Inject, OnInit } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ImageElementComponent } from '../image-element.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '@shared/components/alert/alert.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppState } from '@shared/models/domain-models/appState';
import { Store } from '@ngrx/store';
import { removeStoryAsset, saveStoryAsset } from '@shared/store/actions/story.actions';
import { SaveImageAssetDto } from '@shared/dto/saveImageAsset.dto';
import { Mime, supportedImageMimes } from '@shared/models/entities/asset/mimeType';
import { Asset } from '@shared/models/entities/asset/asset';
import { AssetType } from '@shared/models/entities/asset/assetType';
import { ImageAsset } from '@shared/models/entities/asset/imageAsset';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'st-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss'],
})
export class ImageSelectorComponent implements OnInit {
  public selectedImage: ImageAsset;
  public images: ImageAsset[] = [];
  public icons: ImageAsset[] = [];
  public storyId: number;
  public imageType = AssetType.ORIGINAL_IMAGE;

  constructor(
    public dialogRef: MatDialogRef<ImageElementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public store: Store<AppState>,
    private toastrService: ToastrService,
  ) {}

  public ngOnInit(): void {
    this.setData(this.data.assets);
    this.storyId = this.data.storyId;
  }

  public setData(assets: Asset[]) {
    this.images = assets.filter(img => img.type === AssetType.ORIGINAL_IMAGE) as ImageAsset[];
    this.icons = assets.filter(img => img.type === AssetType.ORIGINAL_ICON) as ImageAsset[];
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public uploadImage(e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    const name = file.name.split('.');
    reader.onload = this._handleReaderLoaded.bind(this, name[0], file.type);
    reader.readAsDataURL(file);
  }

  public _handleReaderLoaded(_name, type, file) {
    const reader = file.target;
    const imageSrc = reader.result as string;
    const data = imageSrc.split(',')[1];
    const mimeType: Mime = type as Mime;
    if (!supportedImageMimes.includes(mimeType)) {
      this.toastrService.error('Image file type is not supported!');
      return;
    }
    const saveImageAssetDto = new SaveImageAssetDto(data, mimeType, this.imageType, _name, this.storyId);
    this.store.dispatch(saveStoryAsset({ saveAssetDto: saveImageAssetDto }));
  }

  public selectImage(image: ImageAsset) {
    this.selectedImage = image;
  }

  public removeImage(image: Asset) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '340px',
      data: 'Do you want to delete this image?',
    });
    dialogRef.afterClosed().subscribe(confirmedToDelete => {
      if (confirmedToDelete) {
        this.store.dispatch(removeStoryAsset({ storyId: this.storyId, assetId: image.id }));
      }
    });
  }
}
