import { TouchpointType } from '../models/entities/touchpointType';
import { AddTouchpointDto } from './addTouchpoint.dto';

export class AddLinkTouchpointDto extends AddTouchpointDto {
  constructor(
    public parentSlideId: number,
    public touchpointType: TouchpointType,
    public touchpointStyleId: number,
    public nextSlideId: number,
  ) {
      super(parentSlideId, touchpointType, touchpointStyleId)
  }
}
