<div class="link-panel">
    <h3 class="link-panel-title">Manage links</h3>
    <div class="link-panel-scroll">
      <div *ngFor="let slide of linkSlides">
        <div class="link-slide" [ngClass]="{ linked: slideLinkTouchpointsIds.includes(slide.id) }" [innerHTML]="slide.content | safeHTML"></div>
        <div
          *ngIf="slideLinkTouchpointsIds.includes(slide.id); else notLinked"
          class="background"
          (mouseenter)="mouseOverSlide(slide)"
          (mouseleave)="mouseOutSlide(slide)"
          matTooltip="Delete link"
          (click)="removeLink(slide)"
          matTooltipPosition="after"
        >
          <mat-icon class="link">link</mat-icon>
          <mat-icon class="link-off">link_off</mat-icon>
        </div>
        <ng-template #notLinked>
          <div
            matTooltip="Add link"
            matTooltipPosition="after"
            class="background"
            (click)="linkSlide(slide)"
            (mouseenter)="mouseOverSlide(slide)"
            (mouseleave)="mouseOutSlide(slide)"
          >
            <mat-icon class="not-linked" matTooltipPosition="after">link</mat-icon>
          </div>
        </ng-template>
      </div>
    </div>
  </div>