import { Injectable } from '@angular/core';
import { Slide } from '@shared/models/entities/slide';
import { StGraphElement } from '@shared/models/entities/stElements/stGraphElement.entity';
import { Story } from '@shared/models/entities/story';
@Injectable({
  providedIn: 'root',
})
export class EditorService {
  public slideDom: Document;
  public slide: Slide;
  public story: Story;

  constructor() {}

  public setSlide(slide: Slide): Document {
    // Domparser generates HTML, HEAD and Body tag and needs to be removed
    const tempDom = new DOMParser().parseFromString(slide.content, 'text/html');
    // Create new html DOM
    const doc = document.implementation.createHTMLDocument('svg');
    // Search for svg in parsed DOM
    const svg = tempDom.querySelector('svg');
    const content = tempDom.querySelector('body').children[0];

    const chartSlot = content.querySelector('#chart');
    const tableSlot = content.querySelector('#table');
    const graphElement = slide.stElements.find(o => o.slotId === 'chart1') as StGraphElement;
    const tableElement = slide.stElements.find(o => o.slotId === 'table1');

    // Temporary fix to show a graph and table placeholder
    if (graphElement !== undefined) {
      const getGraphPlaceholder = graphElement.graphType.concat(graphElement.series);

      // Create image element with placeholder based on ST element
      const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
      image.setAttributeNS(
        'http://www.w3.org/2000/xlink',
        'href',
        `../../../../assets/dummy-graphs/${getGraphPlaceholder}.svg`,
      );
      chartSlot.appendChild(image);
    } else if (tableElement !== undefined) {
      // ST table element contains a foreignObject, so the image is automatically converted to a HTML img
      const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
      image.setAttributeNS('http://www.w3.org/2000/xlink', 'src', '../../../../assets/dummy-graphs/table.svg');
      tableSlot.appendChild(image);
    }

    // Check if svg is found
    if (content !== null && svg) {
      // remove pre rendered tags and add svg to document
      doc.removeChild(doc.children[0]);
      doc.appendChild(content);

      this.slide = slide;
      this.slideDom = doc;
      return doc;
    } else {
      return undefined;
    }
  }

  public save() {
    this.slide.content = this.slideDom.documentElement.outerHTML;
  }
}
