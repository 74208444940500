<div class="share-menu">
  <mat-card-title>
    <h1>Share story</h1>

    <button class="close" mat-button (click)="closeDialog()">X</button>
  </mat-card-title>
  <mat-card-content *ngIf="linkReceived">
    <p>Choose one of the methods below to share this story.</p>
    <h3>PDF file:</h3>
    <mat-list>
      <mat-list-item style="cursor: pointer;" (click)="storyToPdf()">
        <svg-icon
          src="assets/icons/pdf.svg"
          [svgStyle]="{ 'width.px': 24, 'height.px': 24, 'margin-right.px': 10 }"
        ></svg-icon>
        <p>Download as PDF</p>
      </mat-list-item>
    </mat-list>

    <hr class="hr" />
    <h3>Share external:</h3>

    <input *ngIf="linkReceived" class="outputfield" readonly matInput [(ngModel)]="storyLink.shareURL" />
    <mat-checkbox color="primary" [checked]="customDate" (change)="customDate = $event.checked"
      >set expiry date</mat-checkbox
    >
    <mat-form-field class="datepicker" *ngIf="this.customDate" appearance="fill">
      <mat-label>Choose a date</mat-label>
      <input
        [(ngModel)]="selectedDate"
        matInput
        [matDatepicker]="picker"
        [min]="minDate"
        disabled
        (dateChange)="getShareLinkWithCustomDate()"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker disabled="false"></mat-datepicker>
    </mat-form-field>

    <p *ngIf="linkReceived">Valid until: {{ storyLink.expiryDate }}</p>

    <p *ngIf="linkReceived">
      The link has been copied to your clipboard! <br />Send this link to the people you would like to share the story
      with.
    </p>
  </mat-card-content>
</div>
